import { FeedbackInitial, Feedback, FeedbackRating } from 'interface/api';
import { api } from './api';

// Call post request to generate new feedback record
export async function initiateFeedback(userToken: string, feedbackInitial: FeedbackInitial): Promise<Feedback> {
    let formData = new FormData();
    formData.append('request', JSON.stringify(feedbackInitial));
    return new Promise((resolve, reject) => {
        const headers = {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        };
        api.post('/feedback', formData, { headers: headers })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject(`${response.status}: ${response.statusText}`);
                }
            })
            .catch((e) => reject(e));
    });
}

// Get feedback by user_id and generated_uid
// Should only give us back one result
export async function getFeedbackByGeneratedUID(
    userToken: string,
    user_uid: string,
    gen_uid: string
): Promise<Feedback[]> {
    return new Promise((resolve, reject) => {
        api.get('/feedback', {
            params: {
                user_uid: user_uid,
                generate_uid: gen_uid
            },
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject(`${response.status}: ${response.statusText}`);
                }
            })
            .catch((error: any) => reject(error));
    });
}

// Get feedback by user_uid which can be one or many
export async function getFeedbackByUserUID(userToken: string, user_uid: string): Promise<Feedback[]> {
    return new Promise((resolve, reject) => {
        api.get('/feedback', {
            params: {
                user_uid: user_uid
            },
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject(`${response.status}: ${response.statusText}`);
                }
            })
            .catch((error: any) => reject(error));
    });
}

// Get feedback by receiver_uid which can be one or many
export async function getFeedbackByReceiverUID(userToken: string, receiver_uid: string): Promise<Feedback[]> {
    return new Promise((resolve, reject) => {
        api.get('/feedback', {
            params: {
                reviewer_user_uid: receiver_uid
            },
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject(`${response.status}: ${response.statusText}`);
                }
            })
            .catch((error: any) => reject(error));
    });
}

// Get all user's feedback requiring their review
export async function getDesignToReview(userToken: string, user_uid: string): Promise<Feedback[]> {
    return new Promise((resolve, reject) => {
        api.get('/feedback/provide_comments', {
            params: {
                reviewer_user_uid: user_uid
            },
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject(`${response.status}: ${response.statusText}`);
                }
            })
            .catch((error: any) => reject(error));
    });
}

//add reviewers comments and rating to feedback record
export async function addComments(userToken: string, userRating: FeedbackRating): Promise<void> {
    let formData = new FormData();
    formData.append(
        'feedback',
        JSON.stringify({
            user_uid: userRating.user_uid,
            generate_uid: userRating.generate_uid,
            comment: userRating.comment,
            rating: userRating.rating
        })
    );
    return new Promise((resolve, reject) => {
        const headers = {
            Authorization: `Bearer ${userToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        };
        api.put(`/feedback/provide_comments`, formData, { headers: headers })
            .then((response) => {
                if (response.status === 201) {
                    resolve();
                } else {
                    reject(`${response.status}: ${response.data}`);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    });
}

// Get all user's feedback that was reviewed by another user
export async function getReviewedDesign(userToken: string, user_uid: string): Promise<Feedback[]> {
    return new Promise((resolve, reject) => {
        api.get('/feedback/acknowledge', {
            params: {
                user_uid: user_uid
            },
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject(`${response.status}: ${response.statusText}`);
                }
            })
            .catch((error: any) => reject(error));
    });
}

//add acknowledgement to feedback record from only designer(user) to one (making acknowledgement true)
export async function addAcknowledgement(
    userToken: string,
    user_uid: string,
    generateId: string,
    acknowledgement: number
): Promise<void> {
    return new Promise((resolve, reject) => {
        const headers = {
            Authorization: `Bearer ${userToken}`,
            Accept: 'application/json'
        };
        api.put(
            `/feedback/acknowledge?user_uid=${user_uid}&generate_uid=${generateId}&acknowledged=${acknowledgement}`,
            {},
            { headers: headers }
        )
            .then((response) => {
                if (response.status === 201) {
                    resolve();
                } else {
                    reject(`${response.status}: ${response.data}`);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    });
}

//Update completion status of user & reviewer
//update for reviewer if they rated the design good enough
//update for user if they have acknowledged the feedback on good design or
//update for user if they have redesigned the design
export async function updateCompletionStatus(
    userToken: string,
    user_uid: string,
    generateId: string,
    isReviewer: boolean
): Promise<void> {
    const parameterString = isReviewer ? 'reviewer_completed' : 'user_completed';
    return new Promise((resolve, reject) => {
        const headers = {
            Authorization: `Bearer ${userToken}`,
            Accept: 'application/json'
        };
        api.put(
            `/feedback/complete?user_uid=${user_uid}&generate_uid=${generateId}&${parameterString}=${true}`,
            {},
            { headers: headers }
        )
            .then((response) => {
                if (response.status === 200) {
                    resolve();
                } else {
                    reject(`${response.status}: ${response.data}`);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    });
}
