import { StudyQuestion, StudyRequest, StudyResponse, StudyStatus } from 'interface/api';
import { api } from './api';

export function getStudyByName(userToken: string, studyName: string): Promise<StudyRequest> {
    return new Promise((resolve, reject) => {
        api.get(`/study/study_name`, {
            params: {
                study_name: studyName
            },
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    const result: StudyRequest = { ...response.data };
                    resolve(result);
                } else {
                    reject(`${response.status}: ${response.statusText}`);
                }
            })
            .catch((error) => reject(error));
    });
}

export function getAllStudyNames(userToken: string): Promise<string[]> {
    return new Promise((resolve, reject) => {
        api.get('/study/all', {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

export function checkValidStudyName(userToken: string, studyName: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
        getAllStudyNames(userToken)
            .then((data: string[]) => {
                resolve(data.includes(studyName));
            })
            .catch((error) => reject(error));
    });
}

/// Given StudyData and an InstructionIndex, return the corresponded questions
export function getStudyQuestions(study: StudyRequest, instructionId: string): StudyQuestion[] {
    if (study === undefined) {
        return [];
    }

    const instruction = study.instructions.find((item) => item.uid === instructionId);

    if (instruction === undefined) {
        console.error('Invalid instruction id.');
        return [];
    }

    const questions = instruction.questions;
    if (questions !== undefined && questions !== null && questions.length > 0) {
        return questions;
    }

    if (study.default_questions) {
        return study.default_questions;
    }

    throw new Error('Unable to get any questions');
}

export function getStudyAnswers(questions: StudyQuestion[], answers: string[]): StudyResponse[] {
    if (questions === undefined || answers === undefined) {
        return [];
    }

    if (questions.length != answers.length) {
        throw new Error('Mismatch index between questions and answers');
    }

    const studyAnswers: Array<StudyResponse> = [];
    for (let i = 0; i < questions.length; i++) {
        studyAnswers.push({
            text: questions[i].text,
            type: questions[i].type,
            response: answers[i],
            response_title: questions[i].response_title ?? ''
        });
    }

    return studyAnswers;
}

export function getStudyStatus(userToken: string, study_name: string): Promise<StudyStatus> {
    return new Promise((resolve, reject) => {
        api.get('/study/status', {
            params: {
                study_name: study_name
            },
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error.response.data.detail));
    });
}
