/* tslint:disable */
/* eslint-disable */
/**
 * Kaleidoscope Webapp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Acknowledged
 */
export interface Acknowledged {
}
/**
 * Anchor refers to parent or sibling Captures or Generated media.
 * @export
 * @interface Anchor
 */
export interface Anchor {
    /**
     * The image uid can be either the capture_uid, or generate_uid.
     * @type {string}
     * @memberof Anchor
     */
    'image_uid'?: string;
    /**
     * The pre-signed mask url.
     * @type {string}
     * @memberof Anchor
     */
    'mask_url'?: string;
    /**
     * Pre-signed public URL.
     * @type {string}
     * @memberof Anchor
     */
    'image_url'?: string;
    /**
     * Pre-signed public URL.
     * @type {string}
     * @memberof Anchor
     */
    'thumb_url'?: string;
    /**
     * S3 location.
     * @type {string}
     * @memberof Anchor
     */
    'mask_uri'?: string;
    /**
     * S3 location.
     * @type {string}
     * @memberof Anchor
     */
    'image_uri'?: string;
    /**
     * Thumbnail file path in s3 without the bucket.
     * @type {string}
     * @memberof Anchor
     */
    'thumb_uri'?: string;
}
/**
 * 
 * @export
 * @interface BodyCreateFeedbackEntryApiFeedbackPost
 */
export interface BodyCreateFeedbackEntryApiFeedbackPost {
    /**
     * 
     * @type {FeedbackInitial}
     * @memberof BodyCreateFeedbackEntryApiFeedbackPost
     */
    'request': FeedbackInitial;
}
/**
 * 
 * @export
 * @interface BodyCreateLogApiLogPost
 */
export interface BodyCreateLogApiLogPost {
    /**
     * 
     * @type {LogRequestBase}
     * @memberof BodyCreateLogApiLogPost
     */
    'log_request': LogRequestBase;
}
/**
 * 
 * @export
 * @interface BodyCreateLogWithFileApiLogUploadFilePost
 */
export interface BodyCreateLogWithFileApiLogUploadFilePost {
    /**
     * 
     * @type {File}
     * @memberof BodyCreateLogWithFileApiLogUploadFilePost
     */
    'file': File;
    /**
     * 
     * @type {LogRequestBase}
     * @memberof BodyCreateLogWithFileApiLogUploadFilePost
     */
    'log_request': LogRequestBase;
}
/**
 * 
 * @export
 * @interface BodyCreateLogWithPhotoApiLogUploadPhotoPost
 */
export interface BodyCreateLogWithPhotoApiLogUploadPhotoPost {
    /**
     * 
     * @type {File}
     * @memberof BodyCreateLogWithPhotoApiLogUploadPhotoPost
     */
    'file': File;
    /**
     * 
     * @type {LogRequestBase}
     * @memberof BodyCreateLogWithPhotoApiLogUploadPhotoPost
     */
    'log_request': LogRequestBase;
}
/**
 * 
 * @export
 * @interface BodyCreateSequenceMapApiSequenceMapPost
 */
export interface BodyCreateSequenceMapApiSequenceMapPost {
    /**
     * 
     * @type {SequencePair}
     * @memberof BodyCreateSequenceMapApiSequenceMapPost
     */
    'study_idx': SequencePair;
    /**
     * 
     * @type {SequenceRequestIn}
     * @memberof BodyCreateSequenceMapApiSequenceMapPost
     */
    'sequence': SequenceRequestIn;
}
/**
 * 
 * @export
 * @interface BodyGenerateMediaApiGeneratePost
 */
export interface BodyGenerateMediaApiGeneratePost {
    /**
     * 
     * @type {GenerateRequestIn}
     * @memberof BodyGenerateMediaApiGeneratePost
     */
    'request': GenerateRequestIn;
    /**
     * 
     * @type {File}
     * @memberof BodyGenerateMediaApiGeneratePost
     */
    'image'?: File;
    /**
     * 
     * @type {File}
     * @memberof BodyGenerateMediaApiGeneratePost
     */
    'mask'?: File;
}
/**
 * 
 * @export
 * @interface BodyGenerateMediaForResponseApiCaptureGeneratePost
 */
export interface BodyGenerateMediaForResponseApiCaptureGeneratePost {
    /**
     * 
     * @type {string}
     * @memberof BodyGenerateMediaForResponseApiCaptureGeneratePost
     */
    'capture_uid': string;
}
/**
 * 
 * @export
 * @interface BodyModerateImageContentApiCaptureModeratePost
 */
export interface BodyModerateImageContentApiCaptureModeratePost {
    /**
     * 
     * @type {File}
     * @memberof BodyModerateImageContentApiCaptureModeratePost
     */
    'image': File;
}
/**
 * 
 * @export
 * @interface BodyUpdateStudyStatusApiStudyStatusPut
 */
export interface BodyUpdateStudyStatusApiStudyStatusPut {
    /**
     * 
     * @type {string}
     * @memberof BodyUpdateStudyStatusApiStudyStatusPut
     */
    'study_name': string;
    /**
     * 
     * @type {StudyStatus}
     * @memberof BodyUpdateStudyStatusApiStudyStatusPut
     */
    'status': StudyStatus;
}


/**
 * 
 * @export
 * @interface BodyUpdateUserCaptureApiCaptureUidPut
 */
export interface BodyUpdateUserCaptureApiCaptureUidPut {
    /**
     * 
     * @type {CaptureRequestIn}
     * @memberof BodyUpdateUserCaptureApiCaptureUidPut
     */
    'request': CaptureRequestIn;
    /**
     * 
     * @type {File}
     * @memberof BodyUpdateUserCaptureApiCaptureUidPut
     */
    'mask'?: File;
}
/**
 * 
 * @export
 * @interface BodyUpdateWithCommentsApiFeedbackProvideCommentsPut
 */
export interface BodyUpdateWithCommentsApiFeedbackProvideCommentsPut {
    /**
     * 
     * @type {FeedbackRating}
     * @memberof BodyUpdateWithCommentsApiFeedbackProvideCommentsPut
     */
    'feedback': FeedbackRating;
}
/**
 * 
 * @export
 * @interface BodyUploadFileApiCaptureUploadPost
 */
export interface BodyUploadFileApiCaptureUploadPost {
    /**
     * 
     * @type {File}
     * @memberof BodyUploadFileApiCaptureUploadPost
     */
    'file': File;
    /**
     * 
     * @type {CaptureRequestIn}
     * @memberof BodyUploadFileApiCaptureUploadPost
     */
    'request': CaptureRequestIn;
    /**
     * 
     * @type {File}
     * @memberof BodyUploadFileApiCaptureUploadPost
     */
    'mask'?: File;
}
/**
 * 
 * @export
 * @interface BodyUploadFileForAdminApiCaptureFilePost
 */
export interface BodyUploadFileForAdminApiCaptureFilePost {
    /**
     * 
     * @type {File}
     * @memberof BodyUploadFileForAdminApiCaptureFilePost
     */
    'file': File;
}
/**
 * 
 * @export
 * @interface BodyUploadStudyResponseAndGenerateMediaApiCaptureCreatePost
 */
export interface BodyUploadStudyResponseAndGenerateMediaApiCaptureCreatePost {
    /**
     * 
     * @type {CaptureRequestIn}
     * @memberof BodyUploadStudyResponseAndGenerateMediaApiCaptureCreatePost
     */
    'request': CaptureRequestIn;
    /**
     * 
     * @type {File}
     * @memberof BodyUploadStudyResponseAndGenerateMediaApiCaptureCreatePost
     */
    'image'?: File;
    /**
     * 
     * @type {File}
     * @memberof BodyUploadStudyResponseAndGenerateMediaApiCaptureCreatePost
     */
    'mask'?: File;
}
/**
 * 
 * @export
 * @interface BodyUploadStudyResponseApiCaptureResponsePost
 */
export interface BodyUploadStudyResponseApiCaptureResponsePost {
    /**
     * 
     * @type {CaptureRequestIn}
     * @memberof BodyUploadStudyResponseApiCaptureResponsePost
     */
    'request': CaptureRequestIn;
    /**
     * 
     * @type {File}
     * @memberof BodyUploadStudyResponseApiCaptureResponsePost
     */
    'image'?: File;
    /**
     * 
     * @type {File}
     * @memberof BodyUploadStudyResponseApiCaptureResponsePost
     */
    'mask'?: File;
}
/**
 * 
 * @export
 * @interface CaptureRequest
 */
export interface CaptureRequest {
    /**
     * A title given to the file.
     * @type {string}
     * @memberof CaptureRequest
     */
    'title'?: string;
    /**
     * Caption given to file.
     * @type {string}
     * @memberof CaptureRequest
     */
    'caption'?: string;
    /**
     * 
     * @type {Location1}
     * @memberof CaptureRequest
     */
    'location'?: Location1;
    /**
     * 
     * @type {Array<Anchor>}
     * @memberof CaptureRequest
     */
    'anchors'?: Array<Anchor>;
    /**
     * Name of the study.
     * @type {string}
     * @memberof CaptureRequest
     */
    'study_name'?: string;
    /**
     * Responses.
     * @type {Array<StudyResponse>}
     * @memberof CaptureRequest
     */
    'study_response'?: Array<StudyResponse>;
    /**
     * This UID is linked to the StudyInstructionUID.
     * @type {string}
     * @memberof CaptureRequest
     */
    'study_instruction_uid'?: string;
    /**
     * This idx is linked to the index of StudyInstruction in a Study.
     * @type {number}
     * @memberof CaptureRequest
     */
    'study_instruction_idx'?: number;
    /**
     * This prompt is linked to the StudyInstruction.
     * @type {string}
     * @memberof CaptureRequest
     */
    'prompt'?: string;
    /**
     * These key-value pairs will be forwarded to the generate model.
     * @type {object}
     * @memberof CaptureRequest
     */
    'prompt_kwargs'?: object;
    /**
     * If True, use AI (REAL-ESRGAN) to downscale and upscale provided images.
     * @type {boolean}
     * @memberof CaptureRequest
     */
    'ai_scaling_enabled'?: boolean;
    /**
     * If True, moderation tool to determine if image meets the moderation criteria. This overrides the behavior of the study.
     * @type {boolean}
     * @memberof CaptureRequest
     */
    'image_moderation'?: boolean;
    /**
     * List of everything that the user has done.
     * @type {Array<CaptureStatus>}
     * @memberof CaptureRequest
     */
    'status'?: Array<CaptureStatus>;
    /**
     * Optional: Default datetime.now().
     * @type {string}
     * @memberof CaptureRequest
     */
    'timestamp'?: string;
    /**
     * User ID making this request.
     * @type {string}
     * @memberof CaptureRequest
     */
    'user_uid': string;
    /**
     * UUID4 generated by the backend.
     * @type {string}
     * @memberof CaptureRequest
     */
    'uid'?: string;
    /**
     * Original file name when uploading.
     * @type {string}
     * @memberof CaptureRequest
     */
    'file_name'?: string;
    /**
     * Image file path in s3 without the bucket.
     * @type {string}
     * @memberof CaptureRequest
     */
    'image_uri'?: string;
    /**
     * Pre-signed public URL.
     * @type {string}
     * @memberof CaptureRequest
     */
    'image_url'?: string;
    /**
     * Thumbnail file path in s3 without the bucket.
     * @type {string}
     * @memberof CaptureRequest
     */
    'thumb_uri'?: string;
    /**
     * Pre-signed public URL.
     * @type {string}
     * @memberof CaptureRequest
     */
    'thumb_url'?: string;
    /**
     * Mask file path in s3 without the bucket.
     * @type {string}
     * @memberof CaptureRequest
     */
    'mask_uri'?: string;
    /**
     * Pre-signed public URL.
     * @type {string}
     * @memberof CaptureRequest
     */
    'mask_url'?: string;
    /**
     * 
     * @type {ThisAnchor}
     * @memberof CaptureRequest
     */
    'this_anchor'?: ThisAnchor;
    /**
     * List of favorite generated images related to this capture.
     * @type {Array<Anchor>}
     * @memberof CaptureRequest
     */
    'favorites'?: Array<Anchor>;
}
/**
 * 
 * @export
 * @interface CaptureRequestIn
 */
export interface CaptureRequestIn {
    /**
     * A title given to the file.
     * @type {string}
     * @memberof CaptureRequestIn
     */
    'title'?: string;
    /**
     * Caption given to file.
     * @type {string}
     * @memberof CaptureRequestIn
     */
    'caption'?: string;
    /**
     * 
     * @type {Location1}
     * @memberof CaptureRequestIn
     */
    'location'?: Location1;
    /**
     * 
     * @type {Array<Anchor>}
     * @memberof CaptureRequestIn
     */
    'anchors'?: Array<Anchor>;
    /**
     * Name of the study.
     * @type {string}
     * @memberof CaptureRequestIn
     */
    'study_name'?: string;
    /**
     * Responses.
     * @type {Array<StudyResponse>}
     * @memberof CaptureRequestIn
     */
    'study_response'?: Array<StudyResponse>;
    /**
     * This UID is linked to the StudyInstructionUID.
     * @type {string}
     * @memberof CaptureRequestIn
     */
    'study_instruction_uid'?: string;
    /**
     * This idx is linked to the index of StudyInstruction in a Study.
     * @type {number}
     * @memberof CaptureRequestIn
     */
    'study_instruction_idx'?: number;
    /**
     * This prompt is linked to the StudyInstruction.
     * @type {string}
     * @memberof CaptureRequestIn
     */
    'prompt'?: string;
    /**
     * These key-value pairs will be forwarded to the generate model.
     * @type {object}
     * @memberof CaptureRequestIn
     */
    'prompt_kwargs'?: object;
    /**
     * If True, use AI (REAL-ESRGAN) to downscale and upscale provided images.
     * @type {boolean}
     * @memberof CaptureRequestIn
     */
    'ai_scaling_enabled'?: boolean;
    /**
     * If True, moderation tool to determine if image meets the moderation criteria. This overrides the behavior of the study.
     * @type {boolean}
     * @memberof CaptureRequestIn
     */
    'image_moderation'?: boolean;
    /**
     * List of everything that the user has done.
     * @type {Array<CaptureStatus>}
     * @memberof CaptureRequestIn
     */
    'status'?: Array<CaptureStatus>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const CaptureStatus = {
    Init: 'INIT',
    UploadCapture: 'UPLOAD_CAPTURE',
    GenerateJourneyStories: 'GENERATE_JOURNEY_STORIES',
    JourneyStories: 'JOURNEY_STORIES',
    UpdateMask: 'UPDATE_MASK',
    UpdatePrompt: 'UPDATE_PROMPT',
    GenerateUidExists: 'GENERATE_UID_EXISTS',
    FavoriteGenerateImageSelected: 'FAVORITE_GENERATE_IMAGE_SELECTED',
    ReadFeedback: 'READ_FEEDBACK',
    ExploreWithAr: 'EXPLORE_WITH_AR',
    Finish: 'FINISH'
} as const;

export type CaptureStatus = typeof CaptureStatus[keyof typeof CaptureStatus];


/**
 * The conclusion text or url shown
 * @export
 * @interface Conclusion
 */
export interface Conclusion {
    /**
     * The admin provides text that should show to the user in a card at the conclusion of a study.
     * @type {string}
     * @memberof Conclusion
     */
    'text'?: string;
    /**
     * The admin provides a URL to which the user should be directed when the study concludes.
     * @type {string}
     * @memberof Conclusion
     */
    'url'?: string;
}
/**
 * Configuration parameters for this study.
 * @export
 * @interface Config
 */
export interface Config {
    /**
     * 
     * @type {Conclusion}
     * @memberof Config
     */
    'conclusion'?: Conclusion;
    /**
     * 
     * @type {JourneyStories}
     * @memberof Config
     */
    'journey_stories'?: JourneyStories;
    /**
     * 
     * @type {StudyModel}
     * @memberof Config
     */
    'study_model'?: StudyModel;
    /**
     * 
     * @type {Feedback1}
     * @memberof Config
     */
    'feedback'?: Feedback1;
}
/**
 * Features that are enabled for this study.
 * @export
 * @interface Features
 */
export interface Features {
    /**
     * After completing the prompted task, read other user\'s community stories.
     * @type {boolean}
     * @memberof Features
     */
    'view_journey_stories'?: boolean;
    /**
     * User is to provide other users feedback on their generated images.
     * @type {boolean}
     * @memberof Features
     */
    'provide_feedback'?: boolean;
    /**
     * User is prompted to generate images based on mask and prompt. Related to StudyModel.
     * @type {boolean}
     * @memberof Features
     */
    'generate_images'?: boolean;
    /**
     * Show conclusion based on text or url.
     * @type {boolean}
     * @memberof Features
     */
    'show_conclusion'?: boolean;
    /**
     * User uploads images and answer instructions.
     * @type {boolean}
     * @memberof Features
     */
    'user_responses'?: boolean;
    /**
     * Augmented Reality (AR) features for phone are shown.
     * @type {boolean}
     * @memberof Features
     */
    'ar'?: boolean;
    /**
     * Instruction panels are shown. Should always be True.
     * @type {boolean}
     * @memberof Features
     */
    'instructions'?: boolean;
    /**
     * Gallery page is shown. Should always be True.
     * @type {boolean}
     * @memberof Features
     */
    'view_gallery'?: boolean;
    /**
     * Enable image moderation when a user uploads a capture.
     * @type {boolean}
     * @memberof Features
     */
    'image_moderation'?: boolean;
}
/**
 * Items needed to update a feedback table entry with comment and rating.
 * @export
 * @interface Feedback
 */
export interface Feedback {
    /**
     * User ID making this request.
     * @type {string}
     * @memberof Feedback
     */
    'user_uid': string;
    /**
     * This generate_uid.
     * @type {string}
     * @memberof Feedback
     */
    'generate_uid': string;
    /**
     * The parent of this generated UID.
     * @type {string}
     * @memberof Feedback
     */
    'parent_capture_uid': string;
    /**
     * The reviewer, and owner of reviewer_capture_uid.
     * @type {string}
     * @memberof Feedback
     */
    'reviewer_user_uid': string;
    /**
     * The reviewer, and owner of reviewer_capture_uid.
     * @type {string}
     * @memberof Feedback
     */
    'reviewer_capture_uid': string;
    /**
     * Written comment.
     * @type {string}
     * @memberof Feedback
     */
    'comment'?: string;
    /**
     * Optional: Rating for the entry.
     * @type {number}
     * @memberof Feedback
     */
    'rating'?: number;
    /**
     * The user_uid has seen the comments from reviewer and inserted a new generated uid.
     * @type {number}
     * @memberof Feedback
     */
    'acknowledged'?: number;
    /**
     * The user_uid can mark that they have completed all redesigns.
     * @type {boolean}
     * @memberof Feedback
     */
    'user_completed'?: boolean;
    /**
     * The reviewer can mark they are satisfied with the redesign.
     * @type {boolean}
     * @memberof Feedback
     */
    'reviewer_completed'?: boolean;
}
/**
 * Feedback specific parameters.
 * @export
 * @interface Feedback1
 */
export interface Feedback1 {
    /**
     * Minium number of revisions the designer needs to do OR reviewer marks it as accepted.
     * @type {number}
     * @memberof Feedback1
     */
    'min_revisions'?: number;
    /**
     * Maximum number of revisions the designer can submit.
     * @type {number}
     * @memberof Feedback1
     */
    'max_revisions'?: number;
}
/**
 * The minimum items needed to create a feedback table entry.
 * @export
 * @interface FeedbackInitial
 */
export interface FeedbackInitial {
    /**
     * User ID making this request.
     * @type {string}
     * @memberof FeedbackInitial
     */
    'user_uid': string;
    /**
     * This generate_uid.
     * @type {string}
     * @memberof FeedbackInitial
     */
    'generate_uid': string;
    /**
     * The parent of this generated UID.
     * @type {string}
     * @memberof FeedbackInitial
     */
    'parent_capture_uid': string;
    /**
     * The reviewer, and owner of reviewer_capture_uid.
     * @type {string}
     * @memberof FeedbackInitial
     */
    'reviewer_user_uid': string;
    /**
     * The reviewer, and owner of reviewer_capture_uid.
     * @type {string}
     * @memberof FeedbackInitial
     */
    'reviewer_capture_uid': string;
}
/**
 * Items needed to update a feedback table entry with comment and rating.
 * @export
 * @interface FeedbackRating
 */
export interface FeedbackRating {
    /**
     * User ID making this request.
     * @type {string}
     * @memberof FeedbackRating
     */
    'user_uid': string;
    /**
     * This generate_uid.
     * @type {string}
     * @memberof FeedbackRating
     */
    'generate_uid': string;
    /**
     * Written comment.
     * @type {string}
     * @memberof FeedbackRating
     */
    'comment'?: string;
    /**
     * Optional: Rating for the entry.
     * @type {number}
     * @memberof FeedbackRating
     */
    'rating'?: number;
}
/**
 * Used for the generate media call.
 * @export
 * @interface GenerateRequest
 */
export interface GenerateRequest {
    /**
     * The image uid can be either the capture_uid, or generate_uid.
     * @type {string}
     * @memberof GenerateRequest
     */
    'image_uid'?: string;
    /**
     * The pre-signed mask url.
     * @type {string}
     * @memberof GenerateRequest
     */
    'mask_url'?: string;
    /**
     * Pre-signed public URL.
     * @type {string}
     * @memberof GenerateRequest
     */
    'image_url'?: string;
    /**
     * Pre-signed public URL.
     * @type {string}
     * @memberof GenerateRequest
     */
    'thumb_url'?: string;
    /**
     * S3 location.
     * @type {string}
     * @memberof GenerateRequest
     */
    'mask_uri'?: string;
    /**
     * S3 location.
     * @type {string}
     * @memberof GenerateRequest
     */
    'image_uri'?: string;
    /**
     * Thumbnail file path in s3 without the bucket.
     * @type {string}
     * @memberof GenerateRequest
     */
    'thumb_uri'?: string;
    /**
     * The input prompt to the request
     * @type {string}
     * @memberof GenerateRequest
     */
    'prompt': string;
    /**
     * 
     * @type {Model}
     * @memberof GenerateRequest
     */
    'model': Model;
    /**
     * 
     * @type {Array<Anchor>}
     * @memberof GenerateRequest
     */
    'anchors'?: Array<Anchor>;
    /**
     * Optional: Description of this request.
     * @type {string}
     * @memberof GenerateRequest
     */
    'description'?: string;
    /**
     * This are optional kwargs that are passed to the inference.
     * @type {object}
     * @memberof GenerateRequest
     */
    'kwargs'?: object;
    /**
     * Optional: Name of the study.
     * @type {string}
     * @memberof GenerateRequest
     */
    'study_name'?: string;
    /**
     * This UID is linked to the StudyInstructionUID.
     * @type {string}
     * @memberof GenerateRequest
     */
    'study_instruction_uid'?: string;
    /**
     * This idx is linked to the index of StudyInstruction in a Study.
     * @type {number}
     * @memberof GenerateRequest
     */
    'study_instruction_idx'?: number;
    /**
     * 
     * @type {ThisAnchor}
     * @memberof GenerateRequest
     */
    'this_anchor'?: ThisAnchor;
    /**
     * If True, use AI (REAL-ESRGAN) to downscale and upscale provided images.
     * @type {boolean}
     * @memberof GenerateRequest
     */
    'ai_scaling_enabled'?: boolean;
    /**
     * User ID making this request.
     * @type {string}
     * @memberof GenerateRequest
     */
    'user_uid': string;
    /**
     * UUID4 generated by the backend.
     * @type {string}
     * @memberof GenerateRequest
     */
    'uid'?: string;
    /**
     * Optional: Default datetime.now().
     * @type {string}
     * @memberof GenerateRequest
     */
    'timestamp'?: string;
    /**
     * capture_uid associated with this request.
     * @type {string}
     * @memberof GenerateRequest
     */
    'capture_uid'?: string;
    /**
     * Optional: reviewed status.
     * @type {boolean}
     * @memberof GenerateRequest
     */
    'reviewed'?: boolean;
    /**
     * Optional: user_uid when request is not for current user_uid.
     * @type {string}
     * @memberof GenerateRequest
     */
    'receiver_user_uid'?: string;
    /**
     * Store the response from generative models.
     * @type {object}
     * @memberof GenerateRequest
     */
    'generate_api_response'?: object;
    /**
     * Store the InferenceRequest made to generative models.
     * @type {object}
     * @memberof GenerateRequest
     */
    'generate_api_request'?: object;
    /**
     * 
     * @type {Metadata}
     * @memberof GenerateRequest
     */
    'metadata'?: Metadata;
    /**
     * Cropped image is used for AR features.
     * @type {string}
     * @memberof GenerateRequest
     */
    'cropped_uri'?: string;
    /**
     * Cropped image url will be populated when the study is retrieved.
     * @type {string}
     * @memberof GenerateRequest
     */
    'cropped_url'?: string;
}


/**
 * Used for the generate media call.
 * @export
 * @interface GenerateRequestIn
 */
export interface GenerateRequestIn {
    /**
     * The image uid can be either the capture_uid, or generate_uid.
     * @type {string}
     * @memberof GenerateRequestIn
     */
    'image_uid'?: string;
    /**
     * The pre-signed mask url.
     * @type {string}
     * @memberof GenerateRequestIn
     */
    'mask_url'?: string;
    /**
     * Pre-signed public URL.
     * @type {string}
     * @memberof GenerateRequestIn
     */
    'image_url'?: string;
    /**
     * Pre-signed public URL.
     * @type {string}
     * @memberof GenerateRequestIn
     */
    'thumb_url'?: string;
    /**
     * S3 location.
     * @type {string}
     * @memberof GenerateRequestIn
     */
    'mask_uri'?: string;
    /**
     * S3 location.
     * @type {string}
     * @memberof GenerateRequestIn
     */
    'image_uri'?: string;
    /**
     * Thumbnail file path in s3 without the bucket.
     * @type {string}
     * @memberof GenerateRequestIn
     */
    'thumb_uri'?: string;
    /**
     * The input prompt to the request
     * @type {string}
     * @memberof GenerateRequestIn
     */
    'prompt': string;
    /**
     * 
     * @type {Model}
     * @memberof GenerateRequestIn
     */
    'model': Model;
    /**
     * 
     * @type {Array<Anchor>}
     * @memberof GenerateRequestIn
     */
    'anchors'?: Array<Anchor>;
    /**
     * Optional: Description of this request.
     * @type {string}
     * @memberof GenerateRequestIn
     */
    'description'?: string;
    /**
     * This are optional kwargs that are passed to the inference.
     * @type {object}
     * @memberof GenerateRequestIn
     */
    'kwargs'?: object;
    /**
     * Optional: Name of the study.
     * @type {string}
     * @memberof GenerateRequestIn
     */
    'study_name'?: string;
    /**
     * This UID is linked to the StudyInstructionUID.
     * @type {string}
     * @memberof GenerateRequestIn
     */
    'study_instruction_uid'?: string;
    /**
     * This idx is linked to the index of StudyInstruction in a Study.
     * @type {number}
     * @memberof GenerateRequestIn
     */
    'study_instruction_idx'?: number;
    /**
     * 
     * @type {ThisAnchor}
     * @memberof GenerateRequestIn
     */
    'this_anchor'?: ThisAnchor;
    /**
     * If True, use AI (REAL-ESRGAN) to downscale and upscale provided images.
     * @type {boolean}
     * @memberof GenerateRequestIn
     */
    'ai_scaling_enabled'?: boolean;
}


/**
 * 
 * @export
 * @interface GenerateRequestMetadata
 */
export interface GenerateRequestMetadata {
    /**
     * UTC time when created.
     * @type {string}
     * @memberof GenerateRequestMetadata
     */
    'created_at'?: string;
    /**
     * UTC time when completed.
     * @type {string}
     * @memberof GenerateRequestMetadata
     */
    'completed_at'?: string;
    /**
     * 
     * @type {Status}
     * @memberof GenerateRequestMetadata
     */
    'status'?: Status;
}


/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * Journey specific config parameters for community stories.
 * @export
 * @interface JourneyStories
 */
export interface JourneyStories {
    /**
     * 
     * @type {StudyJourneyModes}
     * @memberof JourneyStories
     */
    'mode'?: StudyJourneyModes;
    /**
     * Number of journeys to show.
     * @type {number}
     * @memberof JourneyStories
     */
    'length'?: number;
}


/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'latitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'longitude'?: string;
}
/**
 * Lat and Long of image
 * @export
 * @interface Location1
 */
export interface Location1 {
    /**
     * 
     * @type {string}
     * @memberof Location1
     */
    'latitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof Location1
     */
    'longitude'?: string;
}
/**
 * 
 * @export
 * @interface Location2Inner
 */
export interface Location2Inner {
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const LogLevel = {
    Debug: 'DEBUG',
    Info: 'INFO',
    Warning: 'WARNING',
    Error: 'ERROR',
    Fatal: 'FATAL'
} as const;

export type LogLevel = typeof LogLevel[keyof typeof LogLevel];


/**
 * 
 * @export
 * @interface LogRequest
 */
export interface LogRequest {
    /**
     * The log message.
     * @type {string}
     * @memberof LogRequest
     */
    'message': string;
    /**
     * 
     * @type {LogLevel}
     * @memberof LogRequest
     */
    'log_level'?: LogLevel;
    /**
     * Source of the log.
     * @type {string}
     * @memberof LogRequest
     */
    'source'?: string;
    /**
     * Optional: Default datetime.now().
     * @type {string}
     * @memberof LogRequest
     */
    'timestamp'?: string;
    /**
     * UUID4 generated by the backend.
     * @type {string}
     * @memberof LogRequest
     */
    'uid': string;
    /**
     * Optional: user_uid associated with this log.
     * @type {string}
     * @memberof LogRequest
     */
    'user_uid'?: string;
    /**
     * File path of an image associated with this log.
     * @type {string}
     * @memberof LogRequest
     */
    'file_path'?: string;
    /**
     * File URL of an image associated with this log.
     * @type {string}
     * @memberof LogRequest
     */
    'file_url'?: string;
}


/**
 * 
 * @export
 * @interface LogRequestBase
 */
export interface LogRequestBase {
    /**
     * The log message.
     * @type {string}
     * @memberof LogRequestBase
     */
    'message': string;
    /**
     * 
     * @type {LogLevel}
     * @memberof LogRequestBase
     */
    'log_level'?: LogLevel;
    /**
     * Source of the log.
     * @type {string}
     * @memberof LogRequestBase
     */
    'source'?: string;
}


/**
 * Metadata for status
 * @export
 * @interface Metadata
 */
export interface Metadata {
    /**
     * UTC time when created.
     * @type {string}
     * @memberof Metadata
     */
    'created_at'?: string;
    /**
     * UTC time when completed.
     * @type {string}
     * @memberof Metadata
     */
    'completed_at'?: string;
    /**
     * 
     * @type {Status}
     * @memberof Metadata
     */
    'status'?: Status;
}


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const Model = {
    Undefined: 'UNDEFINED',
    Openai: 'OPENAI',
    StableDiffusionTxt2Img: 'STABLE_DIFFUSION_TXT2IMG',
    StableDiffusionImg2Img: 'STABLE_DIFFUSION_IMG2IMG',
    RealEsrgan: 'REAL_ESRGAN',
    Inpainting: 'INPAINTING',
    Gligen: 'GLIGEN',
    BlendedLatentDiffusion: 'BLENDED_LATENT_DIFFUSION'
} as const;

export type Model = typeof Model[keyof typeof Model];


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const Pilot = {
    No: 'NO',
    Yes: 'YES'
} as const;

export type Pilot = typeof Pilot[keyof typeof Pilot];


/**
 * 
 * @export
 * @interface ResponseGetAllGeneratedMediaForUserApiGenerateGet
 */
export interface ResponseGetAllGeneratedMediaForUserApiGenerateGet {
}
/**
 * 
 * @export
 * @interface ResponseGetAllStudyApiStudyAllGet
 */
export interface ResponseGetAllStudyApiStudyAllGet {
}
/**
 * 
 * @export
 * @interface ResponseGetAllStudyDetailsApiStudyAllDetailsGet
 */
export interface ResponseGetAllStudyDetailsApiStudyAllDetailsGet {
}
/**
 * Can also add `reset` when passing to create_sequence_models
 * @export
 * @interface SequencePair
 */
export interface SequencePair {
    /**
     * 
     * @type {string}
     * @memberof SequencePair
     */
    'study_name': string;
    /**
     * 
     * @type {number}
     * @memberof SequencePair
     */
    'study_instruction_idx': number;
}
/**
 * Can also add `reset` when passing to create_sequence_models
 * @export
 * @interface SequenceRequest
 */
export interface SequenceRequest {
    /**
     * 
     * @type {Array<SequencePair>}
     * @memberof SequenceRequest
     */
    'sequence': Array<SequencePair>;
    /**
     * 
     * @type {string}
     * @memberof SequenceRequest
     */
    'study_name': string;
    /**
     * 
     * @type {number}
     * @memberof SequenceRequest
     */
    'study_instruction_idx': number;
    /**
     * Number of clusters to create for model.
     * @type {number}
     * @memberof SequenceRequest
     */
    'number_of_clusters'?: number;
    /**
     * Number of neighbors to create for model.
     * @type {number}
     * @memberof SequenceRequest
     */
    'n_neighbors'?: number;
    /**
     * When true, dont load any pre-existing data.
     * @type {boolean}
     * @memberof SequenceRequest
     */
    'reset'?: boolean;
}
/**
 * 
 * @export
 * @interface SequenceRequestIn
 */
export interface SequenceRequestIn {
    /**
     * 
     * @type {Array<SequencePair>}
     * @memberof SequenceRequestIn
     */
    'sequence': Array<SequencePair>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const State = {
    None: 'NONE',
    ProfileSetup: 'PROFILE_SETUP',
    StudySetup: 'STUDY_SETUP',
    Welcome: 'WELCOME',
    WalkThroughOfStudy: 'WALK_THROUGH_OF_STUDY',
    LandingGallery: 'LANDING_GALLERY',
    StudyInstructionStarted: 'STUDY_INSTRUCTION_STARTED',
    StudyInstructionFinished: 'STUDY_INSTRUCTION_FINISHED'
} as const;

export type State = typeof State[keyof typeof State];


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const Status = {
    Starting: 'STARTING',
    Waiting: 'WAITING',
    Processing: 'PROCESSING',
    Completed: 'COMPLETED',
    Failed: 'FAILED',
    Unknown: 'UNKNOWN'
} as const;

export type Status = typeof Status[keyof typeof Status];


/**
 * 
 * @export
 * @interface StudyConclusion
 */
export interface StudyConclusion {
    /**
     * The admin provides text that should show to the user in a card at the conclusion of a study.
     * @type {string}
     * @memberof StudyConclusion
     */
    'text'?: string;
    /**
     * The admin provides a URL to which the user should be directed when the study concludes.
     * @type {string}
     * @memberof StudyConclusion
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface StudyConfig
 */
export interface StudyConfig {
    /**
     * 
     * @type {Conclusion}
     * @memberof StudyConfig
     */
    'conclusion'?: Conclusion;
    /**
     * 
     * @type {JourneyStories}
     * @memberof StudyConfig
     */
    'journey_stories'?: JourneyStories;
    /**
     * 
     * @type {StudyModel}
     * @memberof StudyConfig
     */
    'study_model'?: StudyModel;
    /**
     * 
     * @type {Feedback1}
     * @memberof StudyConfig
     */
    'feedback'?: Feedback1;
}
/**
 * 
 * @export
 * @interface StudyFeatures
 */
export interface StudyFeatures {
    /**
     * After completing the prompted task, read other user\'s community stories.
     * @type {boolean}
     * @memberof StudyFeatures
     */
    'view_journey_stories'?: boolean;
    /**
     * User is to provide other users feedback on their generated images.
     * @type {boolean}
     * @memberof StudyFeatures
     */
    'provide_feedback'?: boolean;
    /**
     * User is prompted to generate images based on mask and prompt. Related to StudyModel.
     * @type {boolean}
     * @memberof StudyFeatures
     */
    'generate_images'?: boolean;
    /**
     * Show conclusion based on text or url.
     * @type {boolean}
     * @memberof StudyFeatures
     */
    'show_conclusion'?: boolean;
    /**
     * User uploads images and answer instructions.
     * @type {boolean}
     * @memberof StudyFeatures
     */
    'user_responses'?: boolean;
    /**
     * Augmented Reality (AR) features for phone are shown.
     * @type {boolean}
     * @memberof StudyFeatures
     */
    'ar'?: boolean;
    /**
     * Instruction panels are shown. Should always be True.
     * @type {boolean}
     * @memberof StudyFeatures
     */
    'instructions'?: boolean;
    /**
     * Gallery page is shown. Should always be True.
     * @type {boolean}
     * @memberof StudyFeatures
     */
    'view_gallery'?: boolean;
    /**
     * Enable image moderation when a user uploads a capture.
     * @type {boolean}
     * @memberof StudyFeatures
     */
    'image_moderation'?: boolean;
}
/**
 * 
 * @export
 * @interface StudyFeedbackConfig
 */
export interface StudyFeedbackConfig {
    /**
     * Minium number of revisions the designer needs to do OR reviewer marks it as accepted.
     * @type {number}
     * @memberof StudyFeedbackConfig
     */
    'min_revisions'?: number;
    /**
     * Maximum number of revisions the designer can submit.
     * @type {number}
     * @memberof StudyFeedbackConfig
     */
    'max_revisions'?: number;
}
/**
 * This is the agreement with the Figma design.
 * @export
 * @interface StudyInstruction
 */
export interface StudyInstruction {
    /**
     * UUID4 generated by the backend.
     * @type {string}
     * @memberof StudyInstruction
     */
    'uid'?: string;
    /**
     * If empty, use the parent default_questions.
     * @type {Array<StudyQuestion>}
     * @memberof StudyInstruction
     */
    'questions'?: Array<StudyQuestion>;
    /**
     * The title that will be bolded.
     * @type {string}
     * @memberof StudyInstruction
     */
    'title'?: string;
    /**
     * The instruction to guide the user.
     * @type {string}
     * @memberof StudyInstruction
     */
    'instruction'?: string;
    /**
     * The caption that will be shown below the picture.
     * @type {string}
     * @memberof StudyInstruction
     */
    'caption': string;
    /**
     * This keyword is used for various pages and describes this instruction. For example, it can be \'safe\', \'unsafe\', \'welcome\', \'unwelcome\', etc.
     * @type {string}
     * @memberof StudyInstruction
     */
    'keyword'?: string;
    /**
     * If non-empty, use the upload image API and paste the uri (s3 object location only!).
     * @type {string}
     * @memberof StudyInstruction
     */
    'image_uri'?: string;
    /**
     * This will be populated when the study is retrieved.
     * @type {string}
     * @memberof StudyInstruction
     */
    'image_url'?: string;
    /**
     * If non-empty, use the use the upload image API and paste the uri (s3 object location only!).
     * @type {string}
     * @memberof StudyInstruction
     */
    'mask_uri'?: string;
    /**
     * This will be populated when the study is retrieved.
     * @type {string}
     * @memberof StudyInstruction
     */
    'mask_url'?: string;
    /**
     * If non-empty, this prompt replaces a human-entered prompt.
     * @type {string}
     * @memberof StudyInstruction
     */
    'generative_ai_prompt'?: string;
    /**
     * 
     * @type {StudyInstructionPromptModes}
     * @memberof StudyInstruction
     */
    'prompt_visibility'?: StudyInstructionPromptModes;
    /**
     * The minimum number of responses for this instruction.
     * @type {number}
     * @memberof StudyInstruction
     */
    'min_responses'?: number;
    /**
     * The number maximum of responses for this instruction.
     * @type {number}
     * @memberof StudyInstruction
     */
    'max_responses'?: number;
}


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const StudyInstructionPromptModes = {
    Read: 'READ',
    ReadWrite: 'READ_WRITE',
    Write: 'WRITE',
    Hidden: 'HIDDEN',
    NotUsed: 'NOT_USED'
} as const;

export type StudyInstructionPromptModes = typeof StudyInstructionPromptModes[keyof typeof StudyInstructionPromptModes];


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const StudyJourneyModes = {
    Off: 'OFF',
    Random: 'RANDOM',
    Chronological: 'CHRONOLOGICAL',
    ChronologicalReverse: 'CHRONOLOGICAL_REVERSE',
    AgglomerativeClustering: 'AGGLOMERATIVE_CLUSTERING'
} as const;

export type StudyJourneyModes = typeof StudyJourneyModes[keyof typeof StudyJourneyModes];


/**
 * 
 * @export
 * @interface StudyJourneyStoriesConfig
 */
export interface StudyJourneyStoriesConfig {
    /**
     * 
     * @type {StudyJourneyModes}
     * @memberof StudyJourneyStoriesConfig
     */
    'mode'?: StudyJourneyModes;
    /**
     * Number of journeys to show.
     * @type {number}
     * @memberof StudyJourneyStoriesConfig
     */
    'length'?: number;
}


/**
 * Describe the generative models and specific parameters.
 * @export
 * @interface StudyModel
 */
export interface StudyModel {
    /**
     * This should be a list of models that are valid for this study type.
     * @type {Array<StudyModelWithParams>}
     * @memberof StudyModel
     */
    'available_models': Array<StudyModelWithParams>;
    /**
     * 
     * @type {StudyModelSelection}
     * @memberof StudyModel
     */
    'selection_criteria'?: StudyModelSelection;
    /**
     * When selection_criteria is `select_index`, then index maps to the available_models. For example: number_of_generated_media=2, available_models=[OPENAI, STABLE_DIFFUSION_TXT2IMG] and select_index=[2], then the generate script will use STABLE_DIFFUSION_TXT2IMG twice.
     * @type {Array<number>}
     * @memberof StudyModel
     */
    'select_index'?: Array<number>;
    /**
     * Number of generated media.
     * @type {number}
     * @memberof StudyModel
     */
    'number_of_generated_media'?: number;
}


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const StudyModelSelection = {
    Random: 'RANDOM',
    InOrder: 'IN_ORDER',
    SelectIndex: 'SELECT_INDEX'
} as const;

export type StudyModelSelection = typeof StudyModelSelection[keyof typeof StudyModelSelection];


/**
 * 
 * @export
 * @interface StudyModelWithParams
 */
export interface StudyModelWithParams {
    /**
     * 
     * @type {Model}
     * @memberof StudyModelWithParams
     */
    'name': Model;
    /**
     * 
     * @type {object}
     * @memberof StudyModelWithParams
     */
    'params'?: object;
}


/**
 * 
 * @export
 * @interface StudyQuestion
 */
export interface StudyQuestion {
    /**
     * This is the question that the user must answer.
     * @type {string}
     * @memberof StudyQuestion
     */
    'text': string;
    /**
     * 
     * @type {StudyQuestionType}
     * @memberof StudyQuestion
     */
    'type'?: StudyQuestionType;
    /**
     * This is shown on the view image page above the users response.
     * @type {string}
     * @memberof StudyQuestion
     */
    'response_title'?: string;
    /**
     * 
     * @type {StudyQuestionLabel}
     * @memberof StudyQuestion
     */
    'label'?: StudyQuestionLabel;
}


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const StudyQuestionLabel = {
    PlaceDescription: 'PLACE_DESCRIPTION',
    HowToModify: 'HOW_TO_MODIFY',
    Other: 'OTHER'
} as const;

export type StudyQuestionLabel = typeof StudyQuestionLabel[keyof typeof StudyQuestionLabel];


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const StudyQuestionType = {
    Open: 'OPEN',
    Bool: 'BOOL',
    Rating: 'RATING',
    Null: 'NULL'
} as const;

export type StudyQuestionType = typeof StudyQuestionType[keyof typeof StudyQuestionType];


/**
 * 
 * @export
 * @interface StudyRequest
 */
export interface StudyRequest {
    /**
     * Unique study name.
     * @type {string}
     * @memberof StudyRequest
     */
    'study_name': string;
    /**
     * Description of this study.
     * @type {string}
     * @memberof StudyRequest
     */
    'description'?: string;
    /**
     * Each StudyInstruction appears as a panel in the study.
     * @type {Array<StudyInstruction>}
     * @memberof StudyRequest
     */
    'instructions': Array<StudyInstruction>;
    /**
     * If empty, use the parent default_questions.
     * @type {Array<StudyQuestion>}
     * @memberof StudyRequest
     */
    'default_questions'?: Array<StudyQuestion>;
    /**
     * 
     * @type {Config}
     * @memberof StudyRequest
     */
    'config'?: Config;
    /**
     * 
     * @type {Features}
     * @memberof StudyRequest
     */
    'features'?: Features;
    /**
     * UUID4 generated by the backend.
     * @type {string}
     * @memberof StudyRequest
     */
    'uid'?: string;
    /**
     * 
     * @type {StudyStatus}
     * @memberof StudyRequest
     */
    'status'?: StudyStatus;
    /**
     * Email is used to notify study admins of issues encountered.
     * @type {Array<string>}
     * @memberof StudyRequest
     */
    'study_admin_emails'?: Array<string>;
}


/**
 * 
 * @export
 * @interface StudyRequestBase
 */
export interface StudyRequestBase {
    /**
     * Unique study name.
     * @type {string}
     * @memberof StudyRequestBase
     */
    'study_name': string;
    /**
     * Description of this study.
     * @type {string}
     * @memberof StudyRequestBase
     */
    'description'?: string;
    /**
     * Each StudyInstruction appears as a panel in the study.
     * @type {Array<StudyInstruction>}
     * @memberof StudyRequestBase
     */
    'instructions': Array<StudyInstruction>;
    /**
     * If empty, use the parent default_questions.
     * @type {Array<StudyQuestion>}
     * @memberof StudyRequestBase
     */
    'default_questions'?: Array<StudyQuestion>;
    /**
     * 
     * @type {Config}
     * @memberof StudyRequestBase
     */
    'config'?: Config;
    /**
     * 
     * @type {Features}
     * @memberof StudyRequestBase
     */
    'features'?: Features;
}
/**
 * 
 * @export
 * @interface StudyResponse
 */
export interface StudyResponse {
    /**
     * This is the question that the user must answer.
     * @type {string}
     * @memberof StudyResponse
     */
    'text': string;
    /**
     * 
     * @type {StudyQuestionType}
     * @memberof StudyResponse
     */
    'type'?: StudyQuestionType;
    /**
     * This is shown on the view image page above the users response.
     * @type {string}
     * @memberof StudyResponse
     */
    'response_title'?: string;
    /**
     * 
     * @type {StudyQuestionLabel}
     * @memberof StudyResponse
     */
    'label'?: StudyQuestionLabel;
    /**
     * This is the response captured from the user.
     * @type {any}
     * @memberof StudyResponse
     */
    'response'?: any;
}


/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const StudyStatus = {
    UnderReview: 'UNDER_REVIEW',
    Ready: 'READY',
    Active: 'ACTIVE',
    Complete: 'COMPLETE',
    Test: 'TEST'
} as const;

export type StudyStatus = typeof StudyStatus[keyof typeof StudyStatus];


/**
 * 
 * @export
 * @interface StudyUsersRequest
 */
export interface StudyUsersRequest {
    /**
     * User ID making this request.
     * @type {string}
     * @memberof StudyUsersRequest
     */
    'user_uid': string;
    /**
     * Study Name.
     * @type {string}
     * @memberof StudyUsersRequest
     */
    'study_name': string;
    /**
     * UUID4 generated by the backend.
     * @type {string}
     * @memberof StudyUsersRequest
     */
    'uid'?: string;
    /**
     * 
     * @type {string}
     * @memberof StudyUsersRequest
     */
    'study_uid': string;
}
/**
 * 
 * @export
 * @interface StudyUsersRequestBase
 */
export interface StudyUsersRequestBase {
    /**
     * User ID making this request.
     * @type {string}
     * @memberof StudyUsersRequestBase
     */
    'user_uid': string;
    /**
     * Study Name.
     * @type {string}
     * @memberof StudyUsersRequestBase
     */
    'study_name': string;
}
/**
 * Hold the image, thumbnail, and mask in one entity.
 * @export
 * @interface ThisAnchor
 */
export interface ThisAnchor {
    /**
     * The image uid can be either the capture_uid, or generate_uid.
     * @type {string}
     * @memberof ThisAnchor
     */
    'image_uid'?: string;
    /**
     * The pre-signed mask url.
     * @type {string}
     * @memberof ThisAnchor
     */
    'mask_url'?: string;
    /**
     * Pre-signed public URL.
     * @type {string}
     * @memberof ThisAnchor
     */
    'image_url'?: string;
    /**
     * Pre-signed public URL.
     * @type {string}
     * @memberof ThisAnchor
     */
    'thumb_url'?: string;
    /**
     * S3 location.
     * @type {string}
     * @memberof ThisAnchor
     */
    'mask_uri'?: string;
    /**
     * S3 location.
     * @type {string}
     * @memberof ThisAnchor
     */
    'image_uri'?: string;
    /**
     * Thumbnail file path in s3 without the bucket.
     * @type {string}
     * @memberof ThisAnchor
     */
    'thumb_uri'?: string;
}
/**
 * 
 * @export
 * @interface UserGroupRequest
 */
export interface UserGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof UserGroupRequest
     */
    'user_uid': string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupRequest
     */
    'group_name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserGroupRequest
     */
    'users': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserGroupRequest
     */
    'uid': string;
}
/**
 * 
 * @export
 * @interface UserState
 */
export interface UserState {
    /**
     * User ID making this request.
     * @type {string}
     * @memberof UserState
     */
    'user_uid': string;
    /**
     * 
     * @type {State}
     * @memberof UserState
     */
    'state'?: State;
    /**
     * The name of the study
     * @type {string}
     * @memberof UserState
     */
    'study_name'?: string;
}


/**
 * 
 * @export
 * @interface UserStateOutput
 */
export interface UserStateOutput {
    /**
     * User ID making this request.
     * @type {string}
     * @memberof UserStateOutput
     */
    'user_uid': string;
    /**
     * 
     * @type {State}
     * @memberof UserStateOutput
     */
    'state'?: State;
    /**
     * The name of the study
     * @type {string}
     * @memberof UserStateOutput
     */
    'study_name'?: string;
    /**
     * Optional: Default datetime.now().
     * @type {string}
     * @memberof UserStateOutput
     */
    'timestamp'?: string;
    /**
     * 
     * @type {State}
     * @memberof UserStateOutput
     */
    'previous_state'?: State;
    /**
     * Used by backend to store if this is the latest.
     * @type {boolean}
     * @memberof UserStateOutput
     */
    'is_latest'?: boolean;
}


/**
 * 
 * @export
 * @interface UsernameRequest
 */
export interface UsernameRequest {
    /**
     * 
     * @type {string}
     * @memberof UsernameRequest
     */
    'profile_name': string;
    /**
     * 
     * @type {string}
     * @memberof UsernameRequest
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof UsernameRequest
     */
    'uid': string;
    /**
     * 
     * @type {Pilot}
     * @memberof UsernameRequest
     */
    'pilot'?: Pilot;
}


/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<Location2Inner>}
     * @memberof ValidationError
     */
    'loc': Array<Location2Inner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}

/**
 * CaptureApi - axios parameter creator
 * @export
 */
export const CaptureApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Favorite Generated For Capture
         * @param {string} uid 
         * @param {string} generateUid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFavoriteGeneratedForCapture: async (uid: string, generateUid: string, userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('addFavoriteGeneratedForCapture', 'uid', uid)
            // verify required parameter 'generateUid' is not null or undefined
            assertParamExists('addFavoriteGeneratedForCapture', 'generateUid', generateUid)
            const localVarPath = `/api/capture/favorites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (generateUid !== undefined) {
                localVarQueryParameter['generate_uid'] = generateUid;
            }

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a new status for this capture.  **Args:** - uid (str): Capture UID - status (CaptureStatus): Status  **Returns:** - CaptureRequest
         * @summary Add Status To Capture
         * @param {string} uid 
         * @param {CaptureStatus} status 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStatusToCapture: async (uid: string, status: CaptureStatus, userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('addStatusToCapture', 'uid', uid)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('addStatusToCapture', 'status', status)
            const localVarPath = `/api/capture/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Favorite Generated For Capture
         * @param {string} uid 
         * @param {string} generateUid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFavoriteGeneratedForCapture: async (uid: string, generateUid: string, userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('deleteFavoriteGeneratedForCapture', 'uid', uid)
            // verify required parameter 'generateUid' is not null or undefined
            assertParamExists('deleteFavoriteGeneratedForCapture', 'generateUid', generateUid)
            const localVarPath = `/api/capture/favorites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (generateUid !== undefined) {
                localVarQueryParameter['generate_uid'] = generateUid;
            }

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the given capture uid.  **Args:** - uid (str): UID of capture. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.
         * @summary Delete User Capture
         * @param {string} uid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserCapture: async (uid: string, userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('deleteUserCapture', 'uid', uid)
            const localVarPath = `/api/capture`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all captures for the `user_uid`.  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid.
         * @summary Get All Captures From User
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCapturesFromUser: async (userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/capture`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get `uid` capture for the `user_uid`.  **Args:** - uid (str): Specific capture uid. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.
         * @summary Get Capture From User
         * @param {string} uid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCaptureFromUser: async (uid: string, userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('getCaptureFromUser', 'uid', uid)
            const localVarPath = `/api/capture/uid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Favorite Generated For Capture
         * @param {string} uid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoriteGeneratedForCapture: async (uid: string, userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('getFavoriteGeneratedForCapture', 'uid', uid)
            const localVarPath = `/api/capture/favorites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get `uid` capture for the `user_uid`.  **Args:** - study_name (str): Study name. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.
         * @summary Get User Captures For Study
         * @param {string} studyName 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCapturesForStudy: async (studyName: string, userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'studyName' is not null or undefined
            assertParamExists('getUserCapturesForStudy', 'studyName', studyName)
            const localVarPath = `/api/capture/study_name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (studyName !== undefined) {
                localVarQueryParameter['study_name'] = studyName;
            }

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use the image moderation tool to determine if the image violates any categories.  **Args:** - image (UploadFile)
         * @summary Moderate Image Content
         * @param {File} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moderateImageContent: async (image: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'image' is not null or undefined
            assertParamExists('moderateImageContent', 'image', image)
            const localVarPath = `/api/capture/moderate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the given capture uid.  **NOTE** Only the following can be changed: - prompt - prompt_kwargs - study_response - mask  **Args:** - capture (CaptureRequest): Capture. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.
         * @summary Update User Capture
         * @param {string} uid 
         * @param {CaptureRequestIn} request 
         * @param {string} [userUid] 
         * @param {File} [mask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserCapture: async (uid: string, request: CaptureRequestIn, userUid?: string, mask?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('updateUserCapture', 'uid', uid)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('updateUserCapture', 'request', request)
            const localVarPath = `/api/capture/uid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


            if (request !== undefined) { 
                localVarFormParams.append('request', new Blob([JSON.stringify(request)], { type: "application/json", }));
            }
    
            if (mask !== undefined) { 
                localVarFormParams.append('mask', mask as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload file and update capture table.  **Args:** - file (UploadFile): File to be uploaded.  **Returns:** - CaptureRequest: Capture upload details.
         * @summary Upload File
         * @param {File} file 
         * @param {CaptureRequestIn} request 
         * @param {string} [userUid] 
         * @param {File} [mask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (file: File, request: CaptureRequestIn, userUid?: string, mask?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadFile', 'file', file)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('uploadFile', 'request', request)
            const localVarPath = `/api/capture/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (request !== undefined) { 
                localVarFormParams.append('request', new Blob([JSON.stringify(request)], { type: "application/json", }));
            }
    
            if (mask !== undefined) { 
                localVarFormParams.append('mask', mask as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload a file to the S3 servers. This function can only be used by admins and will be used to store data that might be needed by other services. The data will be stored in the capture_table with the user_uid=\"admin\" and the requested user must be in the admin group.  **Args:** - file (UploadFile): File to be uploaded.  **Returns:** - CaptureRequest: Capture upload details.
         * @summary Upload File For Admin
         * @param {File} file 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileForAdmin: async (file: File, userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadFileForAdmin', 'file', file)
            const localVarPath = `/api/capture/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CaptureApi - functional programming interface
 * @export
 */
export const CaptureApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CaptureApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Favorite Generated For Capture
         * @param {string} uid 
         * @param {string} generateUid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFavoriteGeneratedForCapture(uid: string, generateUid: string, userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFavoriteGeneratedForCapture(uid, generateUid, userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a new status for this capture.  **Args:** - uid (str): Capture UID - status (CaptureStatus): Status  **Returns:** - CaptureRequest
         * @summary Add Status To Capture
         * @param {string} uid 
         * @param {CaptureStatus} status 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addStatusToCapture(uid: string, status: CaptureStatus, userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addStatusToCapture(uid, status, userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Favorite Generated For Capture
         * @param {string} uid 
         * @param {string} generateUid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFavoriteGeneratedForCapture(uid: string, generateUid: string, userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFavoriteGeneratedForCapture(uid, generateUid, userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete the given capture uid.  **Args:** - uid (str): UID of capture. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.
         * @summary Delete User Capture
         * @param {string} uid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserCapture(uid: string, userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserCapture(uid, userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all captures for the `user_uid`.  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid.
         * @summary Get All Captures From User
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCapturesFromUser(userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CaptureRequest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCapturesFromUser(userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get `uid` capture for the `user_uid`.  **Args:** - uid (str): Specific capture uid. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.
         * @summary Get Capture From User
         * @param {string} uid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCaptureFromUser(uid: string, userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaptureRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCaptureFromUser(uid, userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Favorite Generated For Capture
         * @param {string} uid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFavoriteGeneratedForCapture(uid: string, userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFavoriteGeneratedForCapture(uid, userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get `uid` capture for the `user_uid`.  **Args:** - study_name (str): Study name. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.
         * @summary Get User Captures For Study
         * @param {string} studyName 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserCapturesForStudy(studyName: string, userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CaptureRequest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserCapturesForStudy(studyName, userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use the image moderation tool to determine if the image violates any categories.  **Args:** - image (UploadFile)
         * @summary Moderate Image Content
         * @param {File} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moderateImageContent(image: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moderateImageContent(image, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the given capture uid.  **NOTE** Only the following can be changed: - prompt - prompt_kwargs - study_response - mask  **Args:** - capture (CaptureRequest): Capture. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.
         * @summary Update User Capture
         * @param {string} uid 
         * @param {CaptureRequestIn} request 
         * @param {string} [userUid] 
         * @param {File} [mask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserCapture(uid: string, request: CaptureRequestIn, userUid?: string, mask?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaptureRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserCapture(uid, request, userUid, mask, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload file and update capture table.  **Args:** - file (UploadFile): File to be uploaded.  **Returns:** - CaptureRequest: Capture upload details.
         * @summary Upload File
         * @param {File} file 
         * @param {CaptureRequestIn} request 
         * @param {string} [userUid] 
         * @param {File} [mask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(file: File, request: CaptureRequestIn, userUid?: string, mask?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaptureRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(file, request, userUid, mask, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload a file to the S3 servers. This function can only be used by admins and will be used to store data that might be needed by other services. The data will be stored in the capture_table with the user_uid=\"admin\" and the requested user must be in the admin group.  **Args:** - file (UploadFile): File to be uploaded.  **Returns:** - CaptureRequest: Capture upload details.
         * @summary Upload File For Admin
         * @param {File} file 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFileForAdmin(file: File, userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaptureRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFileForAdmin(file, userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CaptureApi - factory interface
 * @export
 */
export const CaptureApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CaptureApiFp(configuration)
    return {
        /**
         * 
         * @summary Add Favorite Generated For Capture
         * @param {string} uid 
         * @param {string} generateUid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFavoriteGeneratedForCapture(uid: string, generateUid: string, userUid?: string, options?: any): AxiosPromise<any> {
            return localVarFp.addFavoriteGeneratedForCapture(uid, generateUid, userUid, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a new status for this capture.  **Args:** - uid (str): Capture UID - status (CaptureStatus): Status  **Returns:** - CaptureRequest
         * @summary Add Status To Capture
         * @param {string} uid 
         * @param {CaptureStatus} status 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStatusToCapture(uid: string, status: CaptureStatus, userUid?: string, options?: any): AxiosPromise<any> {
            return localVarFp.addStatusToCapture(uid, status, userUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Favorite Generated For Capture
         * @param {string} uid 
         * @param {string} generateUid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFavoriteGeneratedForCapture(uid: string, generateUid: string, userUid?: string, options?: any): AxiosPromise<any> {
            return localVarFp.deleteFavoriteGeneratedForCapture(uid, generateUid, userUid, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the given capture uid.  **Args:** - uid (str): UID of capture. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.
         * @summary Delete User Capture
         * @param {string} uid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserCapture(uid: string, userUid?: string, options?: any): AxiosPromise<any> {
            return localVarFp.deleteUserCapture(uid, userUid, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all captures for the `user_uid`.  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid.
         * @summary Get All Captures From User
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCapturesFromUser(userUid?: string, options?: any): AxiosPromise<Array<CaptureRequest>> {
            return localVarFp.getAllCapturesFromUser(userUid, options).then((request) => request(axios, basePath));
        },
        /**
         * Get `uid` capture for the `user_uid`.  **Args:** - uid (str): Specific capture uid. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.
         * @summary Get Capture From User
         * @param {string} uid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCaptureFromUser(uid: string, userUid?: string, options?: any): AxiosPromise<CaptureRequest> {
            return localVarFp.getCaptureFromUser(uid, userUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Favorite Generated For Capture
         * @param {string} uid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoriteGeneratedForCapture(uid: string, userUid?: string, options?: any): AxiosPromise<any> {
            return localVarFp.getFavoriteGeneratedForCapture(uid, userUid, options).then((request) => request(axios, basePath));
        },
        /**
         * Get `uid` capture for the `user_uid`.  **Args:** - study_name (str): Study name. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.
         * @summary Get User Captures For Study
         * @param {string} studyName 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCapturesForStudy(studyName: string, userUid?: string, options?: any): AxiosPromise<Array<CaptureRequest>> {
            return localVarFp.getUserCapturesForStudy(studyName, userUid, options).then((request) => request(axios, basePath));
        },
        /**
         * Use the image moderation tool to determine if the image violates any categories.  **Args:** - image (UploadFile)
         * @summary Moderate Image Content
         * @param {File} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moderateImageContent(image: File, options?: any): AxiosPromise<any> {
            return localVarFp.moderateImageContent(image, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the given capture uid.  **NOTE** Only the following can be changed: - prompt - prompt_kwargs - study_response - mask  **Args:** - capture (CaptureRequest): Capture. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.
         * @summary Update User Capture
         * @param {string} uid 
         * @param {CaptureRequestIn} request 
         * @param {string} [userUid] 
         * @param {File} [mask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserCapture(uid: string, request: CaptureRequestIn, userUid?: string, mask?: File, options?: any): AxiosPromise<CaptureRequest> {
            return localVarFp.updateUserCapture(uid, request, userUid, mask, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload file and update capture table.  **Args:** - file (UploadFile): File to be uploaded.  **Returns:** - CaptureRequest: Capture upload details.
         * @summary Upload File
         * @param {File} file 
         * @param {CaptureRequestIn} request 
         * @param {string} [userUid] 
         * @param {File} [mask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(file: File, request: CaptureRequestIn, userUid?: string, mask?: File, options?: any): AxiosPromise<CaptureRequest> {
            return localVarFp.uploadFile(file, request, userUid, mask, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload a file to the S3 servers. This function can only be used by admins and will be used to store data that might be needed by other services. The data will be stored in the capture_table with the user_uid=\"admin\" and the requested user must be in the admin group.  **Args:** - file (UploadFile): File to be uploaded.  **Returns:** - CaptureRequest: Capture upload details.
         * @summary Upload File For Admin
         * @param {File} file 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileForAdmin(file: File, userUid?: string, options?: any): AxiosPromise<CaptureRequest> {
            return localVarFp.uploadFileForAdmin(file, userUid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CaptureApi - object-oriented interface
 * @export
 * @class CaptureApi
 * @extends {BaseAPI}
 */
export class CaptureApi extends BaseAPI {
    /**
     * 
     * @summary Add Favorite Generated For Capture
     * @param {string} uid 
     * @param {string} generateUid 
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaptureApi
     */
    public addFavoriteGeneratedForCapture(uid: string, generateUid: string, userUid?: string, options?: AxiosRequestConfig) {
        return CaptureApiFp(this.configuration).addFavoriteGeneratedForCapture(uid, generateUid, userUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a new status for this capture.  **Args:** - uid (str): Capture UID - status (CaptureStatus): Status  **Returns:** - CaptureRequest
     * @summary Add Status To Capture
     * @param {string} uid 
     * @param {CaptureStatus} status 
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaptureApi
     */
    public addStatusToCapture(uid: string, status: CaptureStatus, userUid?: string, options?: AxiosRequestConfig) {
        return CaptureApiFp(this.configuration).addStatusToCapture(uid, status, userUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Favorite Generated For Capture
     * @param {string} uid 
     * @param {string} generateUid 
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaptureApi
     */
    public deleteFavoriteGeneratedForCapture(uid: string, generateUid: string, userUid?: string, options?: AxiosRequestConfig) {
        return CaptureApiFp(this.configuration).deleteFavoriteGeneratedForCapture(uid, generateUid, userUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the given capture uid.  **Args:** - uid (str): UID of capture. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.
     * @summary Delete User Capture
     * @param {string} uid 
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaptureApi
     */
    public deleteUserCapture(uid: string, userUid?: string, options?: AxiosRequestConfig) {
        return CaptureApiFp(this.configuration).deleteUserCapture(uid, userUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all captures for the `user_uid`.  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid.
     * @summary Get All Captures From User
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaptureApi
     */
    public getAllCapturesFromUser(userUid?: string, options?: AxiosRequestConfig) {
        return CaptureApiFp(this.configuration).getAllCapturesFromUser(userUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get `uid` capture for the `user_uid`.  **Args:** - uid (str): Specific capture uid. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.
     * @summary Get Capture From User
     * @param {string} uid 
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaptureApi
     */
    public getCaptureFromUser(uid: string, userUid?: string, options?: AxiosRequestConfig) {
        return CaptureApiFp(this.configuration).getCaptureFromUser(uid, userUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Favorite Generated For Capture
     * @param {string} uid 
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaptureApi
     */
    public getFavoriteGeneratedForCapture(uid: string, userUid?: string, options?: AxiosRequestConfig) {
        return CaptureApiFp(this.configuration).getFavoriteGeneratedForCapture(uid, userUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get `uid` capture for the `user_uid`.  **Args:** - study_name (str): Study name. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.
     * @summary Get User Captures For Study
     * @param {string} studyName 
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaptureApi
     */
    public getUserCapturesForStudy(studyName: string, userUid?: string, options?: AxiosRequestConfig) {
        return CaptureApiFp(this.configuration).getUserCapturesForStudy(studyName, userUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use the image moderation tool to determine if the image violates any categories.  **Args:** - image (UploadFile)
     * @summary Moderate Image Content
     * @param {File} image 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaptureApi
     */
    public moderateImageContent(image: File, options?: AxiosRequestConfig) {
        return CaptureApiFp(this.configuration).moderateImageContent(image, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the given capture uid.  **NOTE** Only the following can be changed: - prompt - prompt_kwargs - study_response - mask  **Args:** - capture (CaptureRequest): Capture. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.
     * @summary Update User Capture
     * @param {string} uid 
     * @param {CaptureRequestIn} request 
     * @param {string} [userUid] 
     * @param {File} [mask] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaptureApi
     */
    public updateUserCapture(uid: string, request: CaptureRequestIn, userUid?: string, mask?: File, options?: AxiosRequestConfig) {
        return CaptureApiFp(this.configuration).updateUserCapture(uid, request, userUid, mask, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload file and update capture table.  **Args:** - file (UploadFile): File to be uploaded.  **Returns:** - CaptureRequest: Capture upload details.
     * @summary Upload File
     * @param {File} file 
     * @param {CaptureRequestIn} request 
     * @param {string} [userUid] 
     * @param {File} [mask] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaptureApi
     */
    public uploadFile(file: File, request: CaptureRequestIn, userUid?: string, mask?: File, options?: AxiosRequestConfig) {
        return CaptureApiFp(this.configuration).uploadFile(file, request, userUid, mask, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload a file to the S3 servers. This function can only be used by admins and will be used to store data that might be needed by other services. The data will be stored in the capture_table with the user_uid=\"admin\" and the requested user must be in the admin group.  **Args:** - file (UploadFile): File to be uploaded.  **Returns:** - CaptureRequest: Capture upload details.
     * @summary Upload File For Admin
     * @param {File} file 
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaptureApi
     */
    public uploadFileForAdmin(file: File, userUid?: string, options?: AxiosRequestConfig) {
        return CaptureApiFp(this.configuration).uploadFileForAdmin(file, userUid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FeedbackApi - axios parameter creator
 * @export
 */
export const FeedbackApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Acknowledge given feedback indicating that the user has submitted a new redesign or has chosen to finish their updates.  **Args:** - generate_uid (str): Generate uid. - acknowledged (bool): Optional. Default True. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - Feedback: Schema.
         * @summary Acknowledge Given Feedback
         * @param {string} generateUid 
         * @param {Acknowledged} [acknowledged] 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acknowledgeGivenFeedback: async (generateUid: string, acknowledged?: Acknowledged, userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generateUid' is not null or undefined
            assertParamExists('acknowledgeGivenFeedback', 'generateUid', generateUid)
            const localVarPath = `/api/feedback/acknowledge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (generateUid !== undefined) {
                localVarQueryParameter['generate_uid'] = generateUid;
            }

            if (acknowledged !== undefined) {
                localVarQueryParameter['acknowledged'] = acknowledged;
            }

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a feedback entry in the table.  **Args:** - request (FeedbackInitial, optional): Provide user_uid, image_uid, parent_uid, receiver_uid, and receiver_capture_uid.  **Returns:** - Feedback
         * @summary Create Feedback Entry
         * @param {FeedbackInitial} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeedbackEntry: async (request: FeedbackInitial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('createFeedbackEntry', 'request', request)
            const localVarPath = `/api/feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (request !== undefined) { 
                localVarFormParams.set('request', request as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get feedback entry for the params requested.  **Args:** - request (FeedbackInitial, optional): Provide user_uid, image_uid, parent_uid, receiver_uid, and receiver_capture_uid.  **Returns:** - list[Feedback]
         * @summary Get Feedback
         * @param {string} [userUid] 
         * @param {string} [generateUid] 
         * @param {string} [parentCaptureUid] 
         * @param {string} [reviewerUserUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeedback: async (userUid?: string, generateUid?: string, parentCaptureUid?: string, reviewerUserUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }

            if (generateUid !== undefined) {
                localVarQueryParameter['generate_uid'] = generateUid;
            }

            if (parentCaptureUid !== undefined) {
                localVarQueryParameter['parent_capture_uid'] = parentCaptureUid;
            }

            if (reviewerUserUid !== undefined) {
                localVarQueryParameter['reviewer_user_uid'] = reviewerUserUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all items that have received a comment and rating so the user can modify the designs based on the feedback from the reviewer.  **Args:** - user_uid (str): User id else use JWT to extract uid.  **Returns:** - Feedback: Schema.
         * @summary Get Items To Acknowledge
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemsToAcknowledge: async (userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/feedback/acknowledge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all items the reviewer should look at to provide ratings and comment.  **Args:** - reviewer_user_uid (str): User UID of the reviewer.  **Returns:** - Feedback: Schema.
         * @summary Get Items To Comment
         * @param {string} [reviewerUserUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemsToComment: async (reviewerUserUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/feedback/provide_comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reviewerUserUid !== undefined) {
                localVarQueryParameter['reviewer_user_uid'] = reviewerUserUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the user_completed or reviewer_completed for the given generate_uid.  **Notes:** - Only works when providing one of the completed fields but not both! - Only works if the generate_uid exists!  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid. - generate_uid (str, optional): Generate uid. - user_completed (bool, optional): Provide this or reviewer_completed. - reviewer_completed (bool, optional): Provide this or user_completed.  **Returns:** - dict: Updated item.
         * @summary Update Completed Status
         * @param {string} [userUid] 
         * @param {string} [generateUid] 
         * @param {boolean} [userCompleted] 
         * @param {boolean} [reviewerCompleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompletedStatus: async (userUid?: string, generateUid?: string, userCompleted?: boolean, reviewerCompleted?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/feedback/complete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }

            if (generateUid !== undefined) {
                localVarQueryParameter['generate_uid'] = generateUid;
            }

            if (userCompleted !== undefined) {
                localVarQueryParameter['user_completed'] = userCompleted;
            }

            if (reviewerCompleted !== undefined) {
                localVarQueryParameter['reviewer_completed'] = reviewerCompleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the feedback entry with comments and rating.  **Args:** - feedback (FeedbackRating): Contains rating and comment.  **Returns:** - dict: Updated item.
         * @summary Update With Comments
         * @param {FeedbackRating} feedback 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithComments: async (feedback: FeedbackRating, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'feedback' is not null or undefined
            assertParamExists('updateWithComments', 'feedback', feedback)
            const localVarPath = `/api/feedback/provide_comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (feedback !== undefined) { 
                localVarFormParams.set('feedback', feedback as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedbackApi - functional programming interface
 * @export
 */
export const FeedbackApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeedbackApiAxiosParamCreator(configuration)
    return {
        /**
         * Acknowledge given feedback indicating that the user has submitted a new redesign or has chosen to finish their updates.  **Args:** - generate_uid (str): Generate uid. - acknowledged (bool): Optional. Default True. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - Feedback: Schema.
         * @summary Acknowledge Given Feedback
         * @param {string} generateUid 
         * @param {Acknowledged} [acknowledged] 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acknowledgeGivenFeedback(generateUid: string, acknowledged?: Acknowledged, userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acknowledgeGivenFeedback(generateUid, acknowledged, userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a feedback entry in the table.  **Args:** - request (FeedbackInitial, optional): Provide user_uid, image_uid, parent_uid, receiver_uid, and receiver_capture_uid.  **Returns:** - Feedback
         * @summary Create Feedback Entry
         * @param {FeedbackInitial} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFeedbackEntry(request: FeedbackInitial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feedback>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFeedbackEntry(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get feedback entry for the params requested.  **Args:** - request (FeedbackInitial, optional): Provide user_uid, image_uid, parent_uid, receiver_uid, and receiver_capture_uid.  **Returns:** - list[Feedback]
         * @summary Get Feedback
         * @param {string} [userUid] 
         * @param {string} [generateUid] 
         * @param {string} [parentCaptureUid] 
         * @param {string} [reviewerUserUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeedback(userUid?: string, generateUid?: string, parentCaptureUid?: string, reviewerUserUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Feedback>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeedback(userUid, generateUid, parentCaptureUid, reviewerUserUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all items that have received a comment and rating so the user can modify the designs based on the feedback from the reviewer.  **Args:** - user_uid (str): User id else use JWT to extract uid.  **Returns:** - Feedback: Schema.
         * @summary Get Items To Acknowledge
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getItemsToAcknowledge(userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Feedback>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getItemsToAcknowledge(userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all items the reviewer should look at to provide ratings and comment.  **Args:** - reviewer_user_uid (str): User UID of the reviewer.  **Returns:** - Feedback: Schema.
         * @summary Get Items To Comment
         * @param {string} [reviewerUserUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getItemsToComment(reviewerUserUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Feedback>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getItemsToComment(reviewerUserUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the user_completed or reviewer_completed for the given generate_uid.  **Notes:** - Only works when providing one of the completed fields but not both! - Only works if the generate_uid exists!  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid. - generate_uid (str, optional): Generate uid. - user_completed (bool, optional): Provide this or reviewer_completed. - reviewer_completed (bool, optional): Provide this or user_completed.  **Returns:** - dict: Updated item.
         * @summary Update Completed Status
         * @param {string} [userUid] 
         * @param {string} [generateUid] 
         * @param {boolean} [userCompleted] 
         * @param {boolean} [reviewerCompleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompletedStatus(userUid?: string, generateUid?: string, userCompleted?: boolean, reviewerCompleted?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompletedStatus(userUid, generateUid, userCompleted, reviewerCompleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the feedback entry with comments and rating.  **Args:** - feedback (FeedbackRating): Contains rating and comment.  **Returns:** - dict: Updated item.
         * @summary Update With Comments
         * @param {FeedbackRating} feedback 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithComments(feedback: FeedbackRating, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithComments(feedback, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeedbackApi - factory interface
 * @export
 */
export const FeedbackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeedbackApiFp(configuration)
    return {
        /**
         * Acknowledge given feedback indicating that the user has submitted a new redesign or has chosen to finish their updates.  **Args:** - generate_uid (str): Generate uid. - acknowledged (bool): Optional. Default True. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - Feedback: Schema.
         * @summary Acknowledge Given Feedback
         * @param {string} generateUid 
         * @param {Acknowledged} [acknowledged] 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acknowledgeGivenFeedback(generateUid: string, acknowledged?: Acknowledged, userUid?: string, options?: any): AxiosPromise<object> {
            return localVarFp.acknowledgeGivenFeedback(generateUid, acknowledged, userUid, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a feedback entry in the table.  **Args:** - request (FeedbackInitial, optional): Provide user_uid, image_uid, parent_uid, receiver_uid, and receiver_capture_uid.  **Returns:** - Feedback
         * @summary Create Feedback Entry
         * @param {FeedbackInitial} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeedbackEntry(request: FeedbackInitial, options?: any): AxiosPromise<Feedback> {
            return localVarFp.createFeedbackEntry(request, options).then((request) => request(axios, basePath));
        },
        /**
         * Get feedback entry for the params requested.  **Args:** - request (FeedbackInitial, optional): Provide user_uid, image_uid, parent_uid, receiver_uid, and receiver_capture_uid.  **Returns:** - list[Feedback]
         * @summary Get Feedback
         * @param {string} [userUid] 
         * @param {string} [generateUid] 
         * @param {string} [parentCaptureUid] 
         * @param {string} [reviewerUserUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeedback(userUid?: string, generateUid?: string, parentCaptureUid?: string, reviewerUserUid?: string, options?: any): AxiosPromise<Array<Feedback>> {
            return localVarFp.getFeedback(userUid, generateUid, parentCaptureUid, reviewerUserUid, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all items that have received a comment and rating so the user can modify the designs based on the feedback from the reviewer.  **Args:** - user_uid (str): User id else use JWT to extract uid.  **Returns:** - Feedback: Schema.
         * @summary Get Items To Acknowledge
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemsToAcknowledge(userUid?: string, options?: any): AxiosPromise<Array<Feedback>> {
            return localVarFp.getItemsToAcknowledge(userUid, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all items the reviewer should look at to provide ratings and comment.  **Args:** - reviewer_user_uid (str): User UID of the reviewer.  **Returns:** - Feedback: Schema.
         * @summary Get Items To Comment
         * @param {string} [reviewerUserUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemsToComment(reviewerUserUid?: string, options?: any): AxiosPromise<Array<Feedback>> {
            return localVarFp.getItemsToComment(reviewerUserUid, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the user_completed or reviewer_completed for the given generate_uid.  **Notes:** - Only works when providing one of the completed fields but not both! - Only works if the generate_uid exists!  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid. - generate_uid (str, optional): Generate uid. - user_completed (bool, optional): Provide this or reviewer_completed. - reviewer_completed (bool, optional): Provide this or user_completed.  **Returns:** - dict: Updated item.
         * @summary Update Completed Status
         * @param {string} [userUid] 
         * @param {string} [generateUid] 
         * @param {boolean} [userCompleted] 
         * @param {boolean} [reviewerCompleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompletedStatus(userUid?: string, generateUid?: string, userCompleted?: boolean, reviewerCompleted?: boolean, options?: any): AxiosPromise<object> {
            return localVarFp.updateCompletedStatus(userUid, generateUid, userCompleted, reviewerCompleted, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the feedback entry with comments and rating.  **Args:** - feedback (FeedbackRating): Contains rating and comment.  **Returns:** - dict: Updated item.
         * @summary Update With Comments
         * @param {FeedbackRating} feedback 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithComments(feedback: FeedbackRating, options?: any): AxiosPromise<object> {
            return localVarFp.updateWithComments(feedback, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeedbackApi - object-oriented interface
 * @export
 * @class FeedbackApi
 * @extends {BaseAPI}
 */
export class FeedbackApi extends BaseAPI {
    /**
     * Acknowledge given feedback indicating that the user has submitted a new redesign or has chosen to finish their updates.  **Args:** - generate_uid (str): Generate uid. - acknowledged (bool): Optional. Default True. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - Feedback: Schema.
     * @summary Acknowledge Given Feedback
     * @param {string} generateUid 
     * @param {Acknowledged} [acknowledged] 
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public acknowledgeGivenFeedback(generateUid: string, acknowledged?: Acknowledged, userUid?: string, options?: AxiosRequestConfig) {
        return FeedbackApiFp(this.configuration).acknowledgeGivenFeedback(generateUid, acknowledged, userUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a feedback entry in the table.  **Args:** - request (FeedbackInitial, optional): Provide user_uid, image_uid, parent_uid, receiver_uid, and receiver_capture_uid.  **Returns:** - Feedback
     * @summary Create Feedback Entry
     * @param {FeedbackInitial} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public createFeedbackEntry(request: FeedbackInitial, options?: AxiosRequestConfig) {
        return FeedbackApiFp(this.configuration).createFeedbackEntry(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get feedback entry for the params requested.  **Args:** - request (FeedbackInitial, optional): Provide user_uid, image_uid, parent_uid, receiver_uid, and receiver_capture_uid.  **Returns:** - list[Feedback]
     * @summary Get Feedback
     * @param {string} [userUid] 
     * @param {string} [generateUid] 
     * @param {string} [parentCaptureUid] 
     * @param {string} [reviewerUserUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public getFeedback(userUid?: string, generateUid?: string, parentCaptureUid?: string, reviewerUserUid?: string, options?: AxiosRequestConfig) {
        return FeedbackApiFp(this.configuration).getFeedback(userUid, generateUid, parentCaptureUid, reviewerUserUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all items that have received a comment and rating so the user can modify the designs based on the feedback from the reviewer.  **Args:** - user_uid (str): User id else use JWT to extract uid.  **Returns:** - Feedback: Schema.
     * @summary Get Items To Acknowledge
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public getItemsToAcknowledge(userUid?: string, options?: AxiosRequestConfig) {
        return FeedbackApiFp(this.configuration).getItemsToAcknowledge(userUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all items the reviewer should look at to provide ratings and comment.  **Args:** - reviewer_user_uid (str): User UID of the reviewer.  **Returns:** - Feedback: Schema.
     * @summary Get Items To Comment
     * @param {string} [reviewerUserUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public getItemsToComment(reviewerUserUid?: string, options?: AxiosRequestConfig) {
        return FeedbackApiFp(this.configuration).getItemsToComment(reviewerUserUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the user_completed or reviewer_completed for the given generate_uid.  **Notes:** - Only works when providing one of the completed fields but not both! - Only works if the generate_uid exists!  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid. - generate_uid (str, optional): Generate uid. - user_completed (bool, optional): Provide this or reviewer_completed. - reviewer_completed (bool, optional): Provide this or user_completed.  **Returns:** - dict: Updated item.
     * @summary Update Completed Status
     * @param {string} [userUid] 
     * @param {string} [generateUid] 
     * @param {boolean} [userCompleted] 
     * @param {boolean} [reviewerCompleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public updateCompletedStatus(userUid?: string, generateUid?: string, userCompleted?: boolean, reviewerCompleted?: boolean, options?: AxiosRequestConfig) {
        return FeedbackApiFp(this.configuration).updateCompletedStatus(userUid, generateUid, userCompleted, reviewerCompleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the feedback entry with comments and rating.  **Args:** - feedback (FeedbackRating): Contains rating and comment.  **Returns:** - dict: Updated item.
     * @summary Update With Comments
     * @param {FeedbackRating} feedback 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public updateWithComments(feedback: FeedbackRating, options?: AxiosRequestConfig) {
        return FeedbackApiFp(this.configuration).updateWithComments(feedback, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GenerateApi - axios parameter creator
 * @export
 */
export const GenerateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete the specific generated media.  **Args:** - uid (str): UID. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - dict: Response from table.
         * @summary Delete Generated Media
         * @param {string} uid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGeneratedMedia: async (uid: string, userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('deleteGeneratedMedia', 'uid', uid)
            const localVarPath = `/api/generate/uid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate a new image based on parameters provided.  **NOTES**: - OPENAI in-paints the `image` where the `mask` contains transparent pixels;   these pixels MUST be transparent (i.e. alpha=0) but color doesn\'t matter.  **Returns:** - dict: GenerateRequest details.
         * @summary Generate Media
         * @param {GenerateRequestIn} request 
         * @param {string} [receiverUserUid] 
         * @param {string} [userUid] 
         * @param {File} [image] 
         * @param {File} [mask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateMedia: async (request: GenerateRequestIn, receiverUserUid?: string, userUid?: string, image?: File, mask?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('generateMedia', 'request', request)
            const localVarPath = `/api/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (receiverUserUid !== undefined) {
                localVarQueryParameter['receiver_user_uid'] = receiverUserUid;
            }

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


            if (request !== undefined) { 
                localVarFormParams.append('request', new Blob([JSON.stringify(request)], { type: "application/json", }));
            }
    
            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
            if (mask !== undefined) { 
                localVarFormParams.append('mask', mask as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all the generated media for a specific capture.  **Args:** - capture_uid (str): Capture UID. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - list: List of GenerateRequest requests
         * @summary Get All Generated Media For Capture
         * @param {string} captureUid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllGeneratedMediaForCapture: async (captureUid: string, userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'captureUid' is not null or undefined
            assertParamExists('getAllGeneratedMediaForCapture', 'captureUid', captureUid)
            const localVarPath = `/api/generate/capture`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (captureUid !== undefined) {
                localVarQueryParameter['capture_uid'] = captureUid;
            }

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all the media for the given user.  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - list: List of GenerateRequest requests.
         * @summary Get All Generated Media For User
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllGeneratedMediaForUser: async (userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the generated media using uid.  **Args:** - uid (str): UID. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - dict: GenerateRequest details.
         * @summary Get Single Generated Media
         * @param {string} uid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleGeneratedMedia: async (uid: string, userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('getSingleGeneratedMedia', 'uid', uid)
            const localVarPath = `/api/generate/uid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the status of the generated media using uid.  **Args:** - uid (str): UID.  **Returns:** - dict: GenerateRequest details.
         * @summary Get Status
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('getStatus', 'uid', uid)
            const localVarPath = `/api/generate/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GenerateApi - functional programming interface
 * @export
 */
export const GenerateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GenerateApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete the specific generated media.  **Args:** - uid (str): UID. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - dict: Response from table.
         * @summary Delete Generated Media
         * @param {string} uid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGeneratedMedia(uid: string, userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGeneratedMedia(uid, userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generate a new image based on parameters provided.  **NOTES**: - OPENAI in-paints the `image` where the `mask` contains transparent pixels;   these pixels MUST be transparent (i.e. alpha=0) but color doesn\'t matter.  **Returns:** - dict: GenerateRequest details.
         * @summary Generate Media
         * @param {GenerateRequestIn} request 
         * @param {string} [receiverUserUid] 
         * @param {string} [userUid] 
         * @param {File} [image] 
         * @param {File} [mask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateMedia(request: GenerateRequestIn, receiverUserUid?: string, userUid?: string, image?: File, mask?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateMedia(request, receiverUserUid, userUid, image, mask, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all the generated media for a specific capture.  **Args:** - capture_uid (str): Capture UID. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - list: List of GenerateRequest requests
         * @summary Get All Generated Media For Capture
         * @param {string} captureUid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllGeneratedMediaForCapture(captureUid: string, userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllGeneratedMediaForCapture(captureUid, userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all the media for the given user.  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - list: List of GenerateRequest requests.
         * @summary Get All Generated Media For User
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllGeneratedMediaForUser(userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseGetAllGeneratedMediaForUserApiGenerateGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllGeneratedMediaForUser(userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the generated media using uid.  **Args:** - uid (str): UID. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - dict: GenerateRequest details.
         * @summary Get Single Generated Media
         * @param {string} uid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSingleGeneratedMedia(uid: string, userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSingleGeneratedMedia(uid, userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the status of the generated media using uid.  **Args:** - uid (str): UID.  **Returns:** - dict: GenerateRequest details.
         * @summary Get Status
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatus(uid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Status>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatus(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GenerateApi - factory interface
 * @export
 */
export const GenerateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GenerateApiFp(configuration)
    return {
        /**
         * Delete the specific generated media.  **Args:** - uid (str): UID. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - dict: Response from table.
         * @summary Delete Generated Media
         * @param {string} uid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGeneratedMedia(uid: string, userUid?: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteGeneratedMedia(uid, userUid, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate a new image based on parameters provided.  **NOTES**: - OPENAI in-paints the `image` where the `mask` contains transparent pixels;   these pixels MUST be transparent (i.e. alpha=0) but color doesn\'t matter.  **Returns:** - dict: GenerateRequest details.
         * @summary Generate Media
         * @param {GenerateRequestIn} request 
         * @param {string} [receiverUserUid] 
         * @param {string} [userUid] 
         * @param {File} [image] 
         * @param {File} [mask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateMedia(request: GenerateRequestIn, receiverUserUid?: string, userUid?: string, image?: File, mask?: File, options?: any): AxiosPromise<object> {
            return localVarFp.generateMedia(request, receiverUserUid, userUid, image, mask, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all the generated media for a specific capture.  **Args:** - capture_uid (str): Capture UID. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - list: List of GenerateRequest requests
         * @summary Get All Generated Media For Capture
         * @param {string} captureUid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllGeneratedMediaForCapture(captureUid: string, userUid?: string, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.getAllGeneratedMediaForCapture(captureUid, userUid, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all the media for the given user.  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - list: List of GenerateRequest requests.
         * @summary Get All Generated Media For User
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllGeneratedMediaForUser(userUid?: string, options?: any): AxiosPromise<ResponseGetAllGeneratedMediaForUserApiGenerateGet> {
            return localVarFp.getAllGeneratedMediaForUser(userUid, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the generated media using uid.  **Args:** - uid (str): UID. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - dict: GenerateRequest details.
         * @summary Get Single Generated Media
         * @param {string} uid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleGeneratedMedia(uid: string, userUid?: string, options?: any): AxiosPromise<GenerateRequest> {
            return localVarFp.getSingleGeneratedMedia(uid, userUid, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the status of the generated media using uid.  **Args:** - uid (str): UID.  **Returns:** - dict: GenerateRequest details.
         * @summary Get Status
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus(uid: string, options?: any): AxiosPromise<Status> {
            return localVarFp.getStatus(uid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GenerateApi - object-oriented interface
 * @export
 * @class GenerateApi
 * @extends {BaseAPI}
 */
export class GenerateApi extends BaseAPI {
    /**
     * Delete the specific generated media.  **Args:** - uid (str): UID. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - dict: Response from table.
     * @summary Delete Generated Media
     * @param {string} uid 
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenerateApi
     */
    public deleteGeneratedMedia(uid: string, userUid?: string, options?: AxiosRequestConfig) {
        return GenerateApiFp(this.configuration).deleteGeneratedMedia(uid, userUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate a new image based on parameters provided.  **NOTES**: - OPENAI in-paints the `image` where the `mask` contains transparent pixels;   these pixels MUST be transparent (i.e. alpha=0) but color doesn\'t matter.  **Returns:** - dict: GenerateRequest details.
     * @summary Generate Media
     * @param {GenerateRequestIn} request 
     * @param {string} [receiverUserUid] 
     * @param {string} [userUid] 
     * @param {File} [image] 
     * @param {File} [mask] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenerateApi
     */
    public generateMedia(request: GenerateRequestIn, receiverUserUid?: string, userUid?: string, image?: File, mask?: File, options?: AxiosRequestConfig) {
        return GenerateApiFp(this.configuration).generateMedia(request, receiverUserUid, userUid, image, mask, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all the generated media for a specific capture.  **Args:** - capture_uid (str): Capture UID. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - list: List of GenerateRequest requests
     * @summary Get All Generated Media For Capture
     * @param {string} captureUid 
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenerateApi
     */
    public getAllGeneratedMediaForCapture(captureUid: string, userUid?: string, options?: AxiosRequestConfig) {
        return GenerateApiFp(this.configuration).getAllGeneratedMediaForCapture(captureUid, userUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all the media for the given user.  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - list: List of GenerateRequest requests.
     * @summary Get All Generated Media For User
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenerateApi
     */
    public getAllGeneratedMediaForUser(userUid?: string, options?: AxiosRequestConfig) {
        return GenerateApiFp(this.configuration).getAllGeneratedMediaForUser(userUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the generated media using uid.  **Args:** - uid (str): UID. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - dict: GenerateRequest details.
     * @summary Get Single Generated Media
     * @param {string} uid 
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenerateApi
     */
    public getSingleGeneratedMedia(uid: string, userUid?: string, options?: AxiosRequestConfig) {
        return GenerateApiFp(this.configuration).getSingleGeneratedMedia(uid, userUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the status of the generated media using uid.  **Args:** - uid (str): UID.  **Returns:** - dict: GenerateRequest details.
     * @summary Get Status
     * @param {string} uid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenerateApi
     */
    public getStatus(uid: string, options?: AxiosRequestConfig) {
        return GenerateApiFp(this.configuration).getStatus(uid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LogApi - axios parameter creator
 * @export
 */
export const LogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new log entry.  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - LogRequest: Schema.
         * @summary Create Log
         * @param {LogRequestBase} logRequest 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLog: async (logRequest: LogRequestBase, userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'logRequest' is not null or undefined
            assertParamExists('createLog', 'logRequest', logRequest)
            const localVarPath = `/api/log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


            if (logRequest !== undefined) { 
                localVarFormParams.set('log_request', logRequest as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new log entry.  **Args:** - file (UploadFile): File to be uploaded.  **Returns:** - LogRequest: Schema.
         * @summary Create Log With File
         * @param {File} file 
         * @param {LogRequestBase} logRequest 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLogWithFile: async (file: File, logRequest: LogRequestBase, userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('createLogWithFile', 'file', file)
            // verify required parameter 'logRequest' is not null or undefined
            assertParamExists('createLogWithFile', 'logRequest', logRequest)
            const localVarPath = `/api/log/upload_file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (logRequest !== undefined) { 
                localVarFormParams.append('log_request', new Blob([JSON.stringify(logRequest)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new log entry.  **Args:** - file (UploadFile): File to be uploaded.  **Returns:** - LogRequest: Schema.
         * @summary Create Log With Photo
         * @param {File} file 
         * @param {LogRequestBase} logRequest 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLogWithPhoto: async (file: File, logRequest: LogRequestBase, userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('createLogWithPhoto', 'file', file)
            // verify required parameter 'logRequest' is not null or undefined
            assertParamExists('createLogWithPhoto', 'logRequest', logRequest)
            const localVarPath = `/api/log/upload_photo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (logRequest !== undefined) { 
                localVarFormParams.append('log_request', new Blob([JSON.stringify(logRequest)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get File
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFile: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getFile', 'key', key)
            const localVarPath = `/api/log/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get log from uid.  **Args:** - uid (str): UID.  **Returns:** - LogRequest: Schema.
         * @summary Get Log
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLog: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('getLog', 'uid', uid)
            const localVarPath = `/api/log/uid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogApi - functional programming interface
 * @export
 */
export const LogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new log entry.  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - LogRequest: Schema.
         * @summary Create Log
         * @param {LogRequestBase} logRequest 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLog(logRequest: LogRequestBase, userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLog(logRequest, userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new log entry.  **Args:** - file (UploadFile): File to be uploaded.  **Returns:** - LogRequest: Schema.
         * @summary Create Log With File
         * @param {File} file 
         * @param {LogRequestBase} logRequest 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLogWithFile(file: File, logRequest: LogRequestBase, userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLogWithFile(file, logRequest, userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new log entry.  **Args:** - file (UploadFile): File to be uploaded.  **Returns:** - LogRequest: Schema.
         * @summary Create Log With Photo
         * @param {File} file 
         * @param {LogRequestBase} logRequest 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLogWithPhoto(file: File, logRequest: LogRequestBase, userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLogWithPhoto(file, logRequest, userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get File
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFile(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFile(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get log from uid.  **Args:** - uid (str): UID.  **Returns:** - LogRequest: Schema.
         * @summary Get Log
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLog(uid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLog(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LogApi - factory interface
 * @export
 */
export const LogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogApiFp(configuration)
    return {
        /**
         * Create a new log entry.  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - LogRequest: Schema.
         * @summary Create Log
         * @param {LogRequestBase} logRequest 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLog(logRequest: LogRequestBase, userUid?: string, options?: any): AxiosPromise<LogRequest> {
            return localVarFp.createLog(logRequest, userUid, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new log entry.  **Args:** - file (UploadFile): File to be uploaded.  **Returns:** - LogRequest: Schema.
         * @summary Create Log With File
         * @param {File} file 
         * @param {LogRequestBase} logRequest 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLogWithFile(file: File, logRequest: LogRequestBase, userUid?: string, options?: any): AxiosPromise<LogRequest> {
            return localVarFp.createLogWithFile(file, logRequest, userUid, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new log entry.  **Args:** - file (UploadFile): File to be uploaded.  **Returns:** - LogRequest: Schema.
         * @summary Create Log With Photo
         * @param {File} file 
         * @param {LogRequestBase} logRequest 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLogWithPhoto(file: File, logRequest: LogRequestBase, userUid?: string, options?: any): AxiosPromise<LogRequest> {
            return localVarFp.createLogWithPhoto(file, logRequest, userUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get File
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFile(key: string, options?: any): AxiosPromise<any> {
            return localVarFp.getFile(key, options).then((request) => request(axios, basePath));
        },
        /**
         * Get log from uid.  **Args:** - uid (str): UID.  **Returns:** - LogRequest: Schema.
         * @summary Get Log
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLog(uid: string, options?: any): AxiosPromise<LogRequest> {
            return localVarFp.getLog(uid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LogApi - object-oriented interface
 * @export
 * @class LogApi
 * @extends {BaseAPI}
 */
export class LogApi extends BaseAPI {
    /**
     * Create a new log entry.  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - LogRequest: Schema.
     * @summary Create Log
     * @param {LogRequestBase} logRequest 
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogApi
     */
    public createLog(logRequest: LogRequestBase, userUid?: string, options?: AxiosRequestConfig) {
        return LogApiFp(this.configuration).createLog(logRequest, userUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new log entry.  **Args:** - file (UploadFile): File to be uploaded.  **Returns:** - LogRequest: Schema.
     * @summary Create Log With File
     * @param {File} file 
     * @param {LogRequestBase} logRequest 
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogApi
     */
    public createLogWithFile(file: File, logRequest: LogRequestBase, userUid?: string, options?: AxiosRequestConfig) {
        return LogApiFp(this.configuration).createLogWithFile(file, logRequest, userUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new log entry.  **Args:** - file (UploadFile): File to be uploaded.  **Returns:** - LogRequest: Schema.
     * @summary Create Log With Photo
     * @param {File} file 
     * @param {LogRequestBase} logRequest 
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogApi
     */
    public createLogWithPhoto(file: File, logRequest: LogRequestBase, userUid?: string, options?: AxiosRequestConfig) {
        return LogApiFp(this.configuration).createLogWithPhoto(file, logRequest, userUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get File
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogApi
     */
    public getFile(key: string, options?: AxiosRequestConfig) {
        return LogApiFp(this.configuration).getFile(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get log from uid.  **Args:** - uid (str): UID.  **Returns:** - LogRequest: Schema.
     * @summary Get Log
     * @param {string} uid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogApi
     */
    public getLog(uid: string, options?: AxiosRequestConfig) {
        return LogApiFp(this.configuration).getLog(uid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MainApi - axios parameter creator
 * @export
 */
export const MainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generate Media For Response
         * @param {string} captureUid 
         * @param {string} [receiverUserUid] 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateMediaForResponse: async (captureUid: string, receiverUserUid?: string, userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'captureUid' is not null or undefined
            assertParamExists('generateMediaForResponse', 'captureUid', captureUid)
            const localVarPath = `/api/capture/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (receiverUserUid !== undefined) {
                localVarQueryParameter['receiver_user_uid'] = receiverUserUid;
            }

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


            if (captureUid !== undefined) { 
                localVarFormParams.set('capture_uid', captureUid as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Number Of Responses For Instruction
         * @param {string} studyName 
         * @param {string} [studyInstructionUid] 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNumberOfResponsesForInstruction: async (studyName: string, studyInstructionUid?: string, userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'studyName' is not null or undefined
            assertParamExists('getNumberOfResponsesForInstruction', 'studyName', studyName)
            const localVarPath = `/api/capture/number_responses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (studyName !== undefined) {
                localVarQueryParameter['study_name'] = studyName;
            }

            if (studyInstructionUid !== undefined) {
                localVarQueryParameter['study_instruction_uid'] = studyInstructionUid;
            }

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This is the one stop function for the study responses. It does the following: - upload the image to s3; else the study instruction must have an image. - upload the mask to s3; else check study instruction; else leave as none. - add data to capture table  **Arguments:** - receiver_user_uid (str): Optional if the photo is for a different user. - user_uid (str): If provided, this is the user making the request,   else extract from header.  **Returns:** - dict: TBD
         * @summary Upload Study Response
         * @param {CaptureRequestIn} request 
         * @param {string} [receiverUserUid] 
         * @param {string} [userUid] 
         * @param {File} [image] 
         * @param {File} [mask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadStudyResponse: async (request: CaptureRequestIn, receiverUserUid?: string, userUid?: string, image?: File, mask?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('uploadStudyResponse', 'request', request)
            const localVarPath = `/api/capture/response`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (receiverUserUid !== undefined) {
                localVarQueryParameter['receiver_user_uid'] = receiverUserUid;
            }

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


            if (request !== undefined) { 
                localVarFormParams.append('request', new Blob([JSON.stringify(request)], { type: "application/json", }));
            }
    
            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
            if (mask !== undefined) { 
                localVarFormParams.append('mask', mask as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This is the one stop function for the study responses. It does the following: - upload the image to s3; else the study instruction must have an image. - upload the mask to s3; else check study instruction; else leave as none. - add data to capture table - generate the images using the study.model - add data to the generate table  **Arguments:** - receiver_user_uid (str): Optional if the photo is for a different user. - user_uid (str): If provided, this is the user making the request,   else extract from header.  **Returns:** - dict: TBD
         * @summary Upload Study Response And Generate Media
         * @param {CaptureRequestIn} request 
         * @param {string} [receiverUserUid] 
         * @param {string} [userUid] 
         * @param {File} [image] 
         * @param {File} [mask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadStudyResponseAndGenerateMedia: async (request: CaptureRequestIn, receiverUserUid?: string, userUid?: string, image?: File, mask?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('uploadStudyResponseAndGenerateMedia', 'request', request)
            const localVarPath = `/api/capture/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (receiverUserUid !== undefined) {
                localVarQueryParameter['receiver_user_uid'] = receiverUserUid;
            }

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


            if (request !== undefined) { 
                localVarFormParams.append('request', new Blob([JSON.stringify(request)], { type: "application/json", }));
            }
    
            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
            if (mask !== undefined) { 
                localVarFormParams.append('mask', mask as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MainApi - functional programming interface
 * @export
 */
export const MainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MainApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Generate Media For Response
         * @param {string} captureUid 
         * @param {string} [receiverUserUid] 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateMediaForResponse(captureUid: string, receiverUserUid?: string, userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaptureRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateMediaForResponse(captureUid, receiverUserUid, userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Number Of Responses For Instruction
         * @param {string} studyName 
         * @param {string} [studyInstructionUid] 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNumberOfResponsesForInstruction(studyName: string, studyInstructionUid?: string, userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNumberOfResponsesForInstruction(studyName, studyInstructionUid, userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This is the one stop function for the study responses. It does the following: - upload the image to s3; else the study instruction must have an image. - upload the mask to s3; else check study instruction; else leave as none. - add data to capture table  **Arguments:** - receiver_user_uid (str): Optional if the photo is for a different user. - user_uid (str): If provided, this is the user making the request,   else extract from header.  **Returns:** - dict: TBD
         * @summary Upload Study Response
         * @param {CaptureRequestIn} request 
         * @param {string} [receiverUserUid] 
         * @param {string} [userUid] 
         * @param {File} [image] 
         * @param {File} [mask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadStudyResponse(request: CaptureRequestIn, receiverUserUid?: string, userUid?: string, image?: File, mask?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaptureRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadStudyResponse(request, receiverUserUid, userUid, image, mask, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This is the one stop function for the study responses. It does the following: - upload the image to s3; else the study instruction must have an image. - upload the mask to s3; else check study instruction; else leave as none. - add data to capture table - generate the images using the study.model - add data to the generate table  **Arguments:** - receiver_user_uid (str): Optional if the photo is for a different user. - user_uid (str): If provided, this is the user making the request,   else extract from header.  **Returns:** - dict: TBD
         * @summary Upload Study Response And Generate Media
         * @param {CaptureRequestIn} request 
         * @param {string} [receiverUserUid] 
         * @param {string} [userUid] 
         * @param {File} [image] 
         * @param {File} [mask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadStudyResponseAndGenerateMedia(request: CaptureRequestIn, receiverUserUid?: string, userUid?: string, image?: File, mask?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadStudyResponseAndGenerateMedia(request, receiverUserUid, userUid, image, mask, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MainApi - factory interface
 * @export
 */
export const MainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MainApiFp(configuration)
    return {
        /**
         * 
         * @summary Generate Media For Response
         * @param {string} captureUid 
         * @param {string} [receiverUserUid] 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateMediaForResponse(captureUid: string, receiverUserUid?: string, userUid?: string, options?: any): AxiosPromise<CaptureRequest> {
            return localVarFp.generateMediaForResponse(captureUid, receiverUserUid, userUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Number Of Responses For Instruction
         * @param {string} studyName 
         * @param {string} [studyInstructionUid] 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNumberOfResponsesForInstruction(studyName: string, studyInstructionUid?: string, userUid?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getNumberOfResponsesForInstruction(studyName, studyInstructionUid, userUid, options).then((request) => request(axios, basePath));
        },
        /**
         * This is the one stop function for the study responses. It does the following: - upload the image to s3; else the study instruction must have an image. - upload the mask to s3; else check study instruction; else leave as none. - add data to capture table  **Arguments:** - receiver_user_uid (str): Optional if the photo is for a different user. - user_uid (str): If provided, this is the user making the request,   else extract from header.  **Returns:** - dict: TBD
         * @summary Upload Study Response
         * @param {CaptureRequestIn} request 
         * @param {string} [receiverUserUid] 
         * @param {string} [userUid] 
         * @param {File} [image] 
         * @param {File} [mask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadStudyResponse(request: CaptureRequestIn, receiverUserUid?: string, userUid?: string, image?: File, mask?: File, options?: any): AxiosPromise<CaptureRequest> {
            return localVarFp.uploadStudyResponse(request, receiverUserUid, userUid, image, mask, options).then((request) => request(axios, basePath));
        },
        /**
         * This is the one stop function for the study responses. It does the following: - upload the image to s3; else the study instruction must have an image. - upload the mask to s3; else check study instruction; else leave as none. - add data to capture table - generate the images using the study.model - add data to the generate table  **Arguments:** - receiver_user_uid (str): Optional if the photo is for a different user. - user_uid (str): If provided, this is the user making the request,   else extract from header.  **Returns:** - dict: TBD
         * @summary Upload Study Response And Generate Media
         * @param {CaptureRequestIn} request 
         * @param {string} [receiverUserUid] 
         * @param {string} [userUid] 
         * @param {File} [image] 
         * @param {File} [mask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadStudyResponseAndGenerateMedia(request: CaptureRequestIn, receiverUserUid?: string, userUid?: string, image?: File, mask?: File, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.uploadStudyResponseAndGenerateMedia(request, receiverUserUid, userUid, image, mask, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MainApi - object-oriented interface
 * @export
 * @class MainApi
 * @extends {BaseAPI}
 */
export class MainApi extends BaseAPI {
    /**
     * 
     * @summary Generate Media For Response
     * @param {string} captureUid 
     * @param {string} [receiverUserUid] 
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public generateMediaForResponse(captureUid: string, receiverUserUid?: string, userUid?: string, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).generateMediaForResponse(captureUid, receiverUserUid, userUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Number Of Responses For Instruction
     * @param {string} studyName 
     * @param {string} [studyInstructionUid] 
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public getNumberOfResponsesForInstruction(studyName: string, studyInstructionUid?: string, userUid?: string, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).getNumberOfResponsesForInstruction(studyName, studyInstructionUid, userUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This is the one stop function for the study responses. It does the following: - upload the image to s3; else the study instruction must have an image. - upload the mask to s3; else check study instruction; else leave as none. - add data to capture table  **Arguments:** - receiver_user_uid (str): Optional if the photo is for a different user. - user_uid (str): If provided, this is the user making the request,   else extract from header.  **Returns:** - dict: TBD
     * @summary Upload Study Response
     * @param {CaptureRequestIn} request 
     * @param {string} [receiverUserUid] 
     * @param {string} [userUid] 
     * @param {File} [image] 
     * @param {File} [mask] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public uploadStudyResponse(request: CaptureRequestIn, receiverUserUid?: string, userUid?: string, image?: File, mask?: File, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).uploadStudyResponse(request, receiverUserUid, userUid, image, mask, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This is the one stop function for the study responses. It does the following: - upload the image to s3; else the study instruction must have an image. - upload the mask to s3; else check study instruction; else leave as none. - add data to capture table - generate the images using the study.model - add data to the generate table  **Arguments:** - receiver_user_uid (str): Optional if the photo is for a different user. - user_uid (str): If provided, this is the user making the request,   else extract from header.  **Returns:** - dict: TBD
     * @summary Upload Study Response And Generate Media
     * @param {CaptureRequestIn} request 
     * @param {string} [receiverUserUid] 
     * @param {string} [userUid] 
     * @param {File} [image] 
     * @param {File} [mask] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MainApi
     */
    public uploadStudyResponseAndGenerateMedia(request: CaptureRequestIn, receiverUserUid?: string, userUid?: string, image?: File, mask?: File, options?: AxiosRequestConfig) {
        return MainApiFp(this.configuration).uploadStudyResponseAndGenerateMedia(request, receiverUserUid, userUid, image, mask, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SequenceApi - axios parameter creator
 * @export
 */
export const SequenceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create or Update a sequence request.  **Args:** - sequence (list[str]): List of generated media. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - SequenceRequest: Schema.
         * @summary Create Sequence Map
         * @param {BodyCreateSequenceMapApiSequenceMapPost} bodyCreateSequenceMapApiSequenceMapPost 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSequenceMap: async (bodyCreateSequenceMapApiSequenceMapPost: BodyCreateSequenceMapApiSequenceMapPost, userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bodyCreateSequenceMapApiSequenceMapPost' is not null or undefined
            assertParamExists('createSequenceMap', 'bodyCreateSequenceMapApiSequenceMapPost', bodyCreateSequenceMapApiSequenceMapPost)
            const localVarPath = `/api/sequence/map`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyCreateSequenceMapApiSequenceMapPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create or update a sequence model.  **Args:** - pair (SequencePair): study name and instruction idx. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.
         * @summary Create Sequence Model
         * @param {SequencePair} sequencePair 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSequenceModel: async (sequencePair: SequencePair, userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sequencePair' is not null or undefined
            assertParamExists('createSequenceModel', 'sequencePair', sequencePair)
            const localVarPath = `/api/sequence/model`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sequencePair, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete sequences.   **Args:**  - user_uid (str): Optional. Use if provided, else use JWT to extract uid.
         * @summary Delete Sequence Map
         * @param {string} studyName 
         * @param {number} studyInstructionIdx 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSequenceMap: async (studyName: string, studyInstructionIdx: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'studyName' is not null or undefined
            assertParamExists('deleteSequenceMap', 'studyName', studyName)
            // verify required parameter 'studyInstructionIdx' is not null or undefined
            assertParamExists('deleteSequenceMap', 'studyInstructionIdx', studyInstructionIdx)
            const localVarPath = `/api/sequence/map`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (studyName !== undefined) {
                localVarQueryParameter['study_name'] = studyName;
            }

            if (studyInstructionIdx !== undefined) {
                localVarQueryParameter['study_instruction_idx'] = studyInstructionIdx;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Story Path
         * @param {string} captureUid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoryPath: async (captureUid: string, userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'captureUid' is not null or undefined
            assertParamExists('deleteStoryPath', 'captureUid', captureUid)
            const localVarPath = `/api/sequence/story_path`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (captureUid !== undefined) {
                localVarQueryParameter['capture_uid'] = captureUid;
            }

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate (and store) a new story path for the capture_uid with the provided args. Will override existing story path; this action is not reversible.  **Args:** - capture_uid (str): Capture UID. - length (int, optional): Length of stories to return. Defaults to length defined in Study. - mode (str, optional): The mode to generate the sequence. Defaults to mode defined in Study. - user_uid (str, optional).  **Returns:** - list[CaptureRequest] | JSONResponse
         * @summary Generate Story Path
         * @param {string} captureUid 
         * @param {number} [length] 
         * @param {StudyJourneyModes} [mode] 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateStoryPath: async (captureUid: string, length?: number, mode?: StudyJourneyModes, userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'captureUid' is not null or undefined
            assertParamExists('generateStoryPath', 'captureUid', captureUid)
            const localVarPath = `/api/sequence/story_path`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (captureUid !== undefined) {
                localVarQueryParameter['capture_uid'] = captureUid;
            }

            if (length !== undefined) {
                localVarQueryParameter['length'] = length;
            }

            if (mode !== undefined) {
                localVarQueryParameter['mode'] = mode;
            }

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the SequenceRequest.  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - SequenceRequest: Schema.
         * @summary Get Sequence Map
         * @param {string} studyName 
         * @param {number} studyInstructionIdx 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequenceMap: async (studyName: string, studyInstructionIdx: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'studyName' is not null or undefined
            assertParamExists('getSequenceMap', 'studyName', studyName)
            // verify required parameter 'studyInstructionIdx' is not null or undefined
            assertParamExists('getSequenceMap', 'studyInstructionIdx', studyInstructionIdx)
            const localVarPath = `/api/sequence/map`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (studyName !== undefined) {
                localVarQueryParameter['study_name'] = studyName;
            }

            if (studyInstructionIdx !== undefined) {
                localVarQueryParameter['study_instruction_idx'] = studyInstructionIdx;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return the story path for the capture_uid  **Args:** - capture_uid (str): Capture UID.  **Returns:** - list[CaptureRequest] | JSONResponse
         * @summary Get Story Path
         * @param {string} captureUid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoryPath: async (captureUid: string, userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'captureUid' is not null or undefined
            assertParamExists('getStoryPath', 'captureUid', captureUid)
            const localVarPath = `/api/sequence/story_path`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (captureUid !== undefined) {
                localVarQueryParameter['capture_uid'] = captureUid;
            }

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SequenceApi - functional programming interface
 * @export
 */
export const SequenceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SequenceApiAxiosParamCreator(configuration)
    return {
        /**
         * Create or Update a sequence request.  **Args:** - sequence (list[str]): List of generated media. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - SequenceRequest: Schema.
         * @summary Create Sequence Map
         * @param {BodyCreateSequenceMapApiSequenceMapPost} bodyCreateSequenceMapApiSequenceMapPost 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSequenceMap(bodyCreateSequenceMapApiSequenceMapPost: BodyCreateSequenceMapApiSequenceMapPost, userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SequenceRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSequenceMap(bodyCreateSequenceMapApiSequenceMapPost, userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create or update a sequence model.  **Args:** - pair (SequencePair): study name and instruction idx. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.
         * @summary Create Sequence Model
         * @param {SequencePair} sequencePair 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSequenceModel(sequencePair: SequencePair, userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSequenceModel(sequencePair, userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete sequences.   **Args:**  - user_uid (str): Optional. Use if provided, else use JWT to extract uid.
         * @summary Delete Sequence Map
         * @param {string} studyName 
         * @param {number} studyInstructionIdx 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSequenceMap(studyName: string, studyInstructionIdx: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSequenceMap(studyName, studyInstructionIdx, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Story Path
         * @param {string} captureUid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStoryPath(captureUid: string, userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStoryPath(captureUid, userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generate (and store) a new story path for the capture_uid with the provided args. Will override existing story path; this action is not reversible.  **Args:** - capture_uid (str): Capture UID. - length (int, optional): Length of stories to return. Defaults to length defined in Study. - mode (str, optional): The mode to generate the sequence. Defaults to mode defined in Study. - user_uid (str, optional).  **Returns:** - list[CaptureRequest] | JSONResponse
         * @summary Generate Story Path
         * @param {string} captureUid 
         * @param {number} [length] 
         * @param {StudyJourneyModes} [mode] 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateStoryPath(captureUid: string, length?: number, mode?: StudyJourneyModes, userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateStoryPath(captureUid, length, mode, userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the SequenceRequest.  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - SequenceRequest: Schema.
         * @summary Get Sequence Map
         * @param {string} studyName 
         * @param {number} studyInstructionIdx 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSequenceMap(studyName: string, studyInstructionIdx: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SequenceRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSequenceMap(studyName, studyInstructionIdx, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return the story path for the capture_uid  **Args:** - capture_uid (str): Capture UID.  **Returns:** - list[CaptureRequest] | JSONResponse
         * @summary Get Story Path
         * @param {string} captureUid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoryPath(captureUid: string, userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoryPath(captureUid, userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SequenceApi - factory interface
 * @export
 */
export const SequenceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SequenceApiFp(configuration)
    return {
        /**
         * Create or Update a sequence request.  **Args:** - sequence (list[str]): List of generated media. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - SequenceRequest: Schema.
         * @summary Create Sequence Map
         * @param {BodyCreateSequenceMapApiSequenceMapPost} bodyCreateSequenceMapApiSequenceMapPost 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSequenceMap(bodyCreateSequenceMapApiSequenceMapPost: BodyCreateSequenceMapApiSequenceMapPost, userUid?: string, options?: any): AxiosPromise<SequenceRequest> {
            return localVarFp.createSequenceMap(bodyCreateSequenceMapApiSequenceMapPost, userUid, options).then((request) => request(axios, basePath));
        },
        /**
         * Create or update a sequence model.  **Args:** - pair (SequencePair): study name and instruction idx. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.
         * @summary Create Sequence Model
         * @param {SequencePair} sequencePair 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSequenceModel(sequencePair: SequencePair, userUid?: string, options?: any): AxiosPromise<any> {
            return localVarFp.createSequenceModel(sequencePair, userUid, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete sequences.   **Args:**  - user_uid (str): Optional. Use if provided, else use JWT to extract uid.
         * @summary Delete Sequence Map
         * @param {string} studyName 
         * @param {number} studyInstructionIdx 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSequenceMap(studyName: string, studyInstructionIdx: number, options?: any): AxiosPromise<any> {
            return localVarFp.deleteSequenceMap(studyName, studyInstructionIdx, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Story Path
         * @param {string} captureUid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoryPath(captureUid: string, userUid?: string, options?: any): AxiosPromise<any> {
            return localVarFp.deleteStoryPath(captureUid, userUid, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate (and store) a new story path for the capture_uid with the provided args. Will override existing story path; this action is not reversible.  **Args:** - capture_uid (str): Capture UID. - length (int, optional): Length of stories to return. Defaults to length defined in Study. - mode (str, optional): The mode to generate the sequence. Defaults to mode defined in Study. - user_uid (str, optional).  **Returns:** - list[CaptureRequest] | JSONResponse
         * @summary Generate Story Path
         * @param {string} captureUid 
         * @param {number} [length] 
         * @param {StudyJourneyModes} [mode] 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateStoryPath(captureUid: string, length?: number, mode?: StudyJourneyModes, userUid?: string, options?: any): AxiosPromise<any> {
            return localVarFp.generateStoryPath(captureUid, length, mode, userUid, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the SequenceRequest.  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - SequenceRequest: Schema.
         * @summary Get Sequence Map
         * @param {string} studyName 
         * @param {number} studyInstructionIdx 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSequenceMap(studyName: string, studyInstructionIdx: number, options?: any): AxiosPromise<SequenceRequest> {
            return localVarFp.getSequenceMap(studyName, studyInstructionIdx, options).then((request) => request(axios, basePath));
        },
        /**
         * Return the story path for the capture_uid  **Args:** - capture_uid (str): Capture UID.  **Returns:** - list[CaptureRequest] | JSONResponse
         * @summary Get Story Path
         * @param {string} captureUid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoryPath(captureUid: string, userUid?: string, options?: any): AxiosPromise<any> {
            return localVarFp.getStoryPath(captureUid, userUid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SequenceApi - object-oriented interface
 * @export
 * @class SequenceApi
 * @extends {BaseAPI}
 */
export class SequenceApi extends BaseAPI {
    /**
     * Create or Update a sequence request.  **Args:** - sequence (list[str]): List of generated media. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - SequenceRequest: Schema.
     * @summary Create Sequence Map
     * @param {BodyCreateSequenceMapApiSequenceMapPost} bodyCreateSequenceMapApiSequenceMapPost 
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceApi
     */
    public createSequenceMap(bodyCreateSequenceMapApiSequenceMapPost: BodyCreateSequenceMapApiSequenceMapPost, userUid?: string, options?: AxiosRequestConfig) {
        return SequenceApiFp(this.configuration).createSequenceMap(bodyCreateSequenceMapApiSequenceMapPost, userUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create or update a sequence model.  **Args:** - pair (SequencePair): study name and instruction idx. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.
     * @summary Create Sequence Model
     * @param {SequencePair} sequencePair 
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceApi
     */
    public createSequenceModel(sequencePair: SequencePair, userUid?: string, options?: AxiosRequestConfig) {
        return SequenceApiFp(this.configuration).createSequenceModel(sequencePair, userUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete sequences.   **Args:**  - user_uid (str): Optional. Use if provided, else use JWT to extract uid.
     * @summary Delete Sequence Map
     * @param {string} studyName 
     * @param {number} studyInstructionIdx 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceApi
     */
    public deleteSequenceMap(studyName: string, studyInstructionIdx: number, options?: AxiosRequestConfig) {
        return SequenceApiFp(this.configuration).deleteSequenceMap(studyName, studyInstructionIdx, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Story Path
     * @param {string} captureUid 
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceApi
     */
    public deleteStoryPath(captureUid: string, userUid?: string, options?: AxiosRequestConfig) {
        return SequenceApiFp(this.configuration).deleteStoryPath(captureUid, userUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate (and store) a new story path for the capture_uid with the provided args. Will override existing story path; this action is not reversible.  **Args:** - capture_uid (str): Capture UID. - length (int, optional): Length of stories to return. Defaults to length defined in Study. - mode (str, optional): The mode to generate the sequence. Defaults to mode defined in Study. - user_uid (str, optional).  **Returns:** - list[CaptureRequest] | JSONResponse
     * @summary Generate Story Path
     * @param {string} captureUid 
     * @param {number} [length] 
     * @param {StudyJourneyModes} [mode] 
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceApi
     */
    public generateStoryPath(captureUid: string, length?: number, mode?: StudyJourneyModes, userUid?: string, options?: AxiosRequestConfig) {
        return SequenceApiFp(this.configuration).generateStoryPath(captureUid, length, mode, userUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the SequenceRequest.  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - SequenceRequest: Schema.
     * @summary Get Sequence Map
     * @param {string} studyName 
     * @param {number} studyInstructionIdx 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceApi
     */
    public getSequenceMap(studyName: string, studyInstructionIdx: number, options?: AxiosRequestConfig) {
        return SequenceApiFp(this.configuration).getSequenceMap(studyName, studyInstructionIdx, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return the story path for the capture_uid  **Args:** - capture_uid (str): Capture UID.  **Returns:** - list[CaptureRequest] | JSONResponse
     * @summary Get Story Path
     * @param {string} captureUid 
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SequenceApi
     */
    public getStoryPath(captureUid: string, userUid?: string, options?: AxiosRequestConfig) {
        return SequenceApiFp(this.configuration).getStoryPath(captureUid, userUid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StudyApi - axios parameter creator
 * @export
 */
export const StudyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new study.  **Args:** - study (StudyRequestBase): Schema.  **Raises:** - HTTPException: 409, if study name already exists.  **Returns:** - StudyRequest: Schema.
         * @summary Create New Study
         * @param {StudyRequestBase} studyRequestBase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewStudy: async (studyRequestBase: StudyRequestBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'studyRequestBase' is not null or undefined
            assertParamExists('createNewStudy', 'studyRequestBase', studyRequestBase)
            const localVarPath = `/api/study`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studyRequestBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a given study.  **Args:** - study_name (str): Study name.  **Returns:** - dict: Response.
         * @summary Delete Study
         * @param {string} studyName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStudy: async (studyName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'studyName' is not null or undefined
            assertParamExists('deleteStudy', 'studyName', studyName)
            const localVarPath = `/api/study`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (studyName !== undefined) {
                localVarQueryParameter['study_name'] = studyName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a list of all the study names.  **Returns:** - List[Study_Names]
         * @summary Get All Study
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllStudy: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/study/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a list of all the study with their details. **Returns:** - List[StudyRequest]
         * @summary Get All Study Details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllStudyDetails: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/study/all_details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get study with uid.  **Args:** - study_uid (str): Study UID.  **Returns:** - StudyRequest: Schema.
         * @summary Get Study
         * @param {string} studyUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudy: async (studyUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'studyUid' is not null or undefined
            assertParamExists('getStudy', 'studyUid', studyUid)
            const localVarPath = `/api/study`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (studyUid !== undefined) {
                localVarQueryParameter['study_uid'] = studyUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get StudyRequest using study name.  **Args:** - study_name (str): Study name.  **Raises:** - HTTPException: 404, study name doesn\'t exist.  **Returns:** - StudyRequest: Schema.
         * @summary Get Study Name
         * @param {string} studyName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudyName: async (studyName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'studyName' is not null or undefined
            assertParamExists('getStudyName', 'studyName', studyName)
            const localVarPath = `/api/study/study_name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (studyName !== undefined) {
                localVarQueryParameter['study_name'] = studyName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the status for the current study.  **Args:** - status (StudyStatus)  **Returns:** - StudyRequest
         * @summary Get Study Status
         * @param {string} studyName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudyStatus: async (studyName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'studyName' is not null or undefined
            assertParamExists('getStudyStatus', 'studyName', studyName)
            const localVarPath = `/api/study/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (studyName !== undefined) {
                localVarQueryParameter['study_name'] = studyName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modify existing study. All items of an existing study can be modified except for the UID or study_name.  **Args:** - study (StudyRequest): Schema.  **Raises:** - HTTPException: 404, if study UID does exist.  **Returns:** - StudyRequest: Schema.
         * @summary Modify Study
         * @param {StudyRequest} studyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyStudy: async (studyRequest: StudyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'studyRequest' is not null or undefined
            assertParamExists('modifyStudy', 'studyRequest', studyRequest)
            const localVarPath = `/api/study`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the status for the current study.  **Args:** - status (StudyStatus)  **Returns:** - StudyRequest
         * @summary Update Study Status
         * @param {BodyUpdateStudyStatusApiStudyStatusPut} bodyUpdateStudyStatusApiStudyStatusPut 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStudyStatus: async (bodyUpdateStudyStatusApiStudyStatusPut: BodyUpdateStudyStatusApiStudyStatusPut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bodyUpdateStudyStatusApiStudyStatusPut' is not null or undefined
            assertParamExists('updateStudyStatus', 'bodyUpdateStudyStatusApiStudyStatusPut', bodyUpdateStudyStatusApiStudyStatusPut)
            const localVarPath = `/api/study/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyUpdateStudyStatusApiStudyStatusPut, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StudyApi - functional programming interface
 * @export
 */
export const StudyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StudyApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new study.  **Args:** - study (StudyRequestBase): Schema.  **Raises:** - HTTPException: 409, if study name already exists.  **Returns:** - StudyRequest: Schema.
         * @summary Create New Study
         * @param {StudyRequestBase} studyRequestBase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNewStudy(studyRequestBase: StudyRequestBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNewStudy(studyRequestBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a given study.  **Args:** - study_name (str): Study name.  **Returns:** - dict: Response.
         * @summary Delete Study
         * @param {string} studyName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStudy(studyName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStudy(studyName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a list of all the study names.  **Returns:** - List[Study_Names]
         * @summary Get All Study
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllStudy(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseGetAllStudyApiStudyAllGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllStudy(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a list of all the study with their details. **Returns:** - List[StudyRequest]
         * @summary Get All Study Details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllStudyDetails(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseGetAllStudyDetailsApiStudyAllDetailsGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllStudyDetails(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get study with uid.  **Args:** - study_uid (str): Study UID.  **Returns:** - StudyRequest: Schema.
         * @summary Get Study
         * @param {string} studyUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudy(studyUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudy(studyUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get StudyRequest using study name.  **Args:** - study_name (str): Study name.  **Raises:** - HTTPException: 404, study name doesn\'t exist.  **Returns:** - StudyRequest: Schema.
         * @summary Get Study Name
         * @param {string} studyName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudyName(studyName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudyName(studyName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the status for the current study.  **Args:** - status (StudyStatus)  **Returns:** - StudyRequest
         * @summary Get Study Status
         * @param {string} studyName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudyStatus(studyName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudyStatus(studyName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Modify existing study. All items of an existing study can be modified except for the UID or study_name.  **Args:** - study (StudyRequest): Schema.  **Raises:** - HTTPException: 404, if study UID does exist.  **Returns:** - StudyRequest: Schema.
         * @summary Modify Study
         * @param {StudyRequest} studyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyStudy(studyRequest: StudyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modifyStudy(studyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the status for the current study.  **Args:** - status (StudyStatus)  **Returns:** - StudyRequest
         * @summary Update Study Status
         * @param {BodyUpdateStudyStatusApiStudyStatusPut} bodyUpdateStudyStatusApiStudyStatusPut 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStudyStatus(bodyUpdateStudyStatusApiStudyStatusPut: BodyUpdateStudyStatusApiStudyStatusPut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStudyStatus(bodyUpdateStudyStatusApiStudyStatusPut, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StudyApi - factory interface
 * @export
 */
export const StudyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StudyApiFp(configuration)
    return {
        /**
         * Create a new study.  **Args:** - study (StudyRequestBase): Schema.  **Raises:** - HTTPException: 409, if study name already exists.  **Returns:** - StudyRequest: Schema.
         * @summary Create New Study
         * @param {StudyRequestBase} studyRequestBase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewStudy(studyRequestBase: StudyRequestBase, options?: any): AxiosPromise<StudyRequest> {
            return localVarFp.createNewStudy(studyRequestBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a given study.  **Args:** - study_name (str): Study name.  **Returns:** - dict: Response.
         * @summary Delete Study
         * @param {string} studyName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStudy(studyName: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteStudy(studyName, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a list of all the study names.  **Returns:** - List[Study_Names]
         * @summary Get All Study
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllStudy(options?: any): AxiosPromise<ResponseGetAllStudyApiStudyAllGet> {
            return localVarFp.getAllStudy(options).then((request) => request(axios, basePath));
        },
        /**
         * Get a list of all the study with their details. **Returns:** - List[StudyRequest]
         * @summary Get All Study Details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllStudyDetails(options?: any): AxiosPromise<ResponseGetAllStudyDetailsApiStudyAllDetailsGet> {
            return localVarFp.getAllStudyDetails(options).then((request) => request(axios, basePath));
        },
        /**
         * Get study with uid.  **Args:** - study_uid (str): Study UID.  **Returns:** - StudyRequest: Schema.
         * @summary Get Study
         * @param {string} studyUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudy(studyUid: string, options?: any): AxiosPromise<StudyRequest> {
            return localVarFp.getStudy(studyUid, options).then((request) => request(axios, basePath));
        },
        /**
         * Get StudyRequest using study name.  **Args:** - study_name (str): Study name.  **Raises:** - HTTPException: 404, study name doesn\'t exist.  **Returns:** - StudyRequest: Schema.
         * @summary Get Study Name
         * @param {string} studyName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudyName(studyName: string, options?: any): AxiosPromise<StudyRequest> {
            return localVarFp.getStudyName(studyName, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the status for the current study.  **Args:** - status (StudyStatus)  **Returns:** - StudyRequest
         * @summary Get Study Status
         * @param {string} studyName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudyStatus(studyName: string, options?: any): AxiosPromise<StudyStatus> {
            return localVarFp.getStudyStatus(studyName, options).then((request) => request(axios, basePath));
        },
        /**
         * Modify existing study. All items of an existing study can be modified except for the UID or study_name.  **Args:** - study (StudyRequest): Schema.  **Raises:** - HTTPException: 404, if study UID does exist.  **Returns:** - StudyRequest: Schema.
         * @summary Modify Study
         * @param {StudyRequest} studyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyStudy(studyRequest: StudyRequest, options?: any): AxiosPromise<StudyRequest> {
            return localVarFp.modifyStudy(studyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the status for the current study.  **Args:** - status (StudyStatus)  **Returns:** - StudyRequest
         * @summary Update Study Status
         * @param {BodyUpdateStudyStatusApiStudyStatusPut} bodyUpdateStudyStatusApiStudyStatusPut 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStudyStatus(bodyUpdateStudyStatusApiStudyStatusPut: BodyUpdateStudyStatusApiStudyStatusPut, options?: any): AxiosPromise<StudyRequest> {
            return localVarFp.updateStudyStatus(bodyUpdateStudyStatusApiStudyStatusPut, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StudyApi - object-oriented interface
 * @export
 * @class StudyApi
 * @extends {BaseAPI}
 */
export class StudyApi extends BaseAPI {
    /**
     * Create a new study.  **Args:** - study (StudyRequestBase): Schema.  **Raises:** - HTTPException: 409, if study name already exists.  **Returns:** - StudyRequest: Schema.
     * @summary Create New Study
     * @param {StudyRequestBase} studyRequestBase 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyApi
     */
    public createNewStudy(studyRequestBase: StudyRequestBase, options?: AxiosRequestConfig) {
        return StudyApiFp(this.configuration).createNewStudy(studyRequestBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a given study.  **Args:** - study_name (str): Study name.  **Returns:** - dict: Response.
     * @summary Delete Study
     * @param {string} studyName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyApi
     */
    public deleteStudy(studyName: string, options?: AxiosRequestConfig) {
        return StudyApiFp(this.configuration).deleteStudy(studyName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a list of all the study names.  **Returns:** - List[Study_Names]
     * @summary Get All Study
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyApi
     */
    public getAllStudy(options?: AxiosRequestConfig) {
        return StudyApiFp(this.configuration).getAllStudy(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a list of all the study with their details. **Returns:** - List[StudyRequest]
     * @summary Get All Study Details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyApi
     */
    public getAllStudyDetails(options?: AxiosRequestConfig) {
        return StudyApiFp(this.configuration).getAllStudyDetails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get study with uid.  **Args:** - study_uid (str): Study UID.  **Returns:** - StudyRequest: Schema.
     * @summary Get Study
     * @param {string} studyUid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyApi
     */
    public getStudy(studyUid: string, options?: AxiosRequestConfig) {
        return StudyApiFp(this.configuration).getStudy(studyUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get StudyRequest using study name.  **Args:** - study_name (str): Study name.  **Raises:** - HTTPException: 404, study name doesn\'t exist.  **Returns:** - StudyRequest: Schema.
     * @summary Get Study Name
     * @param {string} studyName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyApi
     */
    public getStudyName(studyName: string, options?: AxiosRequestConfig) {
        return StudyApiFp(this.configuration).getStudyName(studyName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the status for the current study.  **Args:** - status (StudyStatus)  **Returns:** - StudyRequest
     * @summary Get Study Status
     * @param {string} studyName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyApi
     */
    public getStudyStatus(studyName: string, options?: AxiosRequestConfig) {
        return StudyApiFp(this.configuration).getStudyStatus(studyName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modify existing study. All items of an existing study can be modified except for the UID or study_name.  **Args:** - study (StudyRequest): Schema.  **Raises:** - HTTPException: 404, if study UID does exist.  **Returns:** - StudyRequest: Schema.
     * @summary Modify Study
     * @param {StudyRequest} studyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyApi
     */
    public modifyStudy(studyRequest: StudyRequest, options?: AxiosRequestConfig) {
        return StudyApiFp(this.configuration).modifyStudy(studyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the status for the current study.  **Args:** - status (StudyStatus)  **Returns:** - StudyRequest
     * @summary Update Study Status
     * @param {BodyUpdateStudyStatusApiStudyStatusPut} bodyUpdateStudyStatusApiStudyStatusPut 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyApi
     */
    public updateStudyStatus(bodyUpdateStudyStatusApiStudyStatusPut: BodyUpdateStudyStatusApiStudyStatusPut, options?: AxiosRequestConfig) {
        return StudyApiFp(this.configuration).updateStudyStatus(bodyUpdateStudyStatusApiStudyStatusPut, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StudyUsersApi - axios parameter creator
 * @export
 */
export const StudyUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add user to study.  **Args:** - study_user (StudyUsersRequestBase): Schema.  **Raises:** - HTTPException: 404, If user, or study does not exist. - HTTPException: 409, If user is already associated with given study.  **Returns:** - StudyUsersRequest: Schema.
         * @summary Add User To Study
         * @param {StudyUsersRequestBase} studyUsersRequestBase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserToStudy: async (studyUsersRequestBase: StudyUsersRequestBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'studyUsersRequestBase' is not null or undefined
            assertParamExists('addUserToStudy', 'studyUsersRequestBase', studyUsersRequestBase)
            const localVarPath = `/api/study_users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studyUsersRequestBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a user from study.  **Args:** - study_user (StudyUsersRequestBase): Schema.  **Returns:** - dict: Response.
         * @summary Delete User From Study
         * @param {string} userUid 
         * @param {string} studyName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserFromStudy: async (userUid: string, studyName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUid' is not null or undefined
            assertParamExists('deleteUserFromStudy', 'userUid', userUid)
            // verify required parameter 'studyName' is not null or undefined
            assertParamExists('deleteUserFromStudy', 'studyName', studyName)
            const localVarPath = `/api/study_users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }

            if (studyName !== undefined) {
                localVarQueryParameter['study_name'] = studyName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all studies a given user is involved with.  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Raises:** - HTTPException: 404, if user does not exist.  **Returns:** - list: Study names.
         * @summary Get Studies For User
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudiesForUser: async (userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/study_users/user_uid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a StudyUsersRequest associated with user and study.  **Args:** - study_user (StudyUsersRequestBase): Schema.  **Raises:** - HTTPException: 404, if user and study name combination not found.  **Returns:** - StudyUsersRequest: Schema.
         * @summary Get Study User
         * @param {StudyUsersRequestBase} studyUsersRequestBase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudyUser: async (studyUsersRequestBase: StudyUsersRequestBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'studyUsersRequestBase' is not null or undefined
            assertParamExists('getStudyUser', 'studyUsersRequestBase', studyUsersRequestBase)
            const localVarPath = `/api/study_users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studyUsersRequestBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all users in a given study.  **Args:** - study_name (str): Study Name,  **Raises:** - HTTPException: 404, if study doesn\'t exist.  **Returns:** - list: List of users.
         * @summary Get Users In Study
         * @param {string} studyName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersInStudy: async (studyName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'studyName' is not null or undefined
            assertParamExists('getUsersInStudy', 'studyName', studyName)
            const localVarPath = `/api/study_users/study_name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (studyName !== undefined) {
                localVarQueryParameter['study_name'] = studyName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Move user to study. When user_uid is empty, it will use the authorized user id.  **Args:** - study_user (StudyUsersRequestBase): Schema.  **Raises:** - HTTPException: 404, If user, or study does not exist. - HTTPException: 409, If user is already associated with given study.  **Returns:** - StudyUsersRequest: Schema.
         * @summary Move User To Study
         * @param {StudyUsersRequestBase} studyUsersRequestBase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveUserToStudy: async (studyUsersRequestBase: StudyUsersRequestBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'studyUsersRequestBase' is not null or undefined
            assertParamExists('moveUserToStudy', 'studyUsersRequestBase', studyUsersRequestBase)
            const localVarPath = `/api/study_users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studyUsersRequestBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StudyUsersApi - functional programming interface
 * @export
 */
export const StudyUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StudyUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Add user to study.  **Args:** - study_user (StudyUsersRequestBase): Schema.  **Raises:** - HTTPException: 404, If user, or study does not exist. - HTTPException: 409, If user is already associated with given study.  **Returns:** - StudyUsersRequest: Schema.
         * @summary Add User To Study
         * @param {StudyUsersRequestBase} studyUsersRequestBase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUserToStudy(studyUsersRequestBase: StudyUsersRequestBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyUsersRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUserToStudy(studyUsersRequestBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a user from study.  **Args:** - study_user (StudyUsersRequestBase): Schema.  **Returns:** - dict: Response.
         * @summary Delete User From Study
         * @param {string} userUid 
         * @param {string} studyName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserFromStudy(userUid: string, studyName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserFromStudy(userUid, studyName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all studies a given user is involved with.  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Raises:** - HTTPException: 404, if user does not exist.  **Returns:** - list: Study names.
         * @summary Get Studies For User
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudiesForUser(userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudiesForUser(userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a StudyUsersRequest associated with user and study.  **Args:** - study_user (StudyUsersRequestBase): Schema.  **Raises:** - HTTPException: 404, if user and study name combination not found.  **Returns:** - StudyUsersRequest: Schema.
         * @summary Get Study User
         * @param {StudyUsersRequestBase} studyUsersRequestBase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudyUser(studyUsersRequestBase: StudyUsersRequestBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyUsersRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudyUser(studyUsersRequestBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all users in a given study.  **Args:** - study_name (str): Study Name,  **Raises:** - HTTPException: 404, if study doesn\'t exist.  **Returns:** - list: List of users.
         * @summary Get Users In Study
         * @param {string} studyName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersInStudy(studyName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersInStudy(studyName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Move user to study. When user_uid is empty, it will use the authorized user id.  **Args:** - study_user (StudyUsersRequestBase): Schema.  **Raises:** - HTTPException: 404, If user, or study does not exist. - HTTPException: 409, If user is already associated with given study.  **Returns:** - StudyUsersRequest: Schema.
         * @summary Move User To Study
         * @param {StudyUsersRequestBase} studyUsersRequestBase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveUserToStudy(studyUsersRequestBase: StudyUsersRequestBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StudyUsersRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moveUserToStudy(studyUsersRequestBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StudyUsersApi - factory interface
 * @export
 */
export const StudyUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StudyUsersApiFp(configuration)
    return {
        /**
         * Add user to study.  **Args:** - study_user (StudyUsersRequestBase): Schema.  **Raises:** - HTTPException: 404, If user, or study does not exist. - HTTPException: 409, If user is already associated with given study.  **Returns:** - StudyUsersRequest: Schema.
         * @summary Add User To Study
         * @param {StudyUsersRequestBase} studyUsersRequestBase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserToStudy(studyUsersRequestBase: StudyUsersRequestBase, options?: any): AxiosPromise<StudyUsersRequest> {
            return localVarFp.addUserToStudy(studyUsersRequestBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a user from study.  **Args:** - study_user (StudyUsersRequestBase): Schema.  **Returns:** - dict: Response.
         * @summary Delete User From Study
         * @param {string} userUid 
         * @param {string} studyName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserFromStudy(userUid: string, studyName: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteUserFromStudy(userUid, studyName, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all studies a given user is involved with.  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Raises:** - HTTPException: 404, if user does not exist.  **Returns:** - list: Study names.
         * @summary Get Studies For User
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudiesForUser(userUid?: string, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.getStudiesForUser(userUid, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a StudyUsersRequest associated with user and study.  **Args:** - study_user (StudyUsersRequestBase): Schema.  **Raises:** - HTTPException: 404, if user and study name combination not found.  **Returns:** - StudyUsersRequest: Schema.
         * @summary Get Study User
         * @param {StudyUsersRequestBase} studyUsersRequestBase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudyUser(studyUsersRequestBase: StudyUsersRequestBase, options?: any): AxiosPromise<StudyUsersRequest> {
            return localVarFp.getStudyUser(studyUsersRequestBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all users in a given study.  **Args:** - study_name (str): Study Name,  **Raises:** - HTTPException: 404, if study doesn\'t exist.  **Returns:** - list: List of users.
         * @summary Get Users In Study
         * @param {string} studyName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersInStudy(studyName: string, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.getUsersInStudy(studyName, options).then((request) => request(axios, basePath));
        },
        /**
         * Move user to study. When user_uid is empty, it will use the authorized user id.  **Args:** - study_user (StudyUsersRequestBase): Schema.  **Raises:** - HTTPException: 404, If user, or study does not exist. - HTTPException: 409, If user is already associated with given study.  **Returns:** - StudyUsersRequest: Schema.
         * @summary Move User To Study
         * @param {StudyUsersRequestBase} studyUsersRequestBase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveUserToStudy(studyUsersRequestBase: StudyUsersRequestBase, options?: any): AxiosPromise<StudyUsersRequest> {
            return localVarFp.moveUserToStudy(studyUsersRequestBase, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StudyUsersApi - object-oriented interface
 * @export
 * @class StudyUsersApi
 * @extends {BaseAPI}
 */
export class StudyUsersApi extends BaseAPI {
    /**
     * Add user to study.  **Args:** - study_user (StudyUsersRequestBase): Schema.  **Raises:** - HTTPException: 404, If user, or study does not exist. - HTTPException: 409, If user is already associated with given study.  **Returns:** - StudyUsersRequest: Schema.
     * @summary Add User To Study
     * @param {StudyUsersRequestBase} studyUsersRequestBase 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyUsersApi
     */
    public addUserToStudy(studyUsersRequestBase: StudyUsersRequestBase, options?: AxiosRequestConfig) {
        return StudyUsersApiFp(this.configuration).addUserToStudy(studyUsersRequestBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a user from study.  **Args:** - study_user (StudyUsersRequestBase): Schema.  **Returns:** - dict: Response.
     * @summary Delete User From Study
     * @param {string} userUid 
     * @param {string} studyName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyUsersApi
     */
    public deleteUserFromStudy(userUid: string, studyName: string, options?: AxiosRequestConfig) {
        return StudyUsersApiFp(this.configuration).deleteUserFromStudy(userUid, studyName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all studies a given user is involved with.  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Raises:** - HTTPException: 404, if user does not exist.  **Returns:** - list: Study names.
     * @summary Get Studies For User
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyUsersApi
     */
    public getStudiesForUser(userUid?: string, options?: AxiosRequestConfig) {
        return StudyUsersApiFp(this.configuration).getStudiesForUser(userUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a StudyUsersRequest associated with user and study.  **Args:** - study_user (StudyUsersRequestBase): Schema.  **Raises:** - HTTPException: 404, if user and study name combination not found.  **Returns:** - StudyUsersRequest: Schema.
     * @summary Get Study User
     * @param {StudyUsersRequestBase} studyUsersRequestBase 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyUsersApi
     */
    public getStudyUser(studyUsersRequestBase: StudyUsersRequestBase, options?: AxiosRequestConfig) {
        return StudyUsersApiFp(this.configuration).getStudyUser(studyUsersRequestBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all users in a given study.  **Args:** - study_name (str): Study Name,  **Raises:** - HTTPException: 404, if study doesn\'t exist.  **Returns:** - list: List of users.
     * @summary Get Users In Study
     * @param {string} studyName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyUsersApi
     */
    public getUsersInStudy(studyName: string, options?: AxiosRequestConfig) {
        return StudyUsersApiFp(this.configuration).getUsersInStudy(studyName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Move user to study. When user_uid is empty, it will use the authorized user id.  **Args:** - study_user (StudyUsersRequestBase): Schema.  **Raises:** - HTTPException: 404, If user, or study does not exist. - HTTPException: 409, If user is already associated with given study.  **Returns:** - StudyUsersRequest: Schema.
     * @summary Move User To Study
     * @param {StudyUsersRequestBase} studyUsersRequestBase 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyUsersApi
     */
    public moveUserToStudy(studyUsersRequestBase: StudyUsersRequestBase, options?: AxiosRequestConfig) {
        return StudyUsersApiFp(this.configuration).moveUserToStudy(studyUsersRequestBase, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new user.  **Args:** - profile_name (str): Unique profile name. - pilot (Pilot, optional): Defaults to Pilot.YES.  **Raises:** - HTTPException: 404, profile name exists.  **Returns:** - UsernameRequest: Schema.
         * @summary Create User
         * @param {string} profileName 
         * @param {Pilot} [pilot] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (profileName: string, pilot?: Pilot, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileName' is not null or undefined
            assertParamExists('createUser', 'profileName', profileName)
            const localVarPath = `/api/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (profileName !== undefined) {
                localVarQueryParameter['profile_name'] = profileName;
            }

            if (pilot !== undefined) {
                localVarQueryParameter['pilot'] = pilot;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the user with profile name.  **Args:** - profile_name (str): Profile name.  **Returns:** - dict: Response.
         * @summary Delete User With Profile Name
         * @param {string} profileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserWithProfileName: async (profileName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileName' is not null or undefined
            assertParamExists('deleteUserWithProfileName', 'profileName', profileName)
            const localVarPath = `/api/user/username`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (profileName !== undefined) {
                localVarQueryParameter['profile_name'] = profileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete user from table with uid.  **Args:** - uid (str): User UID.  **Returns:** - dict: Response.
         * @summary Delete User With Uid
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserWithUid: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('deleteUserWithUid', 'uid', uid)
            const localVarPath = `/api/user/uid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the profile name.  **Args:** - profile_name (str): New profile name.  **Returns:** - dict: Response.
         * @summary Get User State
         * @param {string} [userUid] 
         * @param {string} [studyName] 
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {boolean} [isLatest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserState: async (userUid?: string, studyName?: string, startTime?: string, endTime?: string, isLatest?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }

            if (studyName !== undefined) {
                localVarQueryParameter['study_name'] = studyName;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (isLatest !== undefined) {
                localVarQueryParameter['is_latest'] = isLatest;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the user with UID.  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - UsernameRequest: Schema.
         * @summary Get User With Uid
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserWithUid: async (userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/uid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the user with profile name.  **Args:** - profile_name (str): Profile name.  **Returns:** - UsernameRequest: Schema.
         * @summary Get User With Username
         * @param {string} profileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserWithUsername: async (profileName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileName' is not null or undefined
            assertParamExists('getUserWithUsername', 'profileName', profileName)
            const localVarPath = `/api/user/username`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (profileName !== undefined) {
                localVarQueryParameter['profile_name'] = profileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the profile name.  **Args:** - profile_name (str): New profile name.  **Returns:** - dict: Response.
         * @summary Update Profile Name
         * @param {string} profileName 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfileName: async (profileName: string, userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileName' is not null or undefined
            assertParamExists('updateProfileName', 'profileName', profileName)
            const localVarPath = `/api/user/username`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (profileName !== undefined) {
                localVarQueryParameter['profile_name'] = profileName;
            }

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User State
         * @param {UserState} userState 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserState: async (userState: UserState, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userState' is not null or undefined
            assertParamExists('updateUserState', 'userState', userState)
            const localVarPath = `/api/user/state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userState, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new user.  **Args:** - profile_name (str): Unique profile name. - pilot (Pilot, optional): Defaults to Pilot.YES.  **Raises:** - HTTPException: 404, profile name exists.  **Returns:** - UsernameRequest: Schema.
         * @summary Create User
         * @param {string} profileName 
         * @param {Pilot} [pilot] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(profileName: string, pilot?: Pilot, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsernameRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(profileName, pilot, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete the user with profile name.  **Args:** - profile_name (str): Profile name.  **Returns:** - dict: Response.
         * @summary Delete User With Profile Name
         * @param {string} profileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserWithProfileName(profileName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserWithProfileName(profileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete user from table with uid.  **Args:** - uid (str): User UID.  **Returns:** - dict: Response.
         * @summary Delete User With Uid
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserWithUid(uid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserWithUid(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the profile name.  **Args:** - profile_name (str): New profile name.  **Returns:** - dict: Response.
         * @summary Get User State
         * @param {string} [userUid] 
         * @param {string} [studyName] 
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {boolean} [isLatest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserState(userUid?: string, studyName?: string, startTime?: string, endTime?: string, isLatest?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserStateOutput>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserState(userUid, studyName, startTime, endTime, isLatest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the user with UID.  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - UsernameRequest: Schema.
         * @summary Get User With Uid
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserWithUid(userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsernameRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserWithUid(userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the user with profile name.  **Args:** - profile_name (str): Profile name.  **Returns:** - UsernameRequest: Schema.
         * @summary Get User With Username
         * @param {string} profileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserWithUsername(profileName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsernameRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserWithUsername(profileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the profile name.  **Args:** - profile_name (str): New profile name.  **Returns:** - dict: Response.
         * @summary Update Profile Name
         * @param {string} profileName 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProfileName(profileName: string, userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProfileName(profileName, userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update User State
         * @param {UserState} userState 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserState(userState: UserState, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserStateOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserState(userState, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * Create a new user.  **Args:** - profile_name (str): Unique profile name. - pilot (Pilot, optional): Defaults to Pilot.YES.  **Raises:** - HTTPException: 404, profile name exists.  **Returns:** - UsernameRequest: Schema.
         * @summary Create User
         * @param {string} profileName 
         * @param {Pilot} [pilot] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(profileName: string, pilot?: Pilot, options?: any): AxiosPromise<UsernameRequest> {
            return localVarFp.createUser(profileName, pilot, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the user with profile name.  **Args:** - profile_name (str): Profile name.  **Returns:** - dict: Response.
         * @summary Delete User With Profile Name
         * @param {string} profileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserWithProfileName(profileName: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteUserWithProfileName(profileName, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete user from table with uid.  **Args:** - uid (str): User UID.  **Returns:** - dict: Response.
         * @summary Delete User With Uid
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserWithUid(uid: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteUserWithUid(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the profile name.  **Args:** - profile_name (str): New profile name.  **Returns:** - dict: Response.
         * @summary Get User State
         * @param {string} [userUid] 
         * @param {string} [studyName] 
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {boolean} [isLatest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserState(userUid?: string, studyName?: string, startTime?: string, endTime?: string, isLatest?: boolean, options?: any): AxiosPromise<Array<UserStateOutput>> {
            return localVarFp.getUserState(userUid, studyName, startTime, endTime, isLatest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the user with UID.  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - UsernameRequest: Schema.
         * @summary Get User With Uid
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserWithUid(userUid?: string, options?: any): AxiosPromise<UsernameRequest> {
            return localVarFp.getUserWithUid(userUid, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the user with profile name.  **Args:** - profile_name (str): Profile name.  **Returns:** - UsernameRequest: Schema.
         * @summary Get User With Username
         * @param {string} profileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserWithUsername(profileName: string, options?: any): AxiosPromise<UsernameRequest> {
            return localVarFp.getUserWithUsername(profileName, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the profile name.  **Args:** - profile_name (str): New profile name.  **Returns:** - dict: Response.
         * @summary Update Profile Name
         * @param {string} profileName 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfileName(profileName: string, userUid?: string, options?: any): AxiosPromise<object> {
            return localVarFp.updateProfileName(profileName, userUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update User State
         * @param {UserState} userState 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserState(userState: UserState, options?: any): AxiosPromise<UserStateOutput> {
            return localVarFp.updateUserState(userState, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * Create a new user.  **Args:** - profile_name (str): Unique profile name. - pilot (Pilot, optional): Defaults to Pilot.YES.  **Raises:** - HTTPException: 404, profile name exists.  **Returns:** - UsernameRequest: Schema.
     * @summary Create User
     * @param {string} profileName 
     * @param {Pilot} [pilot] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUser(profileName: string, pilot?: Pilot, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).createUser(profileName, pilot, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the user with profile name.  **Args:** - profile_name (str): Profile name.  **Returns:** - dict: Response.
     * @summary Delete User With Profile Name
     * @param {string} profileName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUserWithProfileName(profileName: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteUserWithProfileName(profileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete user from table with uid.  **Args:** - uid (str): User UID.  **Returns:** - dict: Response.
     * @summary Delete User With Uid
     * @param {string} uid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUserWithUid(uid: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteUserWithUid(uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the profile name.  **Args:** - profile_name (str): New profile name.  **Returns:** - dict: Response.
     * @summary Get User State
     * @param {string} [userUid] 
     * @param {string} [studyName] 
     * @param {string} [startTime] 
     * @param {string} [endTime] 
     * @param {boolean} [isLatest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserState(userUid?: string, studyName?: string, startTime?: string, endTime?: string, isLatest?: boolean, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserState(userUid, studyName, startTime, endTime, isLatest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the user with UID.  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - UsernameRequest: Schema.
     * @summary Get User With Uid
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserWithUid(userUid?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserWithUid(userUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the user with profile name.  **Args:** - profile_name (str): Profile name.  **Returns:** - UsernameRequest: Schema.
     * @summary Get User With Username
     * @param {string} profileName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserWithUsername(profileName: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserWithUsername(profileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the profile name.  **Args:** - profile_name (str): New profile name.  **Returns:** - dict: Response.
     * @summary Update Profile Name
     * @param {string} profileName 
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateProfileName(profileName: string, userUid?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateProfileName(profileName, userUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update User State
     * @param {UserState} userState 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserState(userState: UserState, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUserState(userState, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserGroupApi - axios parameter creator
 * @export
 */
export const UserGroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new user group.  **Args:** - group_name (str): Unique group name. - users (list[str]): List of user UID. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Raises:** - HTTPException: 409, user group name already exists.  **Returns:** - UserGroupRequest: Schema.
         * @summary Create User Group
         * @param {string} groupName 
         * @param {Array<string>} users 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserGroup: async (groupName: string, users: Array<string>, userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupName' is not null or undefined
            assertParamExists('createUserGroup', 'groupName', groupName)
            // verify required parameter 'users' is not null or undefined
            assertParamExists('createUserGroup', 'users', users)
            const localVarPath = `/api/user_group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (groupName !== undefined) {
                localVarQueryParameter['group_name'] = groupName;
            }

            if (users) {
                localVarQueryParameter['users'] = users;
            }

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete given user group with name.  **Args:** - group_name (str): Group name.  **Returns:** - dict: Response.
         * @summary Delete User Group With Name
         * @param {string} groupName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserGroupWithName: async (groupName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupName' is not null or undefined
            assertParamExists('deleteUserGroupWithName', 'groupName', groupName)
            const localVarPath = `/api/user_group/name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (groupName !== undefined) {
                localVarQueryParameter['group_name'] = groupName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the given user group.  **Args:** - uid (str): Group UID.  **Returns:** - dict: Response.
         * @summary Delete User Group With Uid
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserGroupWithUid: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('deleteUserGroupWithUid', 'uid', uid)
            const localVarPath = `/api/user_group/uid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all groups with given user.  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - list[str]: List of group names.
         * @summary Get All Groups With User
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllGroupsWithUser: async (userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user_group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get UserGroupRequest with group name.  **Args:** - group_name (str): group name. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - UserGroupRequest: Schema.
         * @summary Get User Group With Name
         * @param {string} groupName 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGroupWithName: async (groupName: string, userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupName' is not null or undefined
            assertParamExists('getUserGroupWithName', 'groupName', groupName)
            const localVarPath = `/api/user_group/name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (groupName !== undefined) {
                localVarQueryParameter['group_name'] = groupName;
            }

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a user group with UID.  **Args:** - uid (str): Group UID. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - UserGroupRequest: Schema.
         * @summary Get User Group With Uid
         * @param {string} uid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGroupWithUid: async (uid: string, userUid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('getUserGroupWithUid', 'uid', uid)
            const localVarPath = `/api/user_group/uid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (userUid !== undefined) {
                localVarQueryParameter['user_uid'] = userUid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserGroupApi - functional programming interface
 * @export
 */
export const UserGroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserGroupApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new user group.  **Args:** - group_name (str): Unique group name. - users (list[str]): List of user UID. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Raises:** - HTTPException: 409, user group name already exists.  **Returns:** - UserGroupRequest: Schema.
         * @summary Create User Group
         * @param {string} groupName 
         * @param {Array<string>} users 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserGroup(groupName: string, users: Array<string>, userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroupRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserGroup(groupName, users, userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete given user group with name.  **Args:** - group_name (str): Group name.  **Returns:** - dict: Response.
         * @summary Delete User Group With Name
         * @param {string} groupName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserGroupWithName(groupName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserGroupWithName(groupName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete the given user group.  **Args:** - uid (str): Group UID.  **Returns:** - dict: Response.
         * @summary Delete User Group With Uid
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserGroupWithUid(uid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserGroupWithUid(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all groups with given user.  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - list[str]: List of group names.
         * @summary Get All Groups With User
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllGroupsWithUser(userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllGroupsWithUser(userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get UserGroupRequest with group name.  **Args:** - group_name (str): group name. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - UserGroupRequest: Schema.
         * @summary Get User Group With Name
         * @param {string} groupName 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserGroupWithName(groupName: string, userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroupRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserGroupWithName(groupName, userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a user group with UID.  **Args:** - uid (str): Group UID. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - UserGroupRequest: Schema.
         * @summary Get User Group With Uid
         * @param {string} uid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserGroupWithUid(uid: string, userUid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGroupRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserGroupWithUid(uid, userUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserGroupApi - factory interface
 * @export
 */
export const UserGroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserGroupApiFp(configuration)
    return {
        /**
         * Create a new user group.  **Args:** - group_name (str): Unique group name. - users (list[str]): List of user UID. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Raises:** - HTTPException: 409, user group name already exists.  **Returns:** - UserGroupRequest: Schema.
         * @summary Create User Group
         * @param {string} groupName 
         * @param {Array<string>} users 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserGroup(groupName: string, users: Array<string>, userUid?: string, options?: any): AxiosPromise<UserGroupRequest> {
            return localVarFp.createUserGroup(groupName, users, userUid, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete given user group with name.  **Args:** - group_name (str): Group name.  **Returns:** - dict: Response.
         * @summary Delete User Group With Name
         * @param {string} groupName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserGroupWithName(groupName: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteUserGroupWithName(groupName, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the given user group.  **Args:** - uid (str): Group UID.  **Returns:** - dict: Response.
         * @summary Delete User Group With Uid
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserGroupWithUid(uid: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteUserGroupWithUid(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all groups with given user.  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - list[str]: List of group names.
         * @summary Get All Groups With User
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllGroupsWithUser(userUid?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getAllGroupsWithUser(userUid, options).then((request) => request(axios, basePath));
        },
        /**
         * Get UserGroupRequest with group name.  **Args:** - group_name (str): group name. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - UserGroupRequest: Schema.
         * @summary Get User Group With Name
         * @param {string} groupName 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGroupWithName(groupName: string, userUid?: string, options?: any): AxiosPromise<UserGroupRequest> {
            return localVarFp.getUserGroupWithName(groupName, userUid, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a user group with UID.  **Args:** - uid (str): Group UID. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - UserGroupRequest: Schema.
         * @summary Get User Group With Uid
         * @param {string} uid 
         * @param {string} [userUid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGroupWithUid(uid: string, userUid?: string, options?: any): AxiosPromise<UserGroupRequest> {
            return localVarFp.getUserGroupWithUid(uid, userUid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserGroupApi - object-oriented interface
 * @export
 * @class UserGroupApi
 * @extends {BaseAPI}
 */
export class UserGroupApi extends BaseAPI {
    /**
     * Create a new user group.  **Args:** - group_name (str): Unique group name. - users (list[str]): List of user UID. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Raises:** - HTTPException: 409, user group name already exists.  **Returns:** - UserGroupRequest: Schema.
     * @summary Create User Group
     * @param {string} groupName 
     * @param {Array<string>} users 
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public createUserGroup(groupName: string, users: Array<string>, userUid?: string, options?: AxiosRequestConfig) {
        return UserGroupApiFp(this.configuration).createUserGroup(groupName, users, userUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete given user group with name.  **Args:** - group_name (str): Group name.  **Returns:** - dict: Response.
     * @summary Delete User Group With Name
     * @param {string} groupName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public deleteUserGroupWithName(groupName: string, options?: AxiosRequestConfig) {
        return UserGroupApiFp(this.configuration).deleteUserGroupWithName(groupName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the given user group.  **Args:** - uid (str): Group UID.  **Returns:** - dict: Response.
     * @summary Delete User Group With Uid
     * @param {string} uid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public deleteUserGroupWithUid(uid: string, options?: AxiosRequestConfig) {
        return UserGroupApiFp(this.configuration).deleteUserGroupWithUid(uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all groups with given user.  **Args:** - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - list[str]: List of group names.
     * @summary Get All Groups With User
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public getAllGroupsWithUser(userUid?: string, options?: AxiosRequestConfig) {
        return UserGroupApiFp(this.configuration).getAllGroupsWithUser(userUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get UserGroupRequest with group name.  **Args:** - group_name (str): group name. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - UserGroupRequest: Schema.
     * @summary Get User Group With Name
     * @param {string} groupName 
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public getUserGroupWithName(groupName: string, userUid?: string, options?: AxiosRequestConfig) {
        return UserGroupApiFp(this.configuration).getUserGroupWithName(groupName, userUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a user group with UID.  **Args:** - uid (str): Group UID. - user_uid (str): Optional. Use if provided, else use JWT to extract uid.  **Returns:** - UserGroupRequest: Schema.
     * @summary Get User Group With Uid
     * @param {string} uid 
     * @param {string} [userUid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApi
     */
    public getUserGroupWithUid(uid: string, userUid?: string, options?: AxiosRequestConfig) {
        return UserGroupApiFp(this.configuration).getUserGroupWithUid(uid, userUid, options).then((request) => request(this.axios, this.basePath));
    }
}


