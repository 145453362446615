import {
    CaptureRequest,
    CaptureRequestIn,
    CaptureStatus,
    StudyInstruction,
    StudyRequest,
    StudyResponse
} from 'interface/api';
import { ImageModeration } from 'components/PromptCard';
import { api, getHeaderWithToken } from './api';
import imageCompression from 'browser-image-compression';

/// Full list of option: https://github.com/Donaldcwl/browser-image-compression
const compressedImageOption = {
    maxSizeMB: 1.5,
    maxWidthOrHeight: 1024,
    useWebWorker: true,
    alwaysKeepResolution: true
};

/// Hold capture id and its generated image ids.
/// This response is returned in these 2 cases:
/// 1/ Create a new capture, then generating images
/// 2/ Update a capture's prompts, then generating images
export interface CaptureAndGenerateIds {
    capture_uid: string;
    generate_uids: string[];
}

/// Parse response from server to CaptureAndGenerateIds format.
function parseToCreateCaptureResponse(response: any): CaptureAndGenerateIds {
    let result: CaptureAndGenerateIds = {
        capture_uid: '',
        generate_uids: []
    };

    for (const item of response.data) {
        if (item.capture_uid) {
            result.capture_uid = item.capture_uid;
        } else if (item.generate_uid) {
            result.generate_uids.push(item.generate_uid);
        }
    }
    return result;
}

export function getCreateCaptureRequest(
    study: StudyRequest,
    instructionId: string,
    answers: StudyResponse[],
    aiPrompt: string = ''
): CaptureRequestIn {
    const index = study.instructions.findIndex((item) => item.uid === instructionId);
    if (index === -1) {
        throw Error(`Unable to find instruction id: ${instructionId} in the study: ${study.study_name}`);
    }
    const instruction: StudyInstruction = study.instructions[index];
    return {
        title: instruction.title ?? '',
        caption: instruction.caption,
        location: { latitude: '', longitude: '' },
        anchors: [],
        study_name: study.study_name,
        study_response: answers,
        study_instruction_uid: instructionId,
        study_instruction_idx: index,
        prompt: aiPrompt ? aiPrompt : instruction.generative_ai_prompt ?? '',
        prompt_kwargs: {}
    };
}

export async function getCapture(userToken: string, captureId: string): Promise<CaptureRequest> {
    return new Promise((resolve, reject) => {
        api.get('/capture/uid', {
            params: {
                uid: captureId
            },
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        })
            .then((response) => {
                resolve({ ...response.data });
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function getAllCapturesFromUser(userToken: string): Promise<CaptureRequest[]> {
    return new Promise((resolve, reject) => {
        api.get('/capture', getHeaderWithToken(userToken))
            .then((response) => {
                if (response.status === 200) {
                    const result: CaptureRequest[] = response.data.map((item: CaptureRequest) => {
                        return { ...item };
                    });
                    result.sort((a, b) => {
                        if (b.timestamp && a.timestamp) {
                            return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
                        }
                        return 0;
                    });
                    resolve(result);
                } else {
                    reject(`${response.status}: ${response.statusText}`);
                }
            })
            .catch((error: any) => reject(error));
    });
}

/// Get all user's captures from a study.
export async function getCapturesForStudy(userToken: string, studyName: string): Promise<CaptureRequest[]> {
    return new Promise((resolve, reject) => {
        api.get('/capture/study_name', {
            params: {
                study_name: studyName
            },
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    const result: CaptureRequest[] = response.data.map((item: CaptureRequest) => {
                        return { ...item };
                    });
                    result.sort((a, b) => {
                        if (b.timestamp && a.timestamp) {
                            return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
                        }
                        return 0;
                    });
                    resolve(result);
                } else {
                    reject(`${response.status}: ${response.statusText}`);
                }
            })
            .catch((error: any) => reject(error));
    });
}

export async function createCapture(
    userToken: string,
    imageData: any,
    maskData: any,
    createCaptureRequest: CaptureRequestIn,
    compress: boolean = true // This is for playwright unit test. File object is not available in playwright. Set to False when running unit test.
): Promise<CaptureAndGenerateIds> {
    let formData = new FormData();
    if (compress) {
        const compressedImage = await imageCompression(imageData, compressedImageOption);
        const compressedMask = await imageCompression(maskData, compressedImageOption);
        formData.append('image', compressedImage);
        formData.append('mask', compressedMask);
    } else {
        formData.append('image', imageData);
        formData.append('mask', maskData);
    }

    if (process.env.REACT_APP_SKIP_AI_SCALING) {
        createCaptureRequest.ai_scaling_enabled = false;
    }

    formData.append('request', JSON.stringify(createCaptureRequest));
    const headers = {
        Authorization: `Bearer ${userToken}`,
        'Content-Type': 'multipart/form-data'
    };

    return new Promise((resolve, reject) => {
        api.post('/capture/create', formData, { headers })
            .then((response) => {
                const createCaptureResponse: CaptureAndGenerateIds = parseToCreateCaptureResponse(response);
                if (createCaptureResponse.capture_uid && createCaptureResponse.generate_uids.length > 0) {
                    resolve(createCaptureResponse);
                } else {
                    reject(
                        `Response is invalid. Either missing capture_uid or empty generate_uid. Response: ${response}`
                    );
                }
            })
            .catch((error) => {
                console.error(error);
                if (error.response) {
                    reject(`${error.response.status}: ${error.response.data.detail}`);
                } else {
                    reject(error);
                }
            });
    });
}

export function getNumberOfResponseForInstruction(
    userToken: string,
    studyName: string,
    instructionId: string
): Promise<number> {
    return new Promise((resolve, reject) => {
        api.get('/capture/number_responses', {
            params: {
                study_name: studyName,
                study_instruction_uid: instructionId
            },
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response.data.detail);
            });
    });
}

export async function addFavoriteGeneratedImage(
    userToken: string,
    captureId: string,
    generateId: string
): Promise<void> {
    return new Promise((resolve, reject) => {
        api.put(`/capture/favorites?uid=${captureId}&generate_uid=${generateId}`, {}, getHeaderWithToken(userToken))
            .then((response) => {
                if (response.status === 200) {
                    resolve();
                } else {
                    reject(`${response.status}: ${response.data}`);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    });
}

/// Put request to update the capture.
export async function updateCapture(
    userToken: string,
    captureId: string,
    captureRequest: CaptureRequestIn,
    maskData: any
): Promise<CaptureAndGenerateIds> {
    const compressedMask = await imageCompression(maskData, compressedImageOption);
    let formData = new FormData();
    formData.append('mask', compressedMask);

    formData.append('request', JSON.stringify(captureRequest));

    const headers = {
        Authorization: `Bearer ${userToken}`,
        'Content-Type': 'multipart/form-data'
    };

    return new Promise((resolve, reject) => {
        api.put(`/capture/uid?uid=${captureId}`, formData, { headers })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject(`${response.status}: ${response.statusText}`);
                }
            })
            .catch((e) => reject(e));
    });
}

/// Put request to update the capture with no mask as the api will use the study default.
export async function updateCaptureNoMask(
    userToken: string,
    captureId: string,
    captureRequest: CaptureRequestIn
): Promise<CaptureAndGenerateIds> {
    let formData = new FormData();
    formData.append('request', JSON.stringify(captureRequest));

    const headers = {
        Authorization: `Bearer ${userToken}`,
        'Content-Type': 'multipart/form-data'
    };

    return new Promise((resolve, reject) => {
        api.put(`/capture/uid?uid=${captureId}`, formData, { headers })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject(`${response.status}: ${response.statusText}`);
                }
            })
            .catch((e) => reject(e));
    });
}

/// Call post request to generate images for the capture id.
/// Return an object that contains the capture id and associated generated image ids.
export async function generateMediaForCapture(userToken: string, captureId: string): Promise<CaptureAndGenerateIds> {
    return new Promise((resolve, reject) => {
        const headers = {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        };
        api.post('/capture/generate', { capture_uid: captureId }, { headers: headers })
            .then((response) => {
                if (response.status === 200) {
                    const result: CaptureAndGenerateIds = parseToCreateCaptureResponse(response);
                    resolve(result);
                } else {
                    reject(`${response.status}: ${response.statusText}`);
                }
            })
            .catch((e) => reject(e));
    });
}

/// Resubmit prompt and/or mask data for provided capture id.
/// This call includes 2 api calls:
/// 1/ Call a put request to update prompt string and prompt mask for the capture.
/// 2/ Call a post request to generate images for the capture id.
export async function resubmitCaptureWithNewPrompt(
    userToken: string,
    captureId: string,
    prompt: string,
    maskData: File | undefined
): Promise<CaptureAndGenerateIds> {
    if (maskData) {
        return new Promise((resolve, reject) => {
            const request: CaptureRequestIn = { prompt: prompt };
            updateCapture(userToken, captureId, request, maskData)
                .then((_response) => {
                    return generateMediaForCapture(userToken, captureId);
                })
                .then((result) => resolve(result))
                .catch((e) => reject(e));
        });
    } else {
        return new Promise((resolve, reject) => {
            const request: CaptureRequestIn = { prompt: prompt };
            updateCaptureNoMask(userToken, captureId, request)
                .then((_response) => {
                    return generateMediaForCapture(userToken, captureId);
                })
                .then((result) => resolve(result))
                .catch((e) => reject(e));
        });
    }
}

//Will update the status for a capture dependent on where the user is at in the journey
export async function updateCaptureStatus(
    userToken: string,
    captureId: string,
    captureStatus: CaptureStatus
): Promise<CaptureRequest> {
    return new Promise((resolve, reject) => {
        api.put(`/capture/status?uid=${captureId}&status=${captureStatus}`, {}, getHeaderWithToken(userToken))
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject(`${response.status}: ${response.data}`);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    });
}

//Getting a capture that is not yours
export async function getCaptureForFeedback(
    userToken: string,
    captureId: string,
    userUID: string
): Promise<CaptureRequest> {
    return new Promise((resolve, reject) => {
        api.get('/capture/uid', {
            params: {
                uid: captureId,
                user_uid: userUID
            },
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        })
            .then((response) => {
                resolve({ ...response.data });
            })
            .catch((error) => {
                reject(error);
            });
    });
}

//image moderation
export async function checkCapture(userToken: string, imageData: any): Promise<ImageModeration> {
    //setup image
    let formData = new FormData();
    formData.append('image', imageData);
    //setup headers
    const headers = {
        Authorization: `Bearer ${userToken}`,
        'Content-Type': 'multipart/form-data',
        accept: 'application/json'
    };

    //make Promise
    return new Promise((resolve, reject) => {
        api.post('/capture/moderate', formData, { headers })
            .then((response) => {
                console.log('moderation status', response);
                resolve({
                    flagged: response.data.flagged,
                    categories: response.data.categories,
                    description: response.data.descriptions,
                    reasoning: response.data.reasoning
                });
            })
            .catch((error) => {
                console.error(error);
                if (error.response) {
                    reject(`${error.response.status}: ${error.response.data.detail}`);
                } else {
                    reject(error);
                }
            });
    });
}

export async function createCaptureNoMask(
    userToken: string,
    imageData: any,
    createCaptureRequest: CaptureRequestIn,
    compress: boolean = true // This is for playwright unit test. File object is not available in playwright. Set to False when running unit test.
): Promise<CaptureAndGenerateIds> {
    let formData = new FormData();
    if (compress) {
        const compressedImage = await imageCompression(imageData, compressedImageOption);
        formData.append('image', compressedImage);
    } else {
        formData.append('image', imageData);
    }

    if (process.env.REACT_APP_SKIP_AI_SCALING) {
        createCaptureRequest.ai_scaling_enabled = false;
    }

    formData.append('request', JSON.stringify(createCaptureRequest));
    const headers = {
        Authorization: `Bearer ${userToken}`,
        'Content-Type': 'multipart/form-data'
    };

    return new Promise((resolve, reject) => {
        api.post('/capture/create', formData, { headers })
            .then((response) => {
                const createCaptureResponse: CaptureAndGenerateIds = parseToCreateCaptureResponse(response);
                if (createCaptureResponse.capture_uid && createCaptureResponse.generate_uids.length > 0) {
                    resolve(createCaptureResponse);
                } else {
                    reject(
                        `Response is invalid. Either missing capture_uid or empty generate_uid. Response: ${response}`
                    );
                }
            })
            .catch((error) => {
                console.error(error);
                if (error.response) {
                    reject(`${error.response.status}: ${error.response.data.detail}`);
                } else {
                    reject(error);
                }
            });
    });
}
