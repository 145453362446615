import { AlertColor } from '@mui/material';

export interface UserProfile {
    profile_name: string;
    community: string;
}

export interface SnackBarInfo {
    msg: string;
    severity_type: AlertColor; // error, warning, info, success
    autoHideDuration?: number; // ms. Put null to not hide.
    action?: {
        // additional action for the snackbar
        action_name: string;
        action_callback: () => void;
    };
}

export enum TabType {
    Activities = 'activities',
    Feedback = 'feedback',
    Gallery = 'gallery'
}

export enum FeedbackTabType {
    Designs = 'designs',
    History = 'history'
}
