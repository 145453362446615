export function isValid(value: string | undefined | null) {
    return value !== undefined && value !== null && value !== '';
}

export function isAndroid() {
    const userAgent = navigator.userAgent;
    return userAgent.includes('Android');
}

//Check to see if the string has bold tags
export function checkString(studyInfo: string): Boolean {
    if (studyInfo.includes('<b>') || studyInfo.includes('</b>')) {
        return true;
    } else return false;
}

//If string has bold tags, we can remove them with this function.
export function cleanString(studyInfo: string): string {
    //remove all <b> instances in the string
    let count = (studyInfo.match(/<b>/g) || []).length;
    if (count > 0) {
        for (let x = 0; x < count; x++) studyInfo = studyInfo.replace('<b>', '');
    }

    // remove all </b> instances in the string
    count = (studyInfo.match(/<\/b>/g) || []).length;
    if (count > 0) {
        for (let x = 0; x < count; x++) studyInfo = studyInfo.replace('</b>', '');
    }

    return studyInfo;
}

//This utility function splits out a string of characters
//to bold the "caption focus word".
export function studySplit(studyInfo: string): String[] {
    if (checkString(studyInfo)) {
        studyInfo = cleanString(studyInfo);
    }
    let study = studyInfo.split('feel');
    let firstPart = study[0] + 'feel';
    let secondPart = study[1];
    return [firstPart, secondPart];
}

//This utility function splits out a string of characters
//to bold the "caption focus word".
export function studySplitDots(studyInfo: string): String[] {
    if (checkString(studyInfo)) {
        studyInfo = cleanString(studyInfo);
    }
    let study = studyInfo.split('feel');
    let firstPart = study[0] + 'feel...';
    let secondPart = study[1];
    return [firstPart, secondPart];
}

//This utility function splits out the focused word
//after a clean up if needed and capitalizes it
export function capitalizedCaption(studyInfo: string): string {
    if (checkString(studyInfo)) {
        studyInfo = cleanString(studyInfo);
    }
    if (studyInfo.includes('.')) {
        studyInfo = studyInfo.replace('.', '');
    }
    let study = studyInfo.split('feel ');
    let caption = study[1].charAt(0).toUpperCase() + study[1].slice(1);
    return caption;
}

export interface TimeElapsed {
    elapsedMinutes: number;
    hasTenMinutesElapsed: boolean;
}
//Checks array's elapsed time
export function elapsedTime(creationTimes: string[]): TimeElapsed {
    let currentDate = new Date();
    let tenMinutesElapsed = false;
    let elapsedMinutes = 0;

    for (let i = 0; i < creationTimes.length; i++) {
        let creationDate = new Date(creationTimes[i]);
        elapsedMinutes = (currentDate.getTime() - creationDate.getTime()) / 1000 / 60;
        if (elapsedMinutes >= 10 && !tenMinutesElapsed) {
            tenMinutesElapsed = true;
            break;
        }
    }

    return { elapsedMinutes: elapsedMinutes, hasTenMinutesElapsed: tenMinutesElapsed };
}
