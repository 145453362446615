import HomeIcon from '@mui/icons-material/Home';
import { AccountCircle } from '@mui/icons-material';
import { AppBar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATHS_WITHOUT_TOP_MENU, RoutePath } from 'AppRoutes';
import { useSetAtom } from 'jotai';
import { feedbackAtom, feedbackPageTab, resetFeedback, landingPageTab } from 'global/Atom';
import { FeedbackTabType, TabType } from '../global/AtomData';

export interface MenuAppBarProp {
    showProfile: boolean;
}

export default function MenuAppBar(prop: MenuAppBarProp) {
    const navigate = useNavigate();
    const location = useLocation();
    const [showMenu, setShowMenu] = useState(false);
    const setTabValue = useSetAtom(feedbackPageTab);
    const setFeedbackData = useSetAtom(feedbackAtom);
    const setLandingTabValue = useSetAtom(landingPageTab);

    function goToProfilePage() {
        if (location.pathname == RoutePath.Profile) {
            return;
        }
        navigate(RoutePath.Profile);
    }

    function goToLandingPage() {
        resetFeedback(setFeedbackData);
        setTabValue(FeedbackTabType.Designs);
        navigate(RoutePath.Home);
        setLandingTabValue(TabType.Activities);
    }

    useEffect(() => {
        let foundPath = false;
        for (const item of PATHS_WITHOUT_TOP_MENU) {
            if (item === location.pathname) {
                foundPath = true;
                break;
            }
        }
        setShowMenu(!foundPath);
    }, [location]);

    return showMenu ? (
        <AppBar elevation={4} square position="static">
            <Toolbar variant="regular">
                <IconButton color="inherit" onClick={goToLandingPage}>
                    <HomeIcon />
                </IconButton>
                <Typography variant="h6" sx={{ flexGrow: 1 }}>
                    Envision
                </Typography>
                {prop.showProfile && (
                    <IconButton color="inherit" onClick={goToProfilePage}>
                        <AccountCircle fontSize="large" />
                    </IconButton>
                )}
            </Toolbar>
        </AppBar>
    ) : (
        <></>
    );
}
