import { UsernameRequest } from 'interface';
import { api, getHeaderWithToken } from './api';

export function createUser(userToken: string, userName: string): Promise<UsernameRequest> {
    // API on the server need to change so it will accept the payload instead of embedded payload to the url.
    return new Promise((resolve, reject) => {
        api.post(`/user?profile_name=${userName}&pilot=YES`, {}, getHeaderWithToken(userToken))
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function getProfileName(userToken: string): Promise<string> {
    return new Promise((resolve, reject) => {
        api.get('/user/uid', getHeaderWithToken(userToken))
            .then((response) => {
                if (response.status === 200) {
                    //Only return profile name here.
                    resolve(response.data.profile_name);
                } else {
                    reject(new Error(`${response.status}: ${response.statusText}`));
                }
            })
            .catch((error: any) => reject(error));
    });
}

export function getUser(userToken: string): Promise<UsernameRequest> {
    return new Promise((resolve, reject) => {
        api.get('/user/uid', getHeaderWithToken(userToken))
            .then((response) => {
                resolve({ ...response.data });
            })
            .catch((error: any) => reject(error));
    });
}

export function updateProfileName(userToken: string, newProfileName: string): Promise<string> {
    return new Promise((resolve, reject) => {
        api.put(`/user/username?profile_name=${newProfileName}`, {}, getHeaderWithToken(userToken))
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data.profile_name);
                } else {
                    reject(`${response.status}: ${response.data}`);
                }
            })
            .catch((error: any) => {
                reject(`${error.response.status}: ${error.response.data.detail}`);
            });
    });
}

export function deleteUser(userToken: string, userIdToDelete: string): Promise<number> {
    return new Promise((resolve, reject) => {
        api.delete(`/user/uid?uid=${userIdToDelete}`, {
            headers: { Authorization: `Bearer ${userToken}` }
        })
            .then((response) => resolve(response.status))
            .catch((error) => reject(error));
    });
}

//This function allows a USER UID to be provided and uses it as a parameter
export function getProfileNameWithUID(userToken: string, userUID: string): Promise<UsernameRequest> {
    return new Promise((resolve, reject) => {
        api.get('/user/uid', {
            params: { user_uid: userUID },
            headers: { Authorization: `Bearer ${userToken}` }
        })
            .then((response) => {
                if (response.status === 200) {
                    //Return all profile information
                    resolve(response.data);
                } else {
                    reject(new Error(`${response.status}: ${response.statusText}`));
                }
            })
            .catch((error: any) => reject(error));
    });
}
