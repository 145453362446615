/// The file contains functions and variables for the route configuration.

import { getCurrentStudyAndProfileName } from 'api/StudyUserApi';
import { redirect } from 'react-router-dom';
import { lazy } from 'react';
import { getUserToken, isAdmin, isFirstLaunch } from './AppAuth';
import Root from 'Root';
import GenericErrorPage from 'pages/GenericErrorPage';

const LandingPage = lazy(() => import('./pages/LandingPages/LandingPage'));
const DescribePlacePage = lazy(() => import('./pages/DescribePlacePage'));
const ProfilePage = lazy(() => import('./pages/ProfilePage'));
const CreateUserIdPage = lazy(() => import('./pages/CreateUserIdPage'));
const ChangeCommunityPage = lazy(() => import('./pages/ChangeCommunityPage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const AdminPage = lazy(() => import('./admin/AdminApp'));
const EditStudyPage = lazy(() => import('./admin/EditStudyPage'));
const CreateStudyPage = lazy(() => import('./admin/CreateStudyPage'));
const MappingPage = lazy(() => import('./admin/MappingPage'));
const StudyUsersPage = lazy(() => import('./admin/StudyUsersTable'));
const NextStepPage = lazy(() => import('./pages/NextStepPage'));
const WelcomePage = lazy(() => import('./pages/WelcomePage'));
const AIPromptPage = lazy(() => import('./pages/AIPromptPages/AIPromptPage'));
const AISeguePage = lazy(() => import('./pages/AIPromptPages/AISeguePage'));
const AIDesignPage = lazy(() => import('./pages/AIPromptPages/AIDesignPage'));
const OriginalGalleryItemPage = lazy(() => import('./pages/Gallery/OriginalGalleryItemPage'));
const MyDesignItemPage = lazy(() => import('./pages/Gallery/MyDesignItemPage'));
const StoriesCaptionPage = lazy(() => import('./pages/Stories/StoriesCaptionPage'));
const StoriesSeguePage = lazy(() => import('./pages/Stories/StoriesSeguePage'));
const StoriesJourneyPage = lazy(() => import('./pages/Stories/StoriesJourneyPage'));
const StoriesLoadingPage = lazy(() => import('./pages/Stories/StoriesLoadingPage'));
const ExploreARSeguePage = lazy(() => import('./pages/ARPages/ExploreARSeguePage'));
const ExploreARDescriptionPage = lazy(() => import('./pages/ARPages/ExploreARDescriptionPage'));
const GiveFeedbackPage = lazy(() => import('./pages/Feedback/GiveFeedbackPage'));
const GotFeedbackPage = lazy(() => import('./pages/Feedback/GotFeedbackPage'));
const OriginalCaptureViewPage = lazy(() => import('./pages/OriginalCaptureViewPage'));

export const ROOT_PAGE_ID = 'root';
export const HOME_PAGE_ID = 'home';

export const enum RoutePath {
    Home = '/',
    Login = '/login',
    CreateUserProfile = '/create-user-profile',
    Welcome = '/welcome',
    ChangeCommunity = '/change-community',
    DescribePlace = '/describe-place',
    Profile = '/profile',
    Admin = '/admin',
    CreateStudy = '/create-study',
    Users = '/users',
    Mapping = '/mapping',
    NextStep = '/next-step',
    AISegue = '/ai-segue',
    AIPrompt = '/ai-prompt',
    AIDesign = '/ai-design',
    MyDesignItem = '/my-designs',
    MyOriginal = '/gallery-original',
    StoriesCaption = '/stories-caption',
    StoriesSegue = '/stories-segue',
    StoriesJourney = '/stories-journey',
    StoriesLoading = '/stories-loading',
    ExploreARSegue = '/explore-ar-segue',
    ExploreARCapture = '/explore-ar-capture',
    GivingFeedback = '/giving-feedback',
    GotFeedback = '/reviewing-feedback',
    OriginalCapture = '/original-capture'
}
export const PATHS_WITHOUT_TOP_MENU = [
    RoutePath.Welcome,
    RoutePath.NextStep,
    RoutePath.AISegue,
    RoutePath.CreateUserProfile,
    RoutePath.ChangeCommunity,
    RoutePath.ExploreARSegue
];

async function checkSignIn() {
    const userToken = await getUserToken();
    if (userToken === null) {
        console.log('Redirect login from sign in');
        return redirect(RoutePath.Login);
    }
    // This value is available for children routes
    // Access them either by:
    // - https://reactrouter.com/en/main/hooks/use-route-loader-data
    // - https://reactrouter.com/en/main/hooks/use-loader-data
    return userToken;
}

async function redirectHomePage() {
    const userToken = await getUserToken();
    if (userToken) {
        try {
            const userProfile = await getCurrentStudyAndProfileName(userToken);
            if (userProfile.community === '' && userProfile.profile_name !== '') {
                return redirect(RoutePath.ChangeCommunity);
            }
            if (isFirstLaunch()) {
                return redirect(RoutePath.Welcome);
            }
            // These values are available for children routes
            // Access them either by:
            // - https://reactrouter.com/en/main/hooks/use-route-loader-data
            // - https://reactrouter.com/en/main/hooks/use-loader-data
            return { userToken, userProfile };
        } catch (e) {
            return redirect(RoutePath.CreateUserProfile);
        }
    }
    console.log('Redirect login from home page');
    return redirect(RoutePath.Login);
}

export const routeConfig = [
    {
        path: '/',
        element: <Root />,
        loader: checkSignIn,
        id: ROOT_PAGE_ID,
        errorElement: <GenericErrorPage />,
        children: [
            {
                path: RoutePath.Home,
                id: HOME_PAGE_ID,
                loader: redirectHomePage,
                children: [
                    {
                        index: true, // Render LandingPage by default if no sub-path is provided.
                        element: <LandingPage />
                    },
                    {
                        path: RoutePath.Profile,
                        element: <ProfilePage />
                    },
                    {
                        path: RoutePath.DescribePlace,
                        element: <DescribePlacePage />
                    },
                    {
                        path: RoutePath.NextStep,
                        element: <NextStepPage />
                    },
                    {
                        path: `${RoutePath.AISegue}/:captureId?`,
                        element: <AISeguePage />
                    },
                    {
                        path: `${RoutePath.AIDesign}/:captureId?`,
                        element: <AIDesignPage />
                    },
                    {
                        path: `${RoutePath.AIPrompt}/:captureIdParam?`,
                        element: <AIPromptPage />
                    },
                    {
                        path: `${RoutePath.MyOriginal}/:captureId`,
                        element: <OriginalGalleryItemPage />
                    },
                    {
                        path: `${RoutePath.MyDesignItem}/:captureId`,
                        element: <MyDesignItemPage />
                    },
                    {
                        path: `${RoutePath.StoriesCaption}/:captureId?`,
                        element: <StoriesCaptionPage />
                    },
                    {
                        path: RoutePath.StoriesSegue,
                        element: <StoriesSeguePage />
                    },
                    {
                        path: `${RoutePath.StoriesJourney}/:captureId?`,
                        element: <StoriesJourneyPage />
                    },
                    {
                        path: `${RoutePath.StoriesLoading}/:captureId?`,
                        element: <StoriesLoadingPage />
                    },
                    {
                        path: `${RoutePath.ExploreARCapture}/:captureId?`,
                        element: <ExploreARDescriptionPage />
                    },
                    {
                        path: `${RoutePath.GivingFeedback}/:generateId?`,
                        element: <GiveFeedbackPage />
                    },
                    {
                        path: `${RoutePath.GotFeedback}/:generateId?`,
                        element: <GotFeedbackPage />
                    },
                    {
                        path: RoutePath.OriginalCapture,
                        element: <OriginalCaptureViewPage />
                    }
                ]
            },
            {
                path: RoutePath.Welcome,
                element: <WelcomePage />
                //TODO (hieu.nguyen): check if need to redirect to RoutePath.Home
                //when access this route directly and this is not the first launch.
            },
            {
                path: RoutePath.CreateUserProfile,
                element: <CreateUserIdPage />
                //TODO (hieu.nguyen): check if need to redirect to RoutePath.Home
                //when access this route directly and the user already created an account.
            },
            {
                path: RoutePath.ChangeCommunity,
                element: <ChangeCommunityPage />
                //TODO (hieu.nguyen): check if need to redirect to RoutePath.Home
                //when access this route directly and the study is not null or still valid.
            },
            {
                path: `${RoutePath.ExploreARSegue}/:captureId?`,
                element: <ExploreARSeguePage />
                //TODO (hieu.nguyen): check if need to redirect to RoutePath.Home
                //when access this route directly and the study is not null or still valid.
            },
            {
                path: RoutePath.Admin,
                loader: async () => {
                    const admin = await isAdmin();
                    if (!admin) {
                        throw Error("Sorry! You can't access this page.");
                    }
                    return null;
                },
                children: [
                    {
                        index: true, // Render LandingPage by default if no sub-path is provided.
                        element: <AdminPage />
                    },
                    {
                        path: `:studyName?`,
                        children: [
                            {
                                index: true,
                                element: <EditStudyPage />
                            },
                            {
                                path: `:studyName?${RoutePath.Users}`,
                                element: <StudyUsersPage />
                            },
                            {
                                path: `:studyName?${RoutePath.Mapping}`,
                                element: <MappingPage />
                            }
                        ]
                    },
                    {
                        path: `${RoutePath.Admin}${RoutePath.CreateStudy}`,
                        element: <CreateStudyPage />
                    }
                ],
                errorElement: <GenericErrorPage />
            }
        ]
    },
    {
        path: RoutePath.Login,
        loader: async () => {
            console.log('Login loader');
            return null;
        },
        element: <LoginPage />
    }
];
