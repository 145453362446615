/// The file contains functions and variables for user permission.

import { Auth } from 'aws-amplify';

const ADMIN_GROUP_NAME = 'admin';
const FIRST_LAUNCH_KEY = 'first-launch';

export async function getUserToken(): Promise<string | null> {
    try {
        const session = await Auth.currentSession();
        return session.getIdToken().getJwtToken();
    } catch (e) {
        console.error(e);
        return null;
    }
}

export async function isAdmin(): Promise<boolean> {
    try {
        const session = await Auth.currentSession();
        const groups = session.getAccessToken().decodePayload()['cognito:groups'];
        return groups.some((item: string) => item.toLowerCase() === ADMIN_GROUP_NAME);
    } catch (e) {
        console.error(e);
        return false;
    }
}

export function setFirstLaunch() {
    localStorage.setItem(FIRST_LAUNCH_KEY, 'launched' /*This can be anything, just need a value*/);
}

export function isFirstLaunch() {
    return localStorage.getItem(FIRST_LAUNCH_KEY) === null;
}
