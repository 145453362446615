import { createTheme } from '@mui/material';

const appTheme = createTheme({
    typography: {
        h1: {
            fontSize: '24px',
            '@media (min-width:400px)': {
                fontSize: '33px'
            }
        },
        h2: {
            fontSize: '24px',
            '@media (min-width:400px)': {
                fontSize: '33px'
            }
        },
        h3: {
            fontSize: '24px',
            '@media (min-width:400px)': {
                fontSize: '26px'
            }
        },
        h5: {
            fontSize: '24px',
            '@media (min-width:400px)': {
                fontSize: '26px'
            }
        },
        body1: {
            fontSize: '16px',
            '@media (min-width:400px)': {
                fontSize: '22px'
            }
        },
        body2: {
            color: 'rgba(0,0,0,0.6)',
            fontSize: '16px',
            '@media (min-width:400px)': {
                fontSize: '22px'
            }
        },
        subtitle1: {
            fontWeight: 'bold',
            fontSize: '16px',
            '@media (min-width:400px)': {
                fontSize: '24px'
            }
        },
        subtitle2: {
            color: 'rgba(0,0,0,0.6)',
            fontSize: '14px',
            '@media (min-width:400px)': {
                fontSize: '24px'
            }
        },
        caption: {
            fontSize: '12px',
            '@media (min-width:400px)': {
                fontSize: '16px'
            }
        }
    },
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    // Apply your global styles for MuiTableBody here
                    fontSize: '0.5rem',
                    padding: '10px 8px'
                }
            }
        }
    }
});

export default appTheme;
