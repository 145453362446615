/// Contains types and functions for Gallery pages.

import { getCaptureForFeedback, getCapturesForStudy } from 'api/CaptureApi';
import { getStudiesForUser } from 'api/StudyUserApi';
import { getFeedbackByUserUID, getFeedbackByReceiverUID } from 'api/FeedbackApi';
import { RoutePath } from 'AppRoutes';
import { CaptureRequest, Anchor, Feedback, StudyRequest } from 'interface';
import { MapOrEntries } from 'usehooks-ts';
import { capitalizedCaption } from 'utils/utils';
import { getProfileNameWithUID } from 'api/UserApi';

export enum GalleryType {
    MyDesigns = 'designs',
    Originals = 'originals',
    MadeForMe = 'made-for-me'
}

export enum GalleryItemStatus {
    Unknown,
    MyOriginals,
    MyRedesigns,
    OthersRedesigns
}

export interface GalleryItem {
    captureId: string;
    imageUrl: string;
    user_uid: string;
    title: string;
    desc: string;
    favorites: Anchor[];
    galleryType: GalleryType;
    status: GalleryItemStatus;
}

//INITIALIZE GALLERY DATA
export const initGalleryData: MapOrEntries<GalleryType, GalleryItem[]> = [
    [GalleryType.MyDesigns, []],
    [GalleryType.Originals, []],
    [GalleryType.MadeForMe, []]
];

export function getRouteForGalleryItem(captureId: string, status: GalleryItemStatus): string {
    switch (status) {
        case GalleryItemStatus.MyOriginals:
            return `${RoutePath.MyOriginal}/${captureId}`;
        case GalleryItemStatus.MyRedesigns:
            return `${RoutePath.AIDesign}/${captureId}`;
        default:
            console.error(`Not implemented status: ${status}. captureId: ${captureId}`);
            return RoutePath.Home;
    }
}

export function fillGalleryData(
    studyInfo: StudyRequest,
    captures: CaptureRequest[],
    myFeedbackDesigns: Feedback[],
    theirCaptures: CaptureRequest[],
    uidToProfileNameMap: { [key: string]: string }
): Map<GalleryType, GalleryItem[]> {
    //HANDLE SELF DESIGNS
    let myDesigns: GalleryItem[] = [];
    let originals: GalleryItem[] = [];

    for (const capture of captures) {
        if (!capture.image_url) {
            /// Skip capture doesn't have image url
            continue;
        }
        //initialize gallery item
        const caption = capitalizedCaption(capture.caption!);
        let item: GalleryItem = {
            captureId: capture.uid ?? '',
            imageUrl: '',
            title: caption ?? '',
            desc: capture.prompt ?? '',
            user_uid: capture.user_uid ?? '',
            favorites: [],
            galleryType: GalleryType.Originals,
            status: GalleryItemStatus.Unknown
        };
        //Add original capture to the originals tab as this will always be present
        item.imageUrl = capture.thumb_url ? capture.thumb_url : capture.image_url ?? '';
        item.status = GalleryItemStatus.MyOriginals;
        originals.push({ ...item });

        //Check if the capture is a redesign AND if it has feedback
        //We then append to myDesigns (capture favorites have the image info)
        if (
            capture.favorites &&
            capture.favorites.length > 0 &&
            myFeedbackDesigns.some((feedbackDesign: Feedback) => feedbackDesign.parent_capture_uid === capture.uid)
        ) {
            item.status = GalleryItemStatus.MyRedesigns;
            myDesigns.push({ ...item });
            let index = myDesigns.length - 1;
            for (const favorite of capture.favorites) {
                /// Assign favorite generated data.
                myDesigns[index].favorites.push(favorite);
            }
        }
    }
    //HANDLE OTHERS DESIGNS
    let othersDesigns: GalleryItem[] = [];
    if (studyInfo?.features?.provide_feedback) {
        for (const capture of theirCaptures) {
            let item: GalleryItem = {
                captureId: capture.uid ?? '',
                imageUrl: '',
                title: capture.uid ? uidToProfileNameMap[capture.user_uid] : '',
                desc: capture.prompt ?? '',
                user_uid: capture.user_uid ?? '',
                favorites: capture.favorites ?? [],
                galleryType: GalleryType.MadeForMe,
                status: GalleryItemStatus.OthersRedesigns
            };
            othersDesigns.push({ ...item });
        }
    }
    //TODO!!!!
    //remove this when fixing for My Designs Gallery
    if (studyInfo?.features?.provide_feedback === false) {
        myDesigns = [];
    }

    return new Map<GalleryType, GalleryItem[]>([
        [GalleryType.MyDesigns, myDesigns],
        [GalleryType.Originals, originals],
        [GalleryType.MadeForMe, othersDesigns]
    ]);
}

/// Fetch all captures and filter out data for gallery.
export async function getGalleryData(
    userToken: string,
    userUID: string,
    studyInfo: StudyRequest
): Promise<Map<GalleryType, GalleryItem[]>> {
    try {
        const studies = await getStudiesForUser(userToken);
        if (studies.length == 0) {
            throw Error('There is no study associated with this user');
        }

        //ONLY IF FEEDBACK IS ENABLED GET "THEIR DESIGNS"
        if (studyInfo?.features?.provide_feedback) {
            const [captures, myDesigns, theirDesigns] = await Promise.all([
                getCapturesForStudy(userToken, studies[0]),
                getFeedbackByUserUID(userToken, userUID),
                getFeedbackByReceiverUID(userToken, userUID)
            ]);
            if (theirDesigns.length > 0) {
                //Get Designers' Captures
                const designersCaptures = theirDesigns.map((design: Feedback) => {
                    return { capture_uid: design.parent_capture_uid, user_uid: design.user_uid };
                });
                const uniqueCaptures = designersCaptures.reduce<{ capture_uid: string; user_uid: string }[]>(
                    (unique, item) => {
                        return unique.find(
                            (obj) => obj.capture_uid === item.capture_uid && obj.user_uid === item.user_uid
                        )
                            ? unique
                            : [...unique, item];
                    },
                    []
                );
                const apiCalls = uniqueCaptures.map((item) =>
                    getCaptureForFeedback(userToken, item.capture_uid, item.user_uid)
                );
                //Get Designers Names
                const usersUids = theirDesigns.map((design: Feedback) => {
                    return design.user_uid;
                });
                const uniqueDesignerUids = Array.from(new Set(usersUids));
                const profilesPromise = uniqueDesignerUids.map((user_uid) =>
                    getProfileNameWithUID(userToken, user_uid)
                );
                //Make the designer api calls
                const [theirCaptures, profilesResponse] = await Promise.all([
                    Promise.all(apiCalls),
                    Promise.all(profilesPromise)
                ]);
                const uidToProfileNameMap = profilesResponse.reduce((map: { [key: string]: string }, response) => {
                    map[response.uid] = response.profile_name;
                    return map;
                }, {});
                const galleryData = fillGalleryData(studyInfo, captures, myDesigns, theirCaptures, uidToProfileNameMap);
                return await Promise.resolve(galleryData);
            } else {
                const galleryData = fillGalleryData(studyInfo, captures, myDesigns, [], {});
                return await Promise.resolve(galleryData);
            }
        } else {
            const [captures, myDesigns] = await Promise.all([
                getCapturesForStudy(userToken, studies[0]),
                getFeedbackByUserUID(userToken, userUID)
            ]);
            const galleryData = fillGalleryData(studyInfo, captures, myDesigns, [], {});
            return await Promise.resolve(galleryData);
        }
    } catch (e) {
        return await Promise.reject(e);
    }
}
