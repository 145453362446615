import axios from 'axios';

export const api = axios.create({
    // Quick hack to set baseUrl when run with playwright.
    // Playwright proxy doesn't work.

    baseURL: process.env.RUN_PLAYWRIGHT
        ? process.env.base_url
            ? process.env.base_url
            : 'http://kubernetes.docker.internal:8000/api'
        : '/api',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
});

// throw the custom error message by fastapi
export const fastAPIError = (e: any) => {
    if (e?.response?.data?.message) {
        throw { ...e, message: e.response.data.message };
    }
    throw e;
};

export function getHeaderWithToken(userToken: string) {
    return { headers: { Authorization: `Bearer ${userToken}` } };
}
