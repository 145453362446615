import { StudyRequest, StudyResponse, CaptureRequest, UsernameRequest } from 'interface/api';
import { atom } from 'jotai';
import { SnackBarInfo, TabType, FeedbackTabType } from './AtomData';
import { FeedbackItem } from 'pages/Feedback/FeedbackInterfaces';
import { GalleryType } from 'pages/Gallery/GalleryInterfaces';

export const initVuplexAtom = atom(false);

export const promptImageAtom = atom<any>(null);
export const promptFileAtom = atom(null);
export const isAdminAtom = atom(false);
export const snackBarAtom = atom<SnackBarInfo>({
    msg: '',
    severity_type: 'success',
    autoHideDuration: 3000,
    action: undefined
});

// Contains the selected study instruction id.
export const instructionIdAtom = atom('');

// Indicate if the instruction is completed or not.
export const instructionCompleteStatusAtom = atom(false);

// Contains studyData fetched from the server.
export const studyDataAtom = atom<StudyRequest | undefined>(undefined);

// Sutdy Responses from the Describe Place Page.
export const studyResponsesAtom = atom<StudyResponse[]>([]);

// Contains current capture request information
export const captureRequestAtom = atom<CaptureRequest>({ user_uid: '' });

// Contains a list of journey community stories
export const journeyStoriesAtom = atom<CaptureRequest[]>([]);

// Contains a list of journey community stories' user profiles
export const journeyStoriesUsersAtom = atom<UsernameRequest[]>([]);

// Contain selected capture data.
export const captureDataIndexAtom = atom(0);

// Contain selected feedback data.
const initFeedback: FeedbackItem = {
    user_uid: '',
    parent_capture_uid: '',
    generate_uid: '',
    reviewer_capture_uid: '',
    reviewer_user_uid: '',
    imageUrl: '',
    redesignInProgress: false,
    prompt: ''
};
export const feedbackAtom = atom<FeedbackItem>(initFeedback);
export const resetFeedback = (setValue: (newValue: FeedbackItem) => void) => {
    setValue(initFeedback);
};

// Tracks Landing Page Tabs
export const landingPageTab = atom<TabType>(TabType.Activities);
// Tracks Gallery Page Tabs
export const galleryPageTab = atom<GalleryType>(GalleryType.Originals);
// Tracks Give FeedbackPage Tabs
export const feedbackPageTab = atom<FeedbackTabType>(FeedbackTabType.Designs);

// Keys to store in local storage.
export const firstSubmitKey = 'FirstSubmit';
export const firstLaunchKey = 'FirstLaunch';
export const finishedStudy = 'FinishedStudy';
