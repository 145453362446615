import { AxiosError, AxiosResponse } from 'axios';
import { UserProfile } from 'global/AtomData';
import { StudyStatus, StudyUsersRequest } from 'interface';
import { api, getHeaderWithToken } from './api';
import { checkValidStudyName, getStudyStatus } from './StudyApi';
import { createUser, getProfileName } from './UserApi';

export function createNewUserInStudy(userToken: string, studyName: string, userId: string): Promise<StudyUsersRequest> {
    return new Promise((resolve, reject) => {
        api.post(
            `/study_users`,
            { study_name: studyName, user_uid: userId },
            { headers: { Authorization: `Bearer ${userToken}` } }
        )
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
    });
}

export function getStudiesForUser(userToken: string): Promise<string[]> {
    return new Promise((resolve, reject) => {
        api.get('/study_users/user_uid', getHeaderWithToken(userToken))
            .then((response: AxiosResponse) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject(new Error(`${response.status}: ${response.statusText}`));
                }
            })
            .catch((error: any) => reject(error));
    });
}

export function getCurrentStudyAndProfileName(userToken: string): Promise<UserProfile> {
    var profileName = '';
    var studyName = '';
    return new Promise((resolve, reject) => {
        Promise.all([getStudiesForUser(userToken), getProfileName(userToken)])
            .then(([studies, profile_name]) => {
                profileName = profile_name;
                if (studies.length > 0) {
                    studyName = studies[0];
                    return getStudyStatus(userToken, studyName);
                }
                resolve({ community: '', profile_name: profileName });
            })
            .then((study_status) => {
                if (study_status == StudyStatus.Complete) {
                    resolve({ community: '', profile_name: profileName });
                } else {
                    resolve({ community: studyName, profile_name: profileName });
                }
            })
            .catch((error) => reject(error));
    });
}

export function setCurrentStudyAndProfileName(
    userToken: string,
    studyName: string,
    profileName: string
): Promise<UserProfile> {
    return new Promise((resolve, reject) => {
        checkValidStudyName(userToken, studyName)
            .then((valid) => {
                if (!valid) {
                    throw new AxiosError('Invalid community name');
                } else {
                    return getStudyStatus(userToken, studyName);
                }
            })
            .then((studyStatus) => {
                if (studyStatus === StudyStatus.Complete) {
                    throw new AxiosError('Community is completed. Please enter another one.');
                } else {
                    return createUser(userToken, profileName);
                }
            })
            .then((response) => {
                return createNewUserInStudy(userToken, studyName, response.uid);
            })
            .then((response) => {
                resolve({ community: response.study_name, profile_name: profileName });
            })
            .catch((error) => {
                if (error.response?.data?.detail) {
                    reject(error.response.data.detail);
                } else {
                    reject(error.message);
                }
            });
    });
}

export function changeStudy(userToken: string, new_study_name: string): Promise<StudyUsersRequest> {
    return new Promise((resolve, reject) => {
        api.put(`/study_users`, { user_uid: '', study_name: new_study_name }, getHeaderWithToken(userToken))
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    reject("Unable to find study or user doesn't belong to the study.");
                } else if (error.response.status === 409) {
                    reject('User is already associated with given study.');
                }
            });
    });
}

export function deleteUserFromStudy(userToken: string, userIdToDelete: string, studyName: string): Promise<number> {
    return new Promise((resolve, reject) => {
        api.delete(`/study_users?user_uid=${userIdToDelete}&study_name=${studyName}`, {
            headers: { Authorization: `Bearer ${userToken}` }
        })
            .then((response) => {
                resolve(response.status);
            })
            .catch((error) => reject(error.response.status));
    });
}
