//// Ignore eslint error on vuplex
// @ts-nocheck
import { initVuplexAtom } from 'global/Atom';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';

export function UnityVuplex() {
    const setInitVuplex = useSetAtom(initVuplexAtom);

    function handleUnityMessage(message) {
        // Just log out Unity Message for now.
        console.log(message);
    }

    function setupVuplex() {
        setInitVuplex(true);
        window.vuplex.addEventListener('message', handleUnityMessage);
    }

    function cleanUpVuplex() {
        if (window.vuplex) {
            window.vuplex.removeEventListener('message', handleUnityMessage);
        }
        window.removeEventListener('vuplexready', setupVuplex);
    }

    useEffect(() => {
        if (window.vuplex) {
            setupVuplex();
        } else {
            window.addEventListener('vuplexready', setupVuplex);
        }

        return () => cleanUpVuplex();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <></>;
}

export function openARView(generatedImageUrl: string, userId: string) {
    if (!window.vuplex) {
        console.warn('Vuplex is not ready or not supported.');
        return;
    }
    window.vuplex.postMessage({ type: 'view', message: generatedImageUrl, user_uid: userId });
}

export function copyToClipboard(text: string) {
    if (window.vuplex) {
        window.vuplex.postMessage({ type: 'clipboard-write', message: text });
    }
}

export function sendUnityUserToken(userToken: string) {
    if (window.vuplex) {
        window.vuplex.postMessage({ type: 'send-user-token', message: userToken });
    } else {
        window.addEventListener(
            'vuplexready',
            () => {
                window.vuplex.postMessage({ type: 'send-user-token', message: userToken });
            },
            { once: true }
        );
    }
}
