import { Alert, Button, Snackbar } from '@mui/material';
import { snackBarAtom } from 'global/Atom';
import { useAtomValue } from 'jotai';
import React, { useEffect, useMemo, useState } from 'react';

export default function SnackbarPrompt() {
    const snackBarInfo = useAtomValue(snackBarAtom);
    const [open, setOpen] = useState(false);

    const hideDuration =
        snackBarInfo.autoHideDuration && snackBarInfo.autoHideDuration > 0 ? snackBarInfo.autoHideDuration : null;

    useEffect(() => {
        setOpen(snackBarInfo.msg !== '');
    }, [snackBarInfo]);

    const action = useMemo(() => {
        if (snackBarInfo.action) {
            return (
                <React.Fragment>
                    <Button
                        color="error"
                        variant="text"
                        onClick={() => {
                            snackBarInfo.action?.action_callback();
                            setOpen(false);
                        }}
                    >
                        {snackBarInfo.action.action_name}
                    </Button>
                </React.Fragment>
            );
        }
        return <></>;
    }, [snackBarInfo]);

    function close() {
        setOpen(false);
    }

    return (
        <Snackbar open={open} autoHideDuration={hideDuration} onClose={close}>
            <Alert severity={snackBarInfo.severity_type} sx={{ width: '100%' }} action={action}>
                {snackBarInfo.msg}
            </Alert>
        </Snackbar>
    );
}
