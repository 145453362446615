import { Authenticator } from '@aws-amplify/ui-react';
import { Box, CircularProgress } from '@mui/material';
import { Amplify } from 'aws-amplify';
import { UnityVuplex } from 'global/UnityVuplex';
import { Provider, createStore } from 'jotai';
import { Suspense, useMemo } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { routeConfig } from 'AppRoutes';

if (process.env.NODE_ENV !== 'test') {
    Amplify.configure(AWS_CONFIG);
}

export default function App() {
    const router = createBrowserRouter(routeConfig);

    const myStore = createStore();
    const queryClient = new QueryClient();
    const loadingComponent = useMemo(() => {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }, []);

    return (
        <Authenticator.Provider>
            <Provider store={myStore}>
                <UnityVuplex />
                <QueryClientProvider client={queryClient}>
                    <Suspense fallback={loadingComponent}>
                        <RouterProvider router={router} />
                    </Suspense>
                </QueryClientProvider>
            </Provider>
        </Authenticator.Provider>
    );
}
