import { ThemeProvider, styled } from '@mui/material';
import appTheme from './Theme';
import MenuAppBar from 'components/MenuAppBar';
import SnackbarPrompt from 'components/SnackbarPrompt';
import { Outlet } from 'react-router-dom';

const AppLayout = styled('div')(({ theme }) => ({
    paddingLeft: '360px',
    paddingRight: '360px',
    [theme.breakpoints.down('lg')]: {
        paddingLeft: '250px',
        paddingRight: '250px'
    },
    [theme.breakpoints.down('md')]: {
        paddingLeft: '160px',
        paddingRight: '160px'
    },
    [theme.breakpoints.down('sm')]: {
        paddingLeft: '40px',
        paddingRight: '40px'
    }
}));

export default function Root() {
    return (
        <>
            <MenuAppBar showProfile={true} />
            <ThemeProvider theme={appTheme}>
                <AppLayout>
                    <Outlet />
                    <SnackbarPrompt />
                </AppLayout>
            </ThemeProvider>
        </>
    );
}
