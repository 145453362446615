import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

export default function GenericErrorPage() {
    const centerStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
    };

    const error = useRouteError() as Error;
    console.error(error);

    if (isRouteErrorResponse(error)) {
        return (
            <div style={centerStyle}>
                <h1>Oops!</h1>
                <h2>{error.status}</h2>
                <p>{error.statusText}</p>
                {error.data?.message && <p>{error.data.message}</p>}
            </div>
        );
    } else {
        return (
            <div style={centerStyle}>
                <h2>{error.message}</h2>
            </div>
        );
    }
}
